import { Block, Done, Refresh, Search } from "@mui/icons-material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect } from "react";
import { IconBox, ManageRequestsTables } from "../components";
import { ManageRequestsContext } from "../contexts";
import { useDictionary, useForm } from "../hooks";
import { isDateGreater } from "../hooks/useForm";
import { usePagination } from "../hooks";
import moment from "moment";
import { ROWS_PER_PAGE } from "../hooks/usePagination";

const INITIAL_STATE = {
  firstname: "ALL",
  lastname: "ALL",
  status: "NEW",
  statusGroups: "PENDING",
  datetimefrom: "ALL",
  datetimeto: "ALL",
};

const ManageRequests = () => {
  const {
    state: { searchRequests, searchedRequests },
    setManageRequests,
    approveManageRequests,
  } = useContext(ManageRequestsContext);

  const dictionary = useDictionary();
  const validations = [
    ({ datetimefrom, datetimeto }) =>
      isDateGreater(datetimefrom, datetimeto) || {
        datetimefrom: dictionary["reports"]["errors"]["startGreaterEnd"],
      },
  ];

  const {
    values: searchManageRequests,
    changeHandler,
    setValues,
    errors,
    touched,
    isValid,
    reset,
  } = useForm(searchRequests, validations);

  useEffect(() => {
    setManageRequests(INITIAL_STATE);
  }, []);


  return (
    <Stack spacing={2}>
      <Card>
        <IconBox Icon={Search} />
        <CardHeader
          title={dictionary["routes"]["manageRequests"]}
          sx={{ pl: (theme) => theme.spacing(12) }}
        />
        <CardContent component="form">
          <Grid container spacing={2} mb={1}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                id="text"
                autoComplete="text"
                type="text"
                label={dictionary["managerequests"]["name"]}
                variant="outlined"
                error={Boolean(touched.firstname && errors.firstname)}
                helperText={touched.firstname && errors.firstname}
                value={searchManageRequests.firstname}
                onChange={(e) => changeHandler("firstname", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                id="text"
                autoComplete="text"
                type="text"
                label={dictionary["managerequests"]["lastname"]}
                variant="outlined"
                error={Boolean(touched.lastname && errors.lastname)}
                helperText={touched.lastname && errors.lastname}
                value={searchManageRequests.lastname}
                onChange={(e) => changeHandler("lastname", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <DatePicker
                label={dictionary["reports"]["dateFrom"]}
                inputFormat="DD/MM/yyyy"
                value={searchManageRequests.datetimefrom || ""}
                onChange={(newValue) =>
                  changeHandler("datetimefrom", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.datetimefrom && errors.datetimefrom)}
                    helperText={touched.datetimefrom && errors.datetimefrom}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <DatePicker
                label={dictionary["reports"]["dateTo"]}
                inputFormat="DD/MM/yyyy"
                value={searchManageRequests.datetimeto || ""}
                onChange={(newValue) =>
                  changeHandler("datetimeto", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.datetimeto && errors.datetimeto)}
                    helperText={touched.datetimeto && errors.datetimeto}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="status-select">{dictionary["managerequests"]["statusrequests"]}</InputLabel>
                <Select
                  id="status-select"
                  value={searchManageRequests.status || ""}
                  label={dictionary["managerequests"]["statusrequests"]}
                  onChange={(e) => changeHandler("status", e.target.value)}
                >
                  <MenuItem value={"ALL"}>
                    {dictionary["requestStatus"]["ALL"]}
                  </MenuItem>
                  <MenuItem value={"NEW"}>
                    {dictionary["requestStatus"]["NEW"]}
                  </MenuItem>
                  <MenuItem value={"APPROVED"}>
                    {dictionary["requestStatus"]["APPROVED"]}
                  </MenuItem>
                  <MenuItem value={"CANCELLED"}>
                    {dictionary["requestStatus"]["CANCELLED"]}
                  </MenuItem>
                  <MenuItem value={"DELETED"}>
                    {dictionary["requestStatus"]["DELETED"]}
                  </MenuItem>
                  <MenuItem value={"REVOKED"}>
                    {dictionary["requestStatus"]["REVOKED"]}
                  </MenuItem>
                  <MenuItem value={"REFUSED"}>
                    {dictionary["requestStatus"]["REFUSED"]}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="status-select">
                {dictionary["managerequests"]["statusreponsegroups"]}
                </InputLabel>
                <Select
                  id="status-select"
                  value={searchManageRequests.statusGroups || ""}
                  label={dictionary["managerequests"]["statusreponsegroups"]}
                  onChange={(e) =>
                    changeHandler("statusGroups", e.target.value)
                  }
                >
                  <MenuItem value={"ALL"}>
                    {dictionary["requestStatus"]["ALL"]}
                  </MenuItem>
                  <MenuItem value={"PENDING"}>
                    {dictionary["requestStatus"]["PENDING"]}
                  </MenuItem>
                  <MenuItem value={"APPROVED"}>
                    {dictionary["requestStatus"]["APPROVED"]}
                  </MenuItem>
                  <MenuItem value={"NOT_APPROVED"}>
                    {dictionary["requestStatus"]["NOT_APPROVED"]}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Stack direction="row" justifyContent={"flex-end"} spacing={2}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                  }}
                  //disabled={!isValid}
                  startIcon={<Search />}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    setManageRequests(searchManageRequests);
                  }}
                >
                  {dictionary["search"]}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                  }}
                  startIcon={<Refresh />}
                  onClick={reset}
                >
                  {dictionary["reset"]}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <ManageRequestsTables
            mini={false}
            searchForm={searchManageRequests}
            searchedRequests={searchedRequests}
            approveManageRequests={approveManageRequests}
          />
        </CardContent>
      </Card>
    </Stack>
  );
};

export default ManageRequests;
