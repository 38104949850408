import { Card, CardContent, Grid, Stack } from "@mui/material";
import React from "react";
import CalendarModel from "../components/CalendarModel";
import { useParams } from "react-router-dom";

const Calendar = () => {
  let { id } = useParams();

  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>
          <CalendarModel id={id} />
        </CardContent>
      </Card>
    </Stack>
  );
};

export default Calendar;
