import createDataContext from "./createContext";
import {CP, ROLES, STORE_USER, STORE_JWT, userHasOneOfRoles} from "../utilities";
import {BaseService, FileUser} from "../api";
import {openModal} from "../hooks/useModals";
import {ResetPassword} from "../components";
import {Button, Stack} from "@mui/material";
import {sendNotification} from "../hooks/useNotification";
import {checkIfMobile} from "../utilities";

const SET_LOGIN_USERNAME = "set_login_username";
const SET_LOGIN_PASSWORD = "set_login_password";
const SET_USERNAME = "set_username";
const SET_USER_IMAGE = "set_user_image";
// const START_LOGIN = "start_login";
const END_LOGIN = "end_login";
const LOGIN_ERROR = "set_login_error";
const LOGOUT = "logout";
const SET_USER = "set_user";
const RESET_PASSWORD_FORM = "reset_password_form";
const SET_OBJVERSION = "set_objversion";

const INITIAL_STATE = {
  user: null,
  image: null,
  geolocationEnabled: false,
  token: null,
  // loading: true,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case RESET_PASSWORD_FORM: {
      return {...state, passwordForm: {...INITIAL_STATE.passwordForm}};
    }
    case SET_USER: {
      const {user} = action.payload;
      return {...state, user, geolocationEnabled: !userHasOneOfRoles(["DONT_REQUIRE_LOCALIZATION"], user || {})};
    }
    case SET_USER_IMAGE: {
      return {...state, image: action.payload};
    }
    case SET_USERNAME: {
      const {username} = action.payload;
      return {...state, user: {...state.user, username}};
    }
    case END_LOGIN: {
      const {user} = state;
      const {token} = action.payload;
      return {...state, token, geolocationEnabled: !userHasOneOfRoles([ROLES.DONT_REQUIRE_LOCALIZATION], user)};
    }
    case LOGIN_ERROR: {
      return {...state, loginForm: {...state.loginForm, error: action.payload}};
    }
    case LOGOUT: {
      return {...INITIAL_STATE};
    }
    default: {
      return state;
    }
  }
};
const setUser = (dispatch) => (user) => {
  dispatch({type: SET_USER, payload: {user}});
};
const getUser =
  (dispatch) =>
  async (setValues = undefined) => {
    const response = await BaseService.get(`users/current`);
    const user = response.data;
    setValues && setValues(user);
    localStorage.setItem(STORE_USER, JSON.stringify(user));
    dispatch({type: SET_USER, payload: {user}});
    getUserImage(dispatch)("current");
  };

const setLoginUsername = (dispatch) => (username) => {
  dispatch({type: SET_LOGIN_USERNAME, payload: username});
};

const setLoginPassword = (dispatch) => (password) => {
  dispatch({type: SET_LOGIN_PASSWORD, payload: password});
};

const setImage = (dispatch) => async (id, file) => {
  dispatch({type: SET_USERNAME, payload: {username: ""}});
  const response = await FileUser.post(`/users/${id}/photo`, file);
  getUser(dispatch)();
};

const updateInfoUser = (dispatch) => async (formUser, dictionary, setValues) => {
  const response = await BaseService.put(`/users/current`, formUser);
  if (response) {
    sendNotification(dictionary["profilemode"]["successmess"], dictionary["profilemode"]["messagge"], "success", 5);
    getUser(dispatch)(setValues);
  }
};

const getUserImage = (dispatch) => async (id) => {
  const response = await BaseService.get(`/users/${id}/photo`, {responseType: 'blob'});
  if (response) {
    const data = URL.createObjectURL(response.data);
    dispatch({type: SET_USER_IMAGE, payload: data});
  }
};

const resetPin = (dispatch) => async (logout, dictionary) => {
  const action = async (form, closeModal) => {
    if (form.isValid) {
      await BaseService.put(`/users/pin`, form);
      sendNotification(dictionary["profilemode"]["successmess"], dictionary["profilemode"]["changepin"], "success", 5);
      closeModal();
      logout();
    }
  };
  const form = {oldpin: "", newpin: "", confirmpin: ""};
  openModal(dictionary["changepw"], <ResetPassword formRef={form} />, "info", (closeModal) => (
    <Stack direction="row" spacing={2}>
      <Button
        color="success"
        variant="contained"
        size="small"
        onClick={async () => {
          await action(form, closeModal);
        }}>
        conferma
      </Button>
      <Button color="error" variant="contained" size="small" onClick={closeModal}>
        cancel
      </Button>
    </Stack>
  ));
};

const login = (dispatch) => async (username, password, isMobile) => {
  let clockPoint = checkIfMobile() ? "MOBILE" : CP;
  if ("ontouchstart" in document.documentElement) {
    clockPoint = "MOBILE";
  }
  const response = await BaseService.post(`/login`, {
    jwtusername: `${username}:${clockPoint}`,
    jwtpassword: password,
  });
  const {token} = response.data;
  localStorage.setItem(STORE_JWT, token);
  await getUser(dispatch)();
  dispatch({type: END_LOGIN, payload: {token}});
};

const logout = (dispatch) => async (navigate) => {
  localStorage.removeItem(STORE_USER);
  localStorage.removeItem(STORE_JWT);
  navigate('/');
  dispatch({type: LOGOUT});
};

export const {Provider, Context} = createDataContext(
  authReducer,
  {
    setLoginUsername,
    setLoginPassword,
    login,
    setUser,
    setImage,
    resetPin,
    updateInfoUser,
    getUserImage,
    // setCookie,
    logout,
    getUser,
  }, // actions
  INITIAL_STATE // initial state
);
