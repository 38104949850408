import {
    IconButton,
    Stack,
    Card,
    CardContent,
    Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { ROWS_PER_PAGE } from "../hooks/usePagination";
import {
    useDictionary,
    useEventTypes,
    useJustificationsTypes,
    usePagination,
} from "../hooks";
import moment from "moment";
import { Block, Done, Refresh, Search } from "@mui/icons-material";

const PlannedAbsences = ({ plannedRequests }) => {
    const [pageSize, setPageSize] = usePagination();
    const dictionary = useDictionary();

    const columnsDash = [
        {
            field: "userfirstname",
            headerName: dictionary["user"],
            flex: 1,
            valueGetter: (params) =>
                params.row.userfirstname.slice(0, 1) + "." + params.row.userlastname,
        },
        {
            field: "type",
            headerName: dictionary["plannedabsence"]["type"],
            flex: 1,
            valueGetter: (params) =>
                dictionary["requests"]["types"][params.row.request.requesttype],
        },
        {
            field: "datefrom",
            headerName: dictionary["plannedabsence"]["from"],
            flex: 1,
            valueGetter: (params) =>
                moment(params.row.request.datefrom).format("DD MMM YY")
        },
        {
            field: "dateto",
            headerName: dictionary["plannedabsence"]["to"],
            flex: 1,
            valueGetter: (params) =>
                moment(params.row.request.dateto).format("DD MMM YY")
        },
    ];

    return (
        <DataGrid
            disableSelectionOnClick
            autoHeight
            rowHeight={60}
            density={"compact"}
            rows={plannedRequests}
            columns={columnsDash}
            pagination
            hideFooter={true}
            onPageSizeChange={setPageSize}
            pageSize={100}
            rowsPerPageOptions={ROWS_PER_PAGE}
        />
    )
}

export default PlannedAbsences