import React, { useContext } from 'react'
import {
    Badge,
    Card,
    CardContent,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    Chip
} from "@mui/material";
import { CalendarMonth, LocalActivity, TravelExplore } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { ROWS_PER_PAGE } from "../hooks/usePagination";
import {
    useDictionary,
    useMediaQuery,
    usePagination,
} from "../hooks";
import { renderHeader } from "../utilities";
import { useNavigate  } from 'react-router-dom';
import { UserContext } from '../contexts';


const UsersTable = ({ searchedUsers}) => {
    const [pageSize, setPageSize] = usePagination();
    const dictionary = useDictionary();
    const navigate = useNavigate ();
    const [isMobile] = useMediaQuery("down", "md");

    const {viewMapsPosition}=useContext(UserContext)

    let columns=[]

    const columnsDesk = [
        {
            field: "completename",
            headerName: dictionary["userstables"]["user"],
            renderHeader: renderHeader(dictionary["userstables"]["user"]),
            flex: 1,
            renderCell: (params) => `${params.row.firstname} ${params.row.lastname}`,
        },
        {
            field: "direction",
            headerName: dictionary["userstables"]["status"],
            renderHeader: renderHeader(dictionary["userstables"]["status"]),
            flex: 1,
            renderCell: (params) => {
                if (params.value === "IN") {
                    return <Chip label={dictionary["userstables"]["present"]} color="success" />
                } else if (params.value === "OUT") {
                    return <Chip label={dictionary["userstables"]["missing"]} color="error" />
                } else {
                    <Chip label="N.A." />
                }
            },
        },
        {
            field: "cpdescription",
            headerName: dictionary["userstables"]["cp"],
            renderHeader: renderHeader(dictionary["userstables"]["cp"]),
            flex: 1
        },
        {
            field: "",
            headerName: dictionary["actions"],
            renderHeader: renderHeader(dictionary["actions"]),
            renderCell: (params) =>(
            <Stack direction={"row"}>
            {
                params.row.iscollaborator ? (<IconButton
                color="primary"
                size="small"
                onClick={() => navigate(`/calendar/${params.row.id}`)}
            >
                <CalendarMonth fontSize="small" />
            </IconButton>):<></>
            }
            {
                params.row.iscollaborator && params.row.cpgeolatitude > 0 &&  params.row.cpgeolongitude > 0 ? (
                    <IconButton
                color="primary"
                size="small"
                onClick={() =>viewMapsPosition(params.row.cpgeolatitude,params.row.cpgeolongitude,dictionary)}
            >
                <TravelExplore fontSize="small" />
            </IconButton>  
                ):(<></>)
            }           
            </Stack>
            ),
        }
    ]

    const columnsMobile = [
        {
            field: "completename",
            headerName: dictionary["userstables"]["user"],
            renderHeader: renderHeader(dictionary["userstables"]["user"]),
            flex: 1,
            renderCell: (params) => `${params.row.firstname} ${params.row.lastname}`,
        },
        {
            field: "direction",
            headerName: dictionary["userstables"]["status"],
            renderHeader: renderHeader(dictionary["userstables"]["status"]),
            flex: 1,
            renderCell: (params) => {
                if (params.value === "IN") {
                    return <Chip label={dictionary["userstables"]["present"]} color="success" />
                } else if (params.value === "OUT") {
                    return <Chip label={dictionary["userstables"]["missing"]} color="error" />
                } else {
                    <Chip label="N.A." />
                }
            },
        },
        {
            field: "",
            headerName: dictionary["actions"],
            renderHeader: renderHeader(dictionary["actions"]),
            renderCell: (params) =>(
            <Stack direction={"row"}>
            {
                params.row.iscollaborator ? (<IconButton
                color="primary"
                size="small"
                onClick={() => navigate(`/calendar/${params.row.id}`)}
            >
                <CalendarMonth fontSize="small" />
            </IconButton>):<></>
            }
            {
                params.row.iscollaborator && params.row.cpgeolatitude > 0 &&  params.row.cpgeolongitude > 0 ? (
                    <IconButton
                color="primary"
                size="small"
                onClick={() =>viewMapsPosition(params.row.cpgeolatitude,params.row.cpgeolongitude,dictionary)}
            >
                <TravelExplore fontSize="small" />
            </IconButton>  
                ):(<></>)
            }           
            </Stack>
            ),
        }
    ]

    isMobile ? columns = columnsMobile : columns = columnsDesk

    return (
        <DataGrid
            disableColumnMenu
            disableSelectionOnClick
            autoHeight
            density={"standard"}
            rows={searchedUsers}
            columns={columns}
            pagination
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            rowsPerPageOptions={ROWS_PER_PAGE}
        />
    )
}

export default UsersTable