import React, { useContext, useEffect } from "react";
import { UserContext } from "../contexts";
import { ApproversList } from "../components";

const Approvers = () => {
  const {
    state: { approvers },
    setApprovers,
  } = useContext(UserContext);

  useEffect(() => {
    setApprovers();
  }, []);

  return (
    <ApproversList approvers={approvers} />
  )
}

export default Approvers