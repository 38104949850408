import createDataContext from "./createContext";
import { BaseService } from "../api";
import moment from "moment";

const SET_STATS = "set_stats";
const SET_REPORTS = "set_reports";
const REFRESH_STATS = "refresh_stats";

const INITIAL_STATE = {
  statistics: [],
  analyticsReport: [],
  userSelect: {},
  manageStatsForm: {},
};

const statisticsReducer = (state, action) => {
  switch (action.type) {
    case SET_STATS: {
      return { ...state, statistics: action.payload };
    }
    case REFRESH_STATS: {
      return { ...state, statistics: [] };
    }
    case SET_REPORTS: {
      const { report, userSelect } = action.payload;
      return { ...state, analyticsReport: report, userSelect: userSelect };
    }
    default: {
      return state;
    }
  }
};

const setStatistics = (dispatch) => async (statistics) => {
  let from;
  let to;
  let costcenterid;
  let payload = [];
  if (statistics.datetimefrom) {
    from = moment(statistics.datetimefrom).format("YYYY-MM-DD 00:00");
  } else {
    from = "ALL";
  }
  if (statistics.datetimeto) {
    to = moment(statistics.datetimeto).format("YYYY-MM-DD 23:59");
  } else {
    to = "ALL";
  }
  if (statistics.costcenterid) {
    costcenterid = statistics.costcenterid;
  } else {
    costcenterid = "ALL";
  }

  const response = await BaseService.get(`costcenters/stats/${from}/${to}/${costcenterid}`);

  let middleObj = {};

  response.data.data.forEach((statsCostcenter) => {
    if (!middleObj[statsCostcenter.costcenterid]) {
      middleObj[statsCostcenter.costcenterid] = {
        costcenter:
          statsCostcenter.costcenter,
        id: statsCostcenter.costcenterid,
        commissionid: statsCostcenter.costcenterid,
        minutesOrd: 0,
        minutesStr: 0,
        minutesAct: 0,
      };
    }
    if (statsCostcenter.justificationgrouptype == "O") {
      middleObj[statsCostcenter.costcenterid].minutesOrd +=
        statsCostcenter.minutes / 60;
    } else if (statsCostcenter.justificationgrouptype == "S") {
      middleObj[statsCostcenter.costcenterid].minutesStr +=
        statsCostcenter.minutes / 60;
    } else if (statsCostcenter.justificationgrouptype == "A") {
      middleObj[statsCostcenter.costcenterid].minutesAct +=
        statsCostcenter.minutes / 60;
    }
  });
  let middleArr = Object.values(middleObj);
  middleArr.forEach((item) => payload.push(item));

  dispatch({ type: SET_STATS, payload: payload });
};

const setManageStatistics = (dispatch) => async (statistics) => {
  let from;
  let to;
  let idcollaborator;
  let idcostcenter;
  let payload = [];
  if (statistics.datefrom) {
    from = moment(statistics.datefrom).format("YYYY-MM-DD 00:00");
  } else {
    from = "ALL";
  }
  if (statistics.dateto) {
    to = moment(statistics.dateto).format("YYYY-MM-DD 23:59");
  } else {
    to = "ALL";
  }
  if (statistics.idcollaborator) {
    idcollaborator = statistics.idcollaborator;
  } else {
    idcollaborator = "ALL";
  }
  if (statistics.idcostcenter) {
    idcostcenter = statistics.idcostcenter;
  } else {
    idcostcenter = "ALL";
  }

  const response = await BaseService.get(
    `costcenters/stats/${idcostcenter}/${from}/${to}/${idcollaborator}`
  );

  let middleObj = {};

  response.data.data.forEach((statsCostcenter) => {
    if (!middleObj[statsCostcenter.userid]) {
      middleObj[statsCostcenter.userid] = {
        costcenter:
          statsCostcenter.userfirstname + " " + statsCostcenter.userlastname,
        id: statsCostcenter.userid,
        commissionid: statsCostcenter.costcenterid,
        minutesOrd: 0,
        minutesStr: 0,
        minutesAct: 0,
      };
    }
    if (statsCostcenter.justificationgrouptype == "O") {
      middleObj[statsCostcenter.userid].minutesOrd +=
        statsCostcenter.minutes / 60;
    } else if (statsCostcenter.justificationgrouptype == "S") {
      middleObj[statsCostcenter.userid].minutesStr +=
        statsCostcenter.minutes / 60;
    } else if (statsCostcenter.justificationgrouptype == "A") {
      middleObj[statsCostcenter.userid].minutesAct +=
        statsCostcenter.minutes / 60;
    }
  });
  let middleArr = Object.values(middleObj);
  middleArr.forEach((item) => payload.push(item));
  dispatch({ type: SET_STATS, payload: payload });
};

const setReportsAnalytics =
  (dispatch) =>
  async ({ id_user, datefrom, dateto }) => {
    const response = await BaseService.get(
      `costcenters/stats/ALL/${datefrom}/${dateto}/${id_user}`
    );
    let newArr = [];
    let infoUser = {};
    response.data.data.map((data) => {
      infoUser = {
        name: data.userfirstname + " " + data.userlastname,
        costcenter: data.costcenter,
        date:
          dateto === "ALL"
            ? `Dal ${moment(datefrom).format("DD-MM-YYYY")}`
            : `Dal ${moment(datefrom).format("DD-MM-YYYY")} Al ${moment(
                dateto
              ).format("DD-MM-YYYY")}`,
      };
      newArr = [...newArr, { ...data, id: Math.random(), id_user: data.id }];
    });
    dispatch({
      type: SET_REPORTS,
      payload: { report: newArr, userSelect: infoUser },
    });
  };

//   const getUsers =(dispatch)=> async()=>{
//     const response= await BaseService.get(`/users/${id}`);
//     }
const refreshStats = (dispatch) => () => {
  dispatch({ type: REFRESH_STATS });
};

export const { Provider, Context } = createDataContext(
  statisticsReducer,
  {
    setStatistics,
    setManageStatistics,
    setReportsAnalytics,
    refreshStats,
  }, // actions
  INITIAL_STATE // initial state
);
