import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { HashRouter } from "react-router-dom";

import { LanguageProvider } from "./contexts";
import { Chip } from '@mui/material'
import { REACT_APP_VERSION } from './utilities';
import "moment";

ReactDOM.render(
  <React.StrictMode>
    <LanguageProvider>
      <HashRouter>
        <App />
      </HashRouter>
      <Chip style={{ position: 'fixed', bottom: '5px', right: '5px', zIndex: '9999' }}
        color="primary" size="small" label={`bitPresenze - ${REACT_APP_VERSION}`} />
    </LanguageProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
