import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import { useUser } from '../hooks';
import { userHasOneOfRoles } from '../utilities';

const Route = (props) => {
    const { inNavbar, route, close, dictionary, location, icon, parent, ...restProps } = props;
    const {user} = useUser();

    if (!userHasOneOfRoles(route.need, user)) {
        return null;
    }

    return (
        <ListItemButton
            color="secondary"
            component={Link}
            onClick={close && close}
            {...restProps}
            to={parent ? `${parent.path}${route.path}`: route.path}
            selected={location.pathname === (parent ? `${parent.path}${route.path}`: route.path)}
        >
            {icon && (
                <ListItemIcon>
                    {route.icon}
                </ListItemIcon>
            )}
            <ListItemText>{dictionary["routes"][route.breadcrumb]}</ListItemText>
        </ListItemButton>
    )
}

export default Route;