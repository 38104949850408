import React, { useContext } from 'react'
import { LanguageContext } from '../contexts'

const ErrorTitle = ({ service }) => {
  const { state: { dictionary } } = useContext(LanguageContext);

    return <>
        {dictionary["errors"][service]}
    </>
}

export default ErrorTitle