import { Box, Card, CardContent, Modal } from "@mui/material";
import React from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

const ModalForm = ({ openModal, handleCloseModal, children }) => {
  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Card sx={style}>
        <CardContent>{children}</CardContent>
      </Card>
    </Modal>
  );
};

export default ModalForm;
