import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LanguageContext, CostCenterContext} from "../contexts";
import {
  Card,
  CardContent,
  Stack,
  Grid,
  TextField,
  Button,
  Alert,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { isRequired, isRequiredNumber } from "../hooks/useForm";
import {
  HelpTwoTone,
  Add,
  Edit,
  Delete,
  CancelTwoTone,
  UpdateTwoTone,
} from "@mui/icons-material";
import { useForm, usePagination } from "../hooks";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { DataGrid } from "@mui/x-data-grid";
import { renderHeader } from "../utilities";
import { ROWS_PER_PAGE } from "../hooks/usePagination";
import { ModalForm } from "../components";
import moment from "moment";

const CenterCostsRevenueTab = ({ id, title }) => {
  
  const navigate = useNavigate();

  const [pageSize, setPageSize] = usePagination();

  const [openModal, setOpenModal] = useState(false);

  const {
    state: { dictionary },
  } = useContext(LanguageContext);

  const {
    state: { costCenterAnalyticsRevenue, analyticRevenue, factivities },
    deleteAnalyticsRevenueFromCenter,
    setAnalyticsRevenueFromCostCenter,
    putAnalyticsRevenueFromCostCenter,
    addAnalyticsRevenue,
    setFactitivesRevenue,
    setCostCenter,
    setCostsCenter
  } = useContext(CostCenterContext);

  const validationsAnalyticsCost = [
    ({ description }) =>
      isRequired(description) || {
        description: "Richiesto",
      },
    ({ datefrom }) =>
      isRequired(datefrom) || {
        datefrom: "Richiesto",
      },
    ({ dateto }) =>
      isRequired(dateto) || {
        dateto: "Richiesto",
      },
    ({ amount }) =>
      isRequiredNumber(amount) || {
        amount: "Richiesto",
      },
  ];

  const analyticsRevenueForm = useForm(analyticRevenue, validationsAnalyticsCost);

  const analyticsRevenueFormEdit = useForm({}, [
    ({ description }) =>
    isRequired(description) || {
      description: "Richiesto",
    },
  ({ datefrom }) =>
    isRequired(datefrom) || {
      datefrom: "Richiesto",
    },
  ({ dateto }) =>
    isRequired(dateto) || {
      dateto: "Richiesto",
    },
  ({ amount }) =>
    isRequiredNumber(amount) || {
      amount: "Richiesto",
    },
  ]);

  useEffect(() => {
    if (id !== "new") {
      setCostCenter(id);
      setAnalyticsRevenueFromCostCenter(id);
      setFactitivesRevenue()
    }
  }, []);

  const isCreating = id === "new";

  const cardTitleAnalytics = isCreating ? "Ricavi" : "Ricavi";

  const actionColumn = {
    field: "",
    renderHeader: renderHeader(dictionary["actions"]),
    headerName: dictionary["actions"],
    headerAlign: "right",
    align: "right",
    flex: 1,
    renderCell: (params) => (
      <Stack direction="row" spacing={1}>
        <Tooltip title={dictionary["edit"]} placement="top-start">
          <IconButton
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => {
              analyticsRevenueFormEdit.setValues(params.row);
              setOpenModal(!openModal);
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={dictionary["delete"]} placement="top-start">
          <IconButton
            variant="outlined"
            color="error"
            size="small"
            onClick={() =>
              deleteAnalyticsRevenueFromCenter(id, params.row.id, dictionary, setCostsCenter)
            }
          >
            <Delete fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    ),
  };

  const columns = [
    {
      field: "description",
      renderHeader: renderHeader("Descrizione"),
      headerName: "Descrizione",
      flex: 1,
    },
    {
      field: "datefrom",
      renderHeader: renderHeader("datefrom"),
      headerName: "Dal",
      flex: 1,
    },
    {
      field: "dateto",
      renderHeader: renderHeader("dateto"),
      headerName: "Al",
      flex: 1,
    },
    {
      field: "amount",
      renderHeader: renderHeader("Importo"),
      headerName: "Importo",
      renderCell: ({ row }) => (
        <Typography> € {row.amount.toFixed(2).replace(".", ",")}</Typography>
      ),
      headerAlign: "right",
      align: "right",
    },
    actionColumn,
  ];

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      {openModal && (
        <ModalForm openModal={openModal} handleCloseModal={handleCloseModal}>
          <Grid component="form" container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                id="descrizione"
                name="descrizione"
                sx={{ textTransform: "capitalize" }}
                label={"Descrizione"}
                error={Boolean(
                  analyticsRevenueFormEdit.touched.description &&
                    analyticsRevenueFormEdit.errors.description
                )}
                helperText={
                  Boolean(
                    analyticsRevenueFormEdit.touched.description &&
                      analyticsRevenueFormEdit.errors.description
                  )
                    ? analyticsRevenueFormEdit.errors.description
                    : analyticsRevenueFormEdit.errors.description
                    ? "* " + "Descrizione Obbligatoria"
                    : "* " + "Descrizione Obbligatoria"
                }
                type="text"
                variant="outlined"
                size="small"
                value={analyticsRevenueFormEdit.values.description || ""}
                onChange={(e) =>
                  analyticsRevenueFormEdit.changeHandler(
                    "description",
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DesktopDatePicker
                label={"Dal"}
                inputFormat="DD/MM/yyyy"
                value={analyticsRevenueFormEdit.values.datefrom || null}
                onChange={(newValue) =>
                  analyticsRevenueFormEdit.changeHandler(
                    "datefrom",
                    newValue?.format("yyyy-MM-DD")
                  )
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(
                      analyticsRevenueFormEdit.touched.datefrom &&
                        analyticsRevenueFormEdit.errors.datefrom
                    )}
                    helperText={
                      Boolean(
                        analyticsRevenueFormEdit.touched.datefrom &&
                          analyticsRevenueFormEdit.errors.datefrom
                      )
                        ? analyticsRevenueFormEdit.errors.datefrom
                        : analyticsRevenueFormEdit.errors.datefrom
                        ? "* " + "Data Obbligatoria"
                        : "* " + "Data Obbligatoria"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DesktopDatePicker
                label={"Al"}
                inputFormat="DD/MM/yyyy"
                value={analyticsRevenueFormEdit.values.dateto || ""}
                onChange={(newValue) =>
                  analyticsRevenueFormEdit.changeHandler(
                    "dateto",
                    newValue?.format("yyyy-MM-DD")
                  )
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(
                      analyticsRevenueFormEdit.touched.dateto &&
                        analyticsRevenueFormEdit.errors.dateto
                    )}
                    /* helperText={touched.dob && errors.dob} */
                    helperText={
                      Boolean(
                        analyticsRevenueFormEdit.touched.dateto &&
                          analyticsRevenueFormEdit.errors.dateto
                      )
                        ? analyticsRevenueFormEdit.errors.dateto
                        : analyticsRevenueFormEdit.errors.dateto
                        ? "* " + "Data Obbligatoria"
                        : "* " + "Data Obbligatoria"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Tipo di costo
                    </InputLabel>
                    <Select
                      id="demo-simple-select"
                      value={analyticsRevenueFormEdit.values.idtype || " "}
                      label="Tipo di costo"
                      onChange={(e) =>
                        analyticsRevenueFormEdit.changeHandler(
                          "idtype",
                          e.target.value
                        )
                      }
                    >
                      <MenuItem value={" "}>
                        ...Seleziona tipo di costo
                      </MenuItem>
                      {factivities.map((data, index) => {
                        return (
                          <MenuItem key={index} value={data.id}>
                            {data.description}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                id="nome"
                name="import"
                sx={{ textTransform: "capitalize" }}
                label={"Importo"}
                error={Boolean(
                  analyticsRevenueFormEdit.touched.amount &&
                    analyticsRevenueFormEdit.errors.amount
                )}
                helperText={
                  Boolean(
                    analyticsRevenueFormEdit.touched.amount &&
                      analyticsRevenueFormEdit.errors.amount
                  )
                    ? analyticsRevenueFormEdit.errors.amount
                    : analyticsRevenueFormEdit.errors.amount
                    ? "* " + "Importo Obbligatorio"
                    : "* " + "Importo Obbligatorio"
                }
                type="number"
                variant="outlined"
                size="small"
                value={analyticsRevenueFormEdit.values.amount || ""}
                onChange={(e) =>
                  analyticsRevenueFormEdit.changeHandler(
                    "amount",
                    parseFloat(e.target.value)
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                <Button
                  startIcon={<CancelTwoTone />}
                  color="warning"
                  variant="outlined"
                  onClick={() => {
                    handleCloseModal();
                  }}
                  sx={{ textTransform: "capitalize" }}
                >
                  {"Annulla"}
                </Button>
                <Button
                  startIcon={<UpdateTwoTone />}
                  color="secondary"
                  variant="outlined"
                  disabled={!analyticsRevenueFormEdit.isValid}
                  onClick={() => {
                    putAnalyticsRevenueFromCostCenter(
                      analyticsRevenueFormEdit,
                      id,
                      navigate,
                      false,
                      dictionary
                    );
                    handleCloseModal();
                  }}
                  sx={{ textTransform: "capitalize" }}
                >
                  {"Aggiorna Ricavo"}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </ModalForm>
      )}

      <Card elevation={0}>
        <Alert icon={<HelpTwoTone fontSize="inherit" />} severity="warning">
          I campi contrassegnati con il simbolo * sono obbligatori
        </Alert>
        <CardContent>
          <Grid component="form" container spacing={2}>
            {title ? (
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
                  {cardTitleAnalytics}
                </Typography>
                <Divider />
              </Grid>
            ) : null}

            <Grid item xs={12} md={6} lg={2}>
              <TextField
                fullWidth
                id="descrizione"
                name="descrizione"
                sx={{ textTransform: "capitalize" }}
                label={"Descrizione"}
                error={Boolean(
                  analyticsRevenueForm.touched.description &&
                    analyticsRevenueForm.errors.description
                )}
                helperText={
                  Boolean(
                    analyticsRevenueForm.touched.description &&
                      analyticsRevenueForm.errors.description
                  )
                    ? analyticsRevenueForm.errors.description
                    : analyticsRevenueForm.errors.description
                    ? "* " + "Descrizione Obbligatoria"
                    : "* " + "Descrizione Obbligatoria"
                }
                type="text"
                variant="outlined"
                size="small"
                value={analyticsRevenueForm.values.description || ""}
                onChange={(e) =>
                  analyticsRevenueForm.changeHandler(
                    "description",
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <DesktopDatePicker
                label={"Dal"}
                inputFormat="DD/MM/yyyy"
                value={analyticsRevenueForm.values.datefrom == '' ? null : analyticsRevenueForm.values.datefrom}
                onChange={(newValue) => {
                  analyticsRevenueForm.changeHandler(
                    "datefrom",
                    newValue?.format("yyyy-MM-DD")
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(
                      analyticsRevenueForm.touched.datefrom &&
                        analyticsRevenueForm.errors.datefrom
                    )}
                    /* helperText={touched.dob && errors.dob} */
                    helperText={
                      Boolean(
                        analyticsRevenueForm.touched.datefrom &&
                          analyticsRevenueForm.errors.datefrom
                      )
                        ? analyticsRevenueForm.errors.datefrom
                        : analyticsRevenueForm.errors.datefrom
                        ? "* " + "Data Obbligatoria"
                        : "* " + "Data Obbligatoria"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <DesktopDatePicker
                label={"Al"}
                inputFormat="DD/MM/yyyy"
                value={analyticsRevenueForm.values.dateto == '' ? null : analyticsRevenueForm.values.dateto}
                onChange={(newValue) =>
                  analyticsRevenueForm.changeHandler(
                    "dateto",
                    newValue?.format("yyyy-MM-DD")
                  )
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(
                      analyticsRevenueForm.touched.dateto &&
                        analyticsRevenueForm.errors.dateto
                    )}
                    /* helperText={touched.dob && errors.dob} */
                    helperText={
                      Boolean(
                        analyticsRevenueForm.touched.dateto &&
                          analyticsRevenueForm.errors.dateto
                      )
                        ? analyticsRevenueForm.errors.dateto
                        : analyticsRevenueForm.errors.dateto
                        ? "* " + "Data Obbligatoria"
                        : "* " + "Data Obbligatoria"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Tipo di costo
                    </InputLabel>
                    <Select
                      id="demo-simple-select"
                      value={analyticsRevenueForm.values.idtype || " "}
                      label="Tipo di costo"
                      onChange={(e) =>
                        analyticsRevenueForm.changeHandler(
                          "idtype",
                          e.target.value
                        )
                      }
                    >
                      <MenuItem value={" "}>
                        ...Seleziona tipo di costo
                      </MenuItem>
                      {factivities.map((data, index) => {
                        return (
                          <MenuItem key={index} value={data.id}>
                            {data.description}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <TextField
                fullWidth
                id="nome"
                name="import"
                sx={{ textTransform: "capitalize" }}
                label={"Importo"}
                error={Boolean(
                  analyticsRevenueForm.touched.amount &&
                    analyticsRevenueForm.errors.amount
                )}
                helperText={
                  Boolean(
                    analyticsRevenueForm.touched.amount &&
                      analyticsRevenueForm.errors.amount
                  )
                    ? analyticsRevenueForm.errors.amount
                    : analyticsRevenueForm.errors.amount
                    ? "* " + "Importo Obbligatorio"
                    : "* " + "Importo Obbligatorio"
                }
                type="number"
                variant="outlined"
                size="small"
                value={analyticsRevenueForm.values.amount || ""}
                onChange={(e) =>
                  analyticsRevenueForm.changeHandler(
                    "amount",
                    parseFloat(e.target.value)
                  )
                }
              />
            </Grid>
            <Grid item xs={6} md={6} lg={2}>
              <Button
                startIcon={<Add />}
                color="secondary"
                variant="outlined"
                disabled={!analyticsRevenueForm.isValid}
                onClick={() => {
                  addAnalyticsRevenue(analyticsRevenueForm, id, navigate);
                }}
                sx={{ textTransform: "capitalize" }}
              >
                {"Aggiungi Ricavo"}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <DataGrid
                autoHeight
                disableColumnMenu
                disableSelectionOnClick
                rows={costCenterAnalyticsRevenue === undefined ? []: costCenterAnalyticsRevenue}
                columns={columns}
                pagination
                onPageSizeChange={setPageSize}
                pageSize={pageSize}
                rowsPerPageOptions={ROWS_PER_PAGE}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CenterCostsRevenueTab;
