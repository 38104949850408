import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DailyActivitiesContext } from "../contexts";

const ViewBase = () => {
  const [base64, setBase64] = useState("");
  const [contentType, setContentType] = useState("");

  const {
    state: { filestream },
    downloadFileToActivities,
  } = React.useContext(DailyActivitiesContext);


  const { idActivity, idFile, mode } = useParams();

  useEffect(() => {
    downloadFileToActivities(idActivity, idFile, mode);
  }, []);
  useEffect(() => {
    setBase64(filestream.filestream)
  }, [filestream]);


  const renderContent = () => {
      return (
        <iframe
          src={`${base64}`}
          style={{ width: '100%', height: '100vh', border: 'none' }}
          title="base64 content"
        ></iframe>
      );
  };

  return (
    <>
    {renderContent()}
    </>
  );
};

export default ViewBase;
