import createDataContext from "./createContext";
import BaseRequest from "../api/Base";
import moment from "moment";

const SET_MANAGE_REQUEST = "set_manage_request";
const SET_MANAGE_REQUEST_APPORVED = "set_manage_request_approved";
const SET_PLANNED_ABSENCES = "set_planned_absences";

const INITIAL_STATE = {
  request: {},
  requests: [],
  searchedRequests: [],
  plannedAbsences: [],
  loading: true,
  searchRequests: {
    firstname: "",
    lastname: "",
    status: "",
    statusGroups: "",
    datetimefrom: "",
    datetimeto: "",
  },
};

const searchExecution = (requestsToFilter, searchForm) => {
  let requests = [...requestsToFilter];
  const { firstname, lastname, status, statusGroups, datetimefrom, datetimeto } = searchForm;
  if (firstname) {
    anomalies = requests.filter((request) =>
      request.firstname.toLowerCase().includes(firstname.toLowerCase())
    );
  }
  if (lastname) {
    requests = requests.filter((request) =>
      request.lastname.toLowerCase().includes(lastname.toLowerCase())
    );
  }
  if (status) {
    requests = requests.filter((request) =>
      request.status.toLowerCase().includes(status.toLowerCase())
    );
  }
  if (statusGroups) {
    requests = requests.filter((request) =>
      request.statusGroups.toLowerCase().includes(statusGroups.toLowerCase())
    );
  }
  if (datetimefrom) {
    requests = requests.filter((request) =>
      request.datetimefrom.toLowerCase().includes(datetimefrom.toLowerCase())
    );
  }
  if (datetimeto) {
    requests = requests.filter((request) =>
      request.datetimeto.toLowerCase().includes(datetimeto.toLowerCase())
    );
  }
  return requests;
};

const ManageRequestReducer = (state, action) => {
  switch (action.type) {
    case SET_MANAGE_REQUEST: {
      let requests = searchExecution(action.payload, state.searchRequests);
      return {
        ...state,
        searchedRequests: requests,
        requests: [...action.payload],
        request: {},
      };
    }
    case SET_MANAGE_REQUEST_APPORVED: {
      let reqIdx = state.searchedRequests.findIndex((requ) => requ.requestid === action.payload.requestid)
      let requests = [...state.searchedRequests]
      requests[reqIdx] = action.payload
      return {
        ...state,
        searchedRequests: requests,
        requests: requests,
        request: {},
      };
    }
    case SET_PLANNED_ABSENCES: {
      return {
        ...state,
        plannedAbsences: [...action.payload]
      };
    }
    default: {
      return state;
    }
  }
};

const setManageRequests = (dispatch) => async (data) => {
  let fristname = data.firstname || "ALL"
  let lastname = data.lastname|| "ALL"
  let status = data.status || "ALL" 
  let statusGroups = data.statusGroups || "ALL"
  let datetimefrom = data.datetimefrom || "ALL" 
  let datetimeto = data.datetimeto || "ALL"
  const response = await BaseRequest.get(`requests/approver/${fristname}/${lastname}/${status}/${statusGroups}/${datetimefrom}/${datetimeto}/ALL`);
  dispatch({ type: SET_MANAGE_REQUEST, payload: response.data.requests });
};

const approveManageRequests = (dispatch) => async (id, data) => {
  ;
  const response = await BaseRequest.put(`requests/outcomes/${id}`, data);
  dispatch({ type: SET_MANAGE_REQUEST_APPORVED, payload: response.data.requests[0] });
};

const setPlannedAbsences = (dispatch) => async () => {
  let datetime = moment().format('YYYY-MM-DD HH:mm');
  const response = await BaseRequest.get(`requests/approver/ALL/ALL/APPROVED/ALL/${datetime}:00/ALL/ALL`);
  dispatch({ type: SET_PLANNED_ABSENCES, payload: response.data.requests });
}


export const { Provider, Context } = createDataContext(
  ManageRequestReducer,
  {
    setManageRequests,
    approveManageRequests,
    setPlannedAbsences
  }, // actions
  INITIAL_STATE // initial state
);
