import createDataContext from "./createContext";
import { BaseService, FileUser, UsersService } from "../api";
import { openModal } from "../hooks/useModals";
import { ResetPassword } from "../components";
import { Button, Stack } from "@mui/material";
import { sendNotification } from "../hooks/useNotification";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import L from 'leaflet';

const iconPerson = new L.Icon({
    iconUrl: require("../assets/avatar_user2.png"),
    iconRetinaUrl: require("../assets/avatar_user2.png"),
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(20, 20),
    className: 'leaflet-div-icon'
  });

const SET_APPROVERS = "set_approvers";
const SET_COLLABORATORS = "set_collaborators";
const SET_USERS_STATUS = "set_users_status";

const INITIAL_STATE = {
    approvers: [],
    collaborators: [],
    usersStatus: [],
    searchedUsersStatus: [],
    searchUsers: {
        username: ''
    }
};

const userReducer = (state, action) => {
    switch (action.type) {
        case SET_APPROVERS: {
            return { ...state, approvers: action.payload };
        }
        case SET_COLLABORATORS: {
            return { ...state, collaborators: action.payload };
        }
        case SET_USERS_STATUS: {
            let usersList = searchExecution(action.payload.list, action.payload.searchForm);
            return { ...state, searchedUsersStatus: usersList, usersStatus: [...action.payload.list] };
        }
        default: {
            return state;
        }
    }
};

const searchExecution = (listToFilter, searchForm) => {
    let usersStatus = [...listToFilter];
    const { username } = searchForm;
    if (username) {
        usersStatus = usersStatus.filter((item) =>
            item.firstname.toLowerCase().includes(username.toLowerCase()) || item.lastname.toLowerCase().includes(username.toLowerCase())
        );
    }
    return usersStatus;
};

const setApprovers = (dispatch) => async () => {
    const response = await UsersService.get(`/collaborator/current/approvers`);
    dispatch({ type: SET_APPROVERS, payload: response.data });
};

const setCollaborators = (dispatch) => async () => {
    const response = await UsersService.get(`/approver/current/collaborators`);
    dispatch({ type: SET_COLLABORATORS, payload: response.data });
};

const setUsersStatus = (dispatch) => async (searchForm) => {
    const response = await UsersService.get(`/status`);
    dispatch({ type: SET_USERS_STATUS, payload: { list: response.data.users, searchForm: searchForm } });
}

const viewMapsPosition = (dispatch) => async (latitude, longitude, dictionary)=>{
    openModal(
        dictionary['position'],
        <MapContainer center={[latitude, longitude]} zoom={13} style={{height:300, width:'100%'}}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[latitude, longitude, ]} icon={iconPerson}>
            </Marker>
          </MapContainer>,
        undefined,
        (closeModal) => (
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              closeModal();
            }}
          >
            OK
          </Button>
        ),
        "xs"
      );
}

export const { Provider, Context } = createDataContext(
    userReducer,
    {
        setApprovers,
        setCollaborators,
        setUsersStatus,
        viewMapsPosition
    }, // actions
    INITIAL_STATE // initial state
);
