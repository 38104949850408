import createDataContext from "./createContext";
import BaseRequest from "../api/Base";
import moment from "moment";
import { generateUOW, getJustificationEventIdByTypeId } from "../utilities";

const SET_REPORTS = "set_reports";
const SEARCH_REPORTS = "search_reports";
const SET_USERS_REPORTS = "set_users_reports";

const INITIAL_STATE = {
  report: {},
  reports: [],
  searchedReport: [],
  loading: true,
  searchReports: {
    datetimefrom: '',
    datetimeto: '',
    event: ''
  },
  usersReports: []
};

const searchExecution = (reportsToFilter, searchForm) => {
  let reports = [...reportsToFilter];
  const { datetimefrom, datetimeto } = searchForm;
  if (datetimefrom) {
    reports = reports.filter((report) =>
      report.datetimefrom.toLowerCase().includes(datetimefrom.toLowerCase())
    );
  }
  if (datetimeto) {
    reports = reports.filter((report) =>
      report.datetimeto.toLowerCase().includes(datetimeto.toLowerCase())
    );
  }
  return reports;
};

const ReportsReducer = (state, action) => {
  switch (action.type) {
    case SET_REPORTS: {
      let reports = searchExecution(action.payload, state.searchReports);
      return { ...state, searchedReport: reports, reports: [...action.payload], report: {} };
    }
    case SEARCH_REPORTS: {
      let reports = searchExecution(action.payload, state.searchReports);
      return { ...state, searchedReport: reports, reports: [...action.payload], report: {} };
    }
    case SET_USERS_REPORTS: {
      return { ...state, usersReports: [...action.payload] };
    }
    default: {
      return state;
    }
  }
};

const setReports = dispatch => async ({ status, datetimefrom, datetimeto }) => {
  datetimefrom = datetimefrom ? `${moment(datetimefrom).format("YYYY-MM-DD")} 00:00:00` : "ALL";
  datetimeto = datetimeto ? `${moment(datetimeto).format("YYYY-MM-DD")} 23:59:00` : "ALL";
  const response = await BaseRequest.get(
    `events/current/timereport/${status}/${datetimefrom}/${datetimeto}`
  );
  dispatch({ type: SET_REPORTS, payload: response.data.events });
};

const justificationsReport = dispatch => async (report, formRef, searchForm, justificationsType) => {
  const originalJustifications = report.justifications;
  const { userid } = report;
  const newJustifications = formRef.justifications.map(justification => ({
    // ...justification.commission,
    // ...justification,
    minutescount: justification.minutescount,
    eventid: report.id,
    userid,
    notes: justification.notes,
    typeid: getJustificationEventIdByTypeId(justificationsType, report.typeid),
    costcenterid : justification.costcenter.id,
    costcenter: undefined,
    costcente: undefined,
  }));
  const justifications = generateUOW(originalJustifications, newJustifications, "costcenterid");
  await BaseRequest.post(`justifications/${userid}`, { justifications, userid });
  setReports(dispatch)(searchForm);
};

const setUsersReports = dispatch => async () => {
  const response = await BaseRequest.get(
    `events/approver/timereport/NOTJUSTIFIED/ALL/ALL`
  );
  dispatch({ type: SET_USERS_REPORTS, payload: response.data.events });
}

export const { Provider, Context } = createDataContext(
  ReportsReducer,
  {
    setReports,
    justificationsReport,
    setUsersReports,
  }, // actions
  INITIAL_STATE // initial state
);