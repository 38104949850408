import { Edit, HelpOutline, HelpOutlineTwoTone, Search } from "@mui/icons-material";
import { Alert, Card, CardContent, CardHeader, IconButton, Stack, Tooltip } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { IconBox } from "../components";
import { useCostCenters, useDictionary, useForm } from "../hooks";
import { DataGrid } from "@mui/x-data-grid";
import { renderHeader } from "../utilities";
import { useNavigate } from "react-router-dom";
import { CostCenterContext } from "../contexts";

const CostCenters = () => {
  const navigate = useNavigate()
  const costcenters = useCostCenters("2,3");

  const dictionary = useDictionary();

  const {
    setTitle
  } = useContext(CostCenterContext);


  const columns = [
    {
      field: "description",
      headerName: "Centro di Costo",
      flex: 1,
    },
    {
      field: "",
      headerName: dictionary["actions"],
      renderHeader: renderHeader(dictionary["actions"]),
      renderCell: ({ row }) => {
        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title={dictionary["edit"]} placement="top-start">
              <IconButton
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => {navigate(`/costcenter/${row.id}/${row.rights}`); setTitle(row.description)}}
              >
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack spacing={2}>
      <Card>
        <IconBox Icon={Search} />
        <CardHeader
          title={"Centri di Costo"}
          sx={{ pl: (theme) => theme.spacing(12) }}
        />
    <Alert icon={<HelpOutlineTwoTone fontSize="inherit" />} severity="info">
    In questa sezione puoi inserire i costi analitici per singolo centro di costo. La lista mostra solo i Centri di Costo abilitati alla modifica.
    </Alert>

      </Card>
      <Card>
        <CardContent>
          <DataGrid columns={columns} autoHeight rows={costcenters} />
        </CardContent>
      </Card>
    </Stack>
  );
};

export default CostCenters;
