import React, { useContext, useEffect } from "react";
import { Refresh, Search } from "@mui/icons-material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { AnomaliesTables, IconBox } from "../components";
import { useDictionary, useForm, useUser, useEventTypes } from "../hooks";
import { AnomaliesContext } from "../contexts";
import { isDateGreater } from "../hooks/useForm";
import { transformDateToDatetime } from "../utilities";

const Anomalies = () => {
  const INITIAL_STATE = {
    status: "NOTJUSTIFIED",
    type: "ALL"
  };
  const dictionary = useDictionary();
  const {user} = useUser();
  const types = useEventTypes();

  const {
    state: { anomalies },
    setAnomalies,
  } = useContext(AnomaliesContext);

  useEffect(() => {
    setAnomalies(user.id, INITIAL_STATE);
  }, []);

  const validations = [
    ({ datefrom, dateto }) =>
      isDateGreater(datefrom, dateto) || {
        datefrom: dictionary["anomalies"]["errors"]["fromGreaterTo"],
      },
  ];

  const {
    values: searchAnomalies,
    changeHandler,
    setValues,
    errors,
    touched,
    isValid,
    reset
  } = useForm(INITIAL_STATE, validations);

  return (
    <Stack spacing={2}>
      <Card elevation={5}>
        <IconBox Icon={Search} />
        <CardHeader
          title={dictionary["anomalies"]["title"]}
          sx={{ pl: (theme) => theme.spacing(12) }}
        />
        <CardContent component="form">
          <Grid container spacing={2} mb={1}>
            <Grid item xs={12} md={6} lg={3}>
              <DatePicker
                label={dictionary["anomalies"]["dateFrom"]}
                inputFormat="DD/MM/yyyy"
                value={searchAnomalies.datefrom || ""}
                onChange={(newValue) =>
                  changeHandler("datefrom", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.datefrom && errors.datefrom)}
                    helperText={touched.datefrom && errors.datefrom}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DatePicker
                label={dictionary["anomalies"]["dateTo"]}
                inputFormat="DD/MM/yyyy"
                value={searchAnomalies.dateto || ""}
                onChange={(newValue) =>
                  changeHandler("dateto", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.dateto && errors.dateto)}
                    helperText={touched.dateto && errors.dateto}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">{dictionary["anomalies"]["status"]}</InputLabel>
                <Select
                  id="demo-simple-select"
                  value={searchAnomalies.status || ""}
                  label={dictionary["anomalies"]["status"]}
                  onChange={(e) => changeHandler("status", e.target.value)}
                >
                  <MenuItem value={"ALL"}>{dictionary["anomalies"]["states"]["ALL"]}</MenuItem>
                  <MenuItem value={"JUSTIFIED"}>{dictionary["anomalies"]["states"]["JUSTIFIED"]}</MenuItem>
                  <MenuItem value={"NOTJUSTIFIED"}>
                    {dictionary["anomalies"]["states"]["NOTJUSTIFIED"]}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">{dictionary["anomalies"]["type"]}</InputLabel>
                <Select
                  id="demo-simple-select"
                  value={searchAnomalies.type || ""}
                  label={dictionary["anomalies"]["type"]}
                  onChange={(e) => changeHandler("type", e.target.value)}
                >
                  <MenuItem value={"ALL"}>{dictionary["anomalies"]["types"]["ALL"]}</MenuItem>
                  <MenuItem value={"LACK TIME"}>{dictionary["anomalies"]["types"]["LACK TIME"]}</MenuItem>
                  <MenuItem value={"OVERTIME"}>{dictionary["anomalies"]["types"]["OVERTIME"]}</MenuItem>
                  <MenuItem value={"ABSENCE"}>{dictionary["anomalies"]["types"]["ABSENCE"]}</MenuItem>
                  <MenuItem value={"DECLARED TIME DISCORDANT"}>
                    {dictionary["anomalies"]["types"]["DECLARED TIME DISCORDANT"]}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Stack direction="row" justifyContent={"flex-end"} spacing={2}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  disabled={!isValid}
                  startIcon={<Search />}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    setAnomalies(user.id, searchAnomalies);
                  }}
                >
                  {dictionary["search"]}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  startIcon={<Refresh />}
                  onClick={reset}
                >
                  {dictionary["reset"]}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <AnomaliesTables
            mini={false}
            searchForm={searchAnomalies}
            searchedAnomalies={anomalies}
            types={types}
          />
        </CardContent>
      </Card>
    </Stack>
  );
};

export default Anomalies;
