import React from 'react'
import { Typography, Stack, Grid } from '@mui/material'
import moment from 'moment';
import { useDictionary } from '../hooks';
import { translateMinutesInTime } from '../utilities';

const PermitDetailModal = ({ permit }) => {
  const dictionary = useDictionary();

  return (
    <Stack
      direction="row"
      spacing={2}
    >
      <Grid container>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>
              {dictionary["reports"]["dateFrom"]}
            </span>
            {' >> '}{moment(permit.datefrom).format("ddd: DD/MM/YYYY")}
          </Typography>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>
              {dictionary["permits"]["from"]}:
            </span>
            {permit.startinghour.substring(0, permit.startinghour.length - 3)}
          </Typography>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>
              {dictionary["permits"]["to"]}:
            </span>
            {permit.endinghour.substring(0, permit.endinghour.length - 3)}
          </Typography>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>
              {dictionary["hours"]}:
            </span>
            {translateMinutesInTime(permit.minutes)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>
            <span style={{fontWeight: "bold"}}>Note Richiedente:</span>
            </span>
            {permit.notes}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <hr />
        </Grid>
        {permit.outcomes.map((p) =>
          p.notes ? (
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1">
                <span style={{fontWeight: "bold"}}>Note Approvatore:</span>
              </Typography>
              {p.notes}
            </Grid>
          ) : null
        )}
      </Grid>
    </Stack>
  )
}

export default PermitDetailModal