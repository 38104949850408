import createDataContext from "./createContext";
import BaseRequest from "../api/Base";
import { openModal } from "../hooks/useModals";
import { Button, Stack } from "@mui/material";
import { convertBase64String } from "../utilities";
import { BaseNoLoaderService } from "../api";

const GET_ACTIVITIES = "set_activities";
const GET_ACTIVITIES_EXECUTION = "set_activities_executions";
const GET_USER_FOR_DAILY_ACTIVITIES = "set_user_for_daily_activites";
const SET_ACTIVITY_VIEW = "set_activity_view";
const GET_PREVISION_FILE = "get_prevision_file";
const GET_ACTIVITIES_FILE = "get_activities_file";
const SET_FILE_STREAM = "get_file_stream";
const GET_COSTSCENTER_USER_FOR_DAILY_ACTIVITIES =
  "set_costcenter_user_for_daily_activites";

const INITIAL_STATE = {
  dailyActivitiesExecutions: [],
  dailyActivities: [],
  userListForDailyActivities: [],
  costsCenterForUser: [],
  dataToPrevision: [],
  dataToActivities: [],
  filestream:"",
  dailyActivity: {
    userid: null,
    creatorid: null,
    costcenterid: null,
    title: "",
    plannedstartactivitydate: null,
    plannedstartactivitytime: null,
    plannedstartnotbeforedate: null,
    plannedstartnotbeforehour: null,
    plannedendactivitydate: null,
    plannedendactivitytime: null,
    performwithindate: null,
    performwithinhour: null,
    notbeforedate: null,
    notbeforehour: null,
    notes: null,
    objversion: 1,
    objstatus: "osDirty",
    extradata: {},
  },
};

const DATE_FORMAT = "YYYY-MM-DD";
const TIME_FORMAT = "HH:mm:ss";


const dailyActivitiesReducer = (state, action) => {
  switch (action.type) {
    case GET_ACTIVITIES: {
      const { dailyActivities } = action.payload;
      return { ...state, dailyActivities };
    }
    case GET_ACTIVITIES_EXECUTION: {
      const { dailyActivitiesExecutions } = action.payload;
      return { ...state, dailyActivitiesExecutions };
    }
    case GET_USER_FOR_DAILY_ACTIVITIES: {
      const { userListForDailyActivities } = action.payload;
      return { ...state, userListForDailyActivities };
    }
    case SET_ACTIVITY_VIEW: {
      const { dailyActivity } = action.payload;
      return { ...state, dailyActivity };
    }
    case GET_PREVISION_FILE: {
      const { dataToPrevision } = action.payload;
      return { ...state, dataToPrevision };
    }
    case GET_ACTIVITIES_FILE: {
      const { dataToActivities } = action.payload;
      return { ...state, dataToActivities };
    }
    case SET_FILE_STREAM: {
      const { filestream } = action.payload;
      return { ...state, filestream };
    }
    default: {
      return state;
    }
  }
};
const getActivities =
  (dispatch) =>
  async (user, dateFrom = null, dateTo = null, costcenterId =null, terminated=null, tostart=null, started=null) => {
    const param = {};
    if (dateFrom) {
      param.datefrom = dateFrom;
    }
    if (dateTo) {
      param.dateto = dateTo;
    }
    if (costcenterId) {
      if(costcenterId !== "ALL"){
        param.costcenterid = costcenterId;
      }
    }
    if (terminated) {
      param.terminated = terminated;
    }
    if (tostart) {
      param.tostart = tostart;
    }
    if (started) {
      param.started = started;
      }
    const response = await BaseRequest.get(
      `/users/${user.id}/dailyactivities`,
      { params: param }
    );
    if (response.data) {
      dispatch({
        type: GET_ACTIVITIES,
        payload: {
          dailyActivities: response.data.data,
        },
      });
    }
  };
const getActivitiesBySupervisior =
  (dispatch) =>
  async (user, dateFrom = null, dateTo = null, costcenterId =null, idUser=null, terminated=null, tostart=null, started=null) => {
    const param = {};
    if (dateFrom) {
      param.datefrom = dateFrom;
    }
    if (dateTo) {
      param.dateto = dateTo;
    }
    if (costcenterId) {
      if(costcenterId !== "ALL"){
        param.costcenterid = costcenterId;
      }
    }
    if (idUser) {
      param.userid = idUser;
    }
    if (terminated) {
      param.terminated = terminated;
    }
    if (tostart) {
      param.tostart = tostart;
    }
    if (started) {
      param.started = started;
    }
    const response = await BaseRequest.get(
      `/users/approvers/${user.id}/collaborators/dailyactivities`,
      { params: param }
    );
    if (response.data) {
      dispatch({
        type: GET_ACTIVITIES,
        payload: {
          dailyActivities: response.data.data,
        },
      });
    }
  };

const startActivities =
  (dispatch) =>
  async (
    dateRef,
    timeRef,
    closeModal,
    geolocationPosition,
    user,
    dailyAct,
    manage,
    singleView=false,
    geolocationEnabled
  ) => {
    let findExecution = dailyAct.executions.find(
      (res) =>
        res.execendactivitydate == null || res.execendactivitytime == null
    );
    let filterExecution = dailyAct.executions.filter(
      (res) =>
        res.execendactivitydate == null || res.execendactivitytime == null
    );
    let executions =
      findExecution && Object.keys(findExecution) > 0
        ? {
            ...findExecution,
            execstartactivitydate: dateRef,
            execestartactivitytime: timeRef.format(TIME_FORMAT),
            objversion: 1,
            objstatus: "osDirty",
            geolongitudein: geolocationEnabled ? geolocationPosition.current.longitude : 0,
            geolatitudein: geolocationEnabled ? geolocationPosition.current.latitude : 0,
          }
        : {
            id_dailyactivity: dailyAct.id,
            execstartactivitydate: dateRef,
            execstartactivitytime: timeRef.format(TIME_FORMAT),
            execendactivitydate: null,
            execendactivitytime: null,
            objversion: 1,
            objstatus: "osDirty",
            extradata: {},
            geolongitudein: geolocationEnabled ? geolocationPosition.current.longitude : 0,
            geolatitudein: geolocationEnabled ? geolocationPosition.current.latitude : 0,
          };
    let dataInsert = {
      ...dailyAct,
      /* startactivitydate: dateRef,
      startactivitytime: timeRef.format(TIME_FORMAT), */
      executions: [...filterExecution, executions],
    };
    const response = await BaseRequest.put(
      `/dailyactivities/${dailyAct.id}`,
      dataInsert
    );
    if (response) {
      closeModal();
      if (manage) {
        getActivitiesBySupervisior(dispatch)(user, new Date());
      } else {
        getActivities(dispatch)(user, new Date());
      }
      if(singleView){
        setActivity(dispatch)(dailyAct.id)
      }
    }
  };

const stopActivities =
  (dispatch) =>
  async (
    dateRef,
    timeRef,
    closeModal,
    geolocationPosition,
    user,
    dailyAct,
    manage,
    isDashboard,
    noteRef,
    singleView=false,
    geolocationEnabled
  ) => {
    let findExecution = dailyAct.executions.find(
      (res) =>
        res.execendactivitydate == null || res.execendactivitytime == null
    );
    let filterExecution = dailyAct.executions.filter(
      (res) =>
        res.execendactivitydate !== null || res.execendactivitytime !== null
    );
    let executions =
      /* findExecution && Object.keys(findExecution) >  0 ?  */
      {
        ...findExecution,
        execendactivitydate: dateRef,
        execendactivitytime: timeRef.format(TIME_FORMAT),
        note: noteRef,
        objversion: 1,
        objstatus: "osDirty",
        geolongitudeout: geolocationEnabled ? geolocationPosition.current.longitude :0,
        geolatitudeout: geolocationEnabled ? geolocationPosition.current.latitude : 0,
      };

    let dataInsert = {
      ...dailyAct,
      executions: [...filterExecution, executions],
    };

    const response = await BaseRequest.put(
      `/dailyactivities/${dailyAct.id}`,
      dataInsert
    );
    if (response) {
      closeModal();
      if (manage) {
        getActivitiesBySupervisior(dispatch)(user, new Date());
      } else {
        getActivities(dispatch)(user, new Date());
      }
      if (isDashboard) {
        activitiesInExecutions(dispatch)(user);
      }
      if(singleView){
        setActivity(dispatch)(dailyAct.id)
      }
    }
  };

const pauseActivities =
  (dispatch) =>
  async (dateRef, timeRef, closeModal, geolocationEnabled, user, dailyAct) => {
    let findExecution = dailyAct.executions.find(
      (res) =>
        res.execendactivitydate == null || res.execendactivitytime == null
    );
    let filterExecution = dailyAct.executions.filter(
      (res) =>
        res.execendactivitydate == null || res.execendactivitytime == null
    );
    let executions =
      findExecution && Object.keys(findExecution) > 0
        ? {
            ...findExecution,
            execendactivitydate: dateRef,
            execendactivitytime: timeRef.format(TIME_FORMAT),
            objversion: 1,
            objstatus: "osDirty",
          }
        : {
            id_dailyactivity: dailyAct.id,
            startactivitydate: dateRef,
            startactivitytime: timeRef.format(TIME_FORMAT),
            endactivitydate: null,
            endactivitytime: null,
            objversion: 1,
            objstatus: "osDirty",
            extradata: {},
          };
    let dataInsert = {
      ...dailyAct,
      executions: [...filterExecution, executions],
    };
    const response = await BaseRequest.put(
      `/dailyactivities/${dailyAct.id}`,
      dataInsert
    );
    if (response) {
      getActivities(dispatch)(user);
    }
  };

const insertDailyActivities =
  (dispatch) =>
  async (dataInsert, user, manage = false, handleClose, navigate) => {
    const response = await BaseRequest.post(`/dailyactivities`, dataInsert);
    if (manage) {
      if (response.status === 200 || response.status === 201) {
        let data = response.data.data;
        navigate(
          `/daily-activities/${data.id}?mode=edit&role=manage`
        );
        setActivity(dispatch)(data.id)
        getFileToPrevision(dispatch)(data.id);
      }
    } else {
      if (response.status === 200 || response.status === 201) {
        let data = response.data.data;
        navigate(`/daily-activities/${data.id}?mode=edit&role=user`);
        setActivity(dispatch)(data.id)
        getFileToPrevision(dispatch)(data.id);
      }
    }
    resetListFile(dispatch)()
  };
const updateDailyActivities =
  (dispatch) =>
  async (activities, user, manage = false, handleClose) => {
    const response = await BaseRequest.put(
      `/dailyactivities/${activities.id}`,
      activities
    );
    if (manage) {
      if (response.status === 200 || response.status === 201) {
        let data = response.data.data;
        getActivitiesBySupervisior(dispatch)(user, new Date());
        setActivity(dispatch)(data.id);
        getFileToPrevision(dispatch)(data.id);
      }
    } else {
      if (response.status === 200 || response.status === 201) {
        let data = response.data.data;
        getActivities(dispatch)(user, new Date());
        setActivity(dispatch)(data.id)
        getFileToPrevision(dispatch)(data.id);
      }
    }
    /* resetListFile(dispatch)() */
  };

const activitiesInExecutions = (dispatch) => async (user) => {
  const response = await BaseRequest.get(
    `/users/${user.id}/dailyactivities/currents`
  );
  if (response) {
    dispatch({
      type: GET_ACTIVITIES_EXECUTION,
      payload: {
        dailyActivitiesExecutions: response.data.data,
      },
    });
  }
};
const getUserListForSupervisor = (dispatch) => async () => {
  const response = await BaseRequest.get(
    `users/approver/current/collaborators?role=DAILYACTIVITY_CREATOR`
  );
  if (response) {
    dispatch({
      type: GET_USER_FOR_DAILY_ACTIVITIES,
      payload: {
        userListForDailyActivities: response.data.DAILYACTIVITY_CREATOR,
      },
    });
  }
};

const getUserListForSupervisorStats = (dispatch) => async () => {
  const response = await BaseRequest.get(
    `users/approver/current/collaborators?role=SUPERVISOR`
  );
  if (response) {
    dispatch({
      type: GET_USER_FOR_DAILY_ACTIVITIES,
      payload: {
        userListForDailyActivities: response.data.SUPERVISOR,
      },
    });
  }
};

const getCostsCenterForUser =
  (dispatch) =>
  async (iduser, rights = 1) => {
    const param = {};
    if (rights) {
      param.rights = rights;
    }
    const response = await BaseRequest.get(`/users/${iduser}/costcenters`, {
      params: param,
    });
  };

const addFileToPrevision = (dispatch) => async (idactivitiy, file) => {
  let dataFile = {
    entity_type: 1,
    entity_id: idactivitiy,
    filename: file.name,
    filestream: file.base64,
  };
  const response = await BaseRequest.post(
    `/dailyactivities/${idactivitiy}/attachments`,
    dataFile
  );
  if (response) {
    getFileToPrevision(dispatch)(idactivitiy);
  }else if (response.status === 413 ){
    
  }
};

const getFileToPrevision = (dispatch) => async (idactivitiy) => {
  const prevision = await BaseNoLoaderService.get(
    `/dailyactivities/${idactivitiy}/attachments?attachtype=${1}`
  );
  if (prevision) {
    dispatch({
      type: GET_PREVISION_FILE,
      payload: { dataToPrevision: prevision.data.data },
    });
  }

  const activities = await BaseRequest.get(
    `/dailyactivities/${idactivitiy}/attachments?attachtype=${2}`
  );
  if (activities) {
    dispatch({
      type: GET_ACTIVITIES_FILE,
      payload: { dataToActivities: activities.data.data },
    });
  }
};

const resetListFile = (dispatch) => async (idactivitiy) => {
    dispatch({
      type: GET_ACTIVITIES_FILE,
      payload: { dataToActivities: [], dataToPrevision: [] },
    });
    dispatch({
      type: GET_PREVISION_FILE,
      payload: { dataToPrevision: [] },
    });
};

const addFileToActivities = (dispatch) => async (idactivitiy, file) => {
  let dataFile = {
    entity_type: 2,
    entity_id: idactivitiy,
    filename: file.name,
    filestream: file.base64,
  };
  const response = await BaseNoLoaderService.post(
    `/dailyactivities/${idactivitiy}/executions/attachments`,
    dataFile
  );
  if (response) {
    getFileToPrevision(dispatch)(idactivitiy);
  }else if (response.status === 413 ){
  }
};

const deleteFileToActivities =
  (dispatch) => async (idactivitiy, id_file, isActivity) => {
    if(isActivity){
      const activities = await BaseRequest.delete(
        `/dailyactivities/${idactivitiy}/executions/attachments/${id_file}`,{});
      getFileToPrevision(dispatch)(idactivitiy);
    }else{
      const prevision  = await BaseRequest.delete(
        `/dailyactivities/${idactivitiy}/attachments/${id_file}`,{});
      getFileToPrevision(dispatch)(idactivitiy);
    }
  
  };

  const editFileToActivities =
  (dispatch) => async (idactivitiy, id_file, isActivity, data) => {
      if(isActivity){
        const activities = await BaseRequest.put(
          `/dailyactivities/${idactivitiy}/executions/attachments/${id_file}`,data);
        getFileToPrevision(dispatch)(idactivitiy);
      }else{
        const prevision  = await BaseRequest.put (
          `/dailyactivities/${idactivitiy}/attachments/${id_file}`,data);
        getFileToPrevision(dispatch)(idactivitiy);
      }
    };

  const downloadFileToActivities =
  (dispatch) => async (idactivitiy, id_file, isActivity) => {
    if(isActivity == true || isActivity == "true"){
      const activities = await BaseRequest.get(
        `/dailyactivities/${idactivitiy}/executions/attachments/${id_file}`,{});
        getFileToPrevision(dispatch)(idactivitiy);
        dispatch({
          type: SET_FILE_STREAM,
          payload: { filestream: {...activities.data.data, filestream : convertBase64String(activities.data.data?.filestream)} },
        });
    }else{
      const prevision  = await BaseRequest.get(
        `/dailyactivities/${idactivitiy}/attachments/${id_file}`,{});
        getFileToPrevision(dispatch)(idactivitiy);
        dispatch({
          type: SET_FILE_STREAM,
          payload: { filestream: {...prevision.data.data, filestream : convertBase64String(prevision.data.data?.filestream)} },
        });
      }
  
  };
  const newDownloadFileToActivities =
  (dispatch) => async (row, isActivity) => {
    let attachtype=isActivity ? 2 : 1
    try {
      const response = await BaseRequest.get(
        `/dailyactivities/${row.entityid}/downloads/attachments/${row.fileuuid.replace("{", "").replace("}", "")}?attachtype=${attachtype}`, 
        {
          responseType: 'blob'  // Assicurati che la risposta sia di tipo blob
        }
      );

      // Verifica che la risposta sia correttamente interpretata come blob
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.href = url;
      a.download = row.filename; // Cambia questo con il nome del file che desideri
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Optional: Revoke the object URL after the download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Errore nel download del file:', error);
    }
  };

const setActivity = (dispatch) => async (idActivity, setIsBound=false) => {
  const response = await BaseRequest.get(`/dailyactivities/${idActivity}`);
  if (response) {
    let result = response.data.data
    if(result?.notbeforedate || result?.performwithindate){
      if(setIsBound){
        setIsBound(true)
      }
    }
    dispatch({
      type: SET_ACTIVITY_VIEW,
      payload: { dailyActivity: result },
    });
  }
};
const resetActivity = (dispatch) => async () => {
  dispatch({
    type: SET_ACTIVITY_VIEW,
    payload: { dailyActivity: INITIAL_STATE.dailyActivity },
  });
};

const deleteActivities = (dispatch) => async (activites, manage, user) => {
  const action = async () =>
    await BaseRequest.delete(`/dailyactivities/${activites.id}`);
  openModal(
    "Vuoi eliminare questa attività?",
    activites.title,
    "warning",
    (closeModal) => (
      <Stack direction="row" spacing={2}>
        <Button
        id="confirm-delete-activity"
          color="success"
          variant="contained"
          size="small"
          onClick={async () => {
            await action();
            if (manage) {
              getActivitiesBySupervisior(dispatch)(user, new Date());
            } else {
              getActivities(dispatch)(user, new Date());
            }
            closeModal();
          }}
        >
          Conferma
        </Button>
        <Button
          color="error"
          variant="contained"
          size="small"
          onClick={closeModal}
        >
          Annulla
        </Button>
      </Stack>
    )
  );
};

export const { Provider, Context } = createDataContext(
  dailyActivitiesReducer,
  {
    getActivities,
    startActivities,
    stopActivities,
    insertDailyActivities,
    pauseActivities,
    activitiesInExecutions,
    getUserListForSupervisor,
    getCostsCenterForUser,
    getActivitiesBySupervisior,
    deleteActivities,
    setActivity,
    updateDailyActivities,
    resetActivity,
    addFileToPrevision,
    addFileToActivities,
    getFileToPrevision,
    deleteFileToActivities,
    editFileToActivities,
    downloadFileToActivities,
    newDownloadFileToActivities,
    resetListFile,
    getUserListForSupervisorStats
  }, // actions
  INITIAL_STATE // initial state
);
