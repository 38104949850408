import { useTheme, useMediaQuery as useMaterialMediaQuery } from '@mui/material';

const useMediaQuery = (mode, breakpoint) => {

    const theme = useTheme();
    const match = useMaterialMediaQuery(theme.breakpoints[mode](breakpoint));

    return [match];
}

export default useMediaQuery;