import axios from "axios";
import {BASE_URL, errorInterceptor, requestInterceptor, setLoading} from "./apiUtility";

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use((request) => {
  requestInterceptor(request);
  setLoading(true);
  return request;
});

instance.interceptors.response.use(
  (response) => {
    setLoading(false);
    return response;
  },
  (error) => {
    errorInterceptor(error, "base");
    setLoading(false);
    return Promise.reject(error);
  }
);

export default instance;
