import React from 'react';
import { 
    Alert, 
    Box, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    Zoom 
} from '@mui/material';

const Modals = ({modals, closeModal}) => {

    return (
        <Box sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        }}>
            {modals.map(({ title, message, id, actions, type, size }, index) => {
                return <Dialog
                    key={`modal${id}`}
                    maxWidth={size}
                    fullWidth
                    onClose={() => closeModal(id)}
                    open
                    TransitionComponent={Zoom}
                >
                    {title && <DialogTitle sx={{ display: "flex", flexDirection: "row" }}>
                        {/* <span style={{ flex: 1, textTransform: "capitalize" }}>{title}</span> NO CAPITALIZE*/} 
                        <span style={{ flex: 1}}>{title}</span>
                        {type && <Alert
                            severity={type}
                            sx={{ p: 0, background: "unset", flex: 0, mt: '-2px' }} />}
                    </DialogTitle>}
                    {message && <DialogContent>{message}</DialogContent>}
                    {actions && <DialogActions>
                        {actions}
                    </DialogActions>
                    }
                </Dialog>
            })}
        </Box>
    )
}

export default Modals