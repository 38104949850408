import {Grid, Typography} from "@mui/material";

const Note = ({richiesta}) => {
  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="body1">
          <span style={{fontWeight: "bold"}}>Note Richiedente:</span>
        </Typography>
        {richiesta.notes}
      </Grid>
      {richiesta.outcomes.map((r, idx) => {
        return (
          <>
            {idx == 0 && r.notes ? (
              <Grid item xs={12} md={12} lg={12}>
                <hr />
              </Grid>
            ) : null}
            {r.notes ? (
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant="body1">
                  <span style={{fontWeight: "bold"}}>Note Approvatore:</span>
                </Typography>
                {r.notes}
              </Grid>
            ) : null}
          </>
        );
      })}
    </Grid>
  );
};

export default Note;
