import { Paper, SwipeableDrawer } from '@mui/material'
import React, { useContext } from 'react'
import { ThemeContext } from '../contexts';
import { styled } from '@mui/material/styles';

const Sidebar = ({ children }) => {
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const { state: { sidebarOpen }, closeSidebar, openSidebar } = useContext(ThemeContext);

  return (
    <SwipeableDrawer
      variant="temporary"
      anchor="left"
      open={sidebarOpen}
      onClose={closeSidebar}
      onOpen={openSidebar}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      sx={{
        width: 250,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 250,
          boxSizing: 'border-box',
        },
        display: { xs: 'block', md: 'block', lg: 'none' }
      }}
    >
      <DrawerHeader>
        <img src={require('../assets/main_logo_bp.png')} alt="admin_logo" width={"250px"} height={"64px"} />
      </DrawerHeader>
      {children}
    </SwipeableDrawer>
  )
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // padding: theme.spacing(1, 0, 0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  height: "64px",
  justifyContent: 'flex-end'
}));

export default Sidebar