import {
  Grid,
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  CardMedia,
  Stack,
  Alert,
} from "@mui/material";
import { AuthContext } from "../contexts";
import { useForm, useDictionary } from "../hooks";
import React, { useContext } from "react";
import { isRequired } from "../hooks/useForm";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { login } = useContext(AuthContext);

  const navigate = useNavigate();

  const dictionary = useDictionary();

  const validations = [
    ({ password }) =>
      isRequired(password) || {
        password: dictionary["login"]["errors"]["password"],
      },
    ({ username }) =>
      isRequired(username) || {
        username: dictionary["login"]["errors"]["username"],
      },
  ];

  const {
    values: loginForm,
    changeHandler,
    touched,
    errors,
    isValid,
  } = useForm(
    {
      username: "",
      password: "",
    },
    validations
  );

  const navigateToAdmin = () => {
    window.location.href = "/admin";
  };

  return (
    <Stack direction="row" sx={{ justifyContent: "center" }}>
      <Card component="form" sx={{ maxWidth: 400 }}>
        <CardMedia
          component="img"
          image={require("../assets/main_logo_bp.png")}
          alt="logo"
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                autoComplete="username"
                id="username"
                label="Username"
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
                variant="outlined"
                value={loginForm.username}
                onChange={(e) => changeHandler("username", e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                fullWidth
                id="password"
                autoComplete="current-password"
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                type="password"
                label="Password"
                variant="outlined"
                value={loginForm.password}
                onChange={(e) => changeHandler("password", e.target.value)}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container spacing={2} direction="row" justifyContent="flex-end">
            <Grid
              item
              xs={12}
              textAlign="right"
              sx={{ pr: (theme) => theme.spacing(1) }}
              mb={2}
            >
              <Button
                size="small"
                type="submit"
                variant="contained"
                disabled={!isValid}
                color="success"
                onClick={(e) => {
                  e.preventDefault();
                  login(loginForm.username, loginForm.password);
                }}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </CardActions>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            navigateToAdmin();
          }}
        >
          Accedi alla Console Admin
        </Button>
      </Card>
    </Stack>
  );
};

export default Login;
