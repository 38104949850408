import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowBack,
  Circle,
  DateRange,
  FilterAltTwoTone,
  VerifiedUser,
} from "@mui/icons-material";
import { StatisticsContext } from "../contexts";
import {
  useCostCenters,
  useDictionary,
  useForm,
  useQueryParams,
} from "../hooks";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { formattedTimeStats } from "../utilities";

const ManageAnaliticsReports = () => {
  const dictionary = useDictionary();
  const navigate = useNavigate();
  const costcenters = useCostCenters();

  const {
    state: { analyticsReport, userSelect },
    setReportsAnalytics,
  } = useContext(StatisticsContext);
  
  const [analyticsReportData, setAnalyticsReportData] =
    useState(analyticsReport);
  const columns = [
    {
      field: "justificationgrouptype",
      headerName: "Tipo",
      headerAlign: "center",
      align: "center",
      minWidth: 80,
      valueFormatter: ({ value }) => {
        return value;
      },
      renderCell: ({ row }) =>
        row.justificationgrouptype === "O" ? (
          <Tooltip title={"Lavoro Ordinario"}>
            <Circle color="info" />
          </Tooltip>
        ) : row.justificationgrouptype === "S" ? (
          <Tooltip title={"Lavoro Straordinario"}>
            <Circle color="error" />
          </Tooltip>
        ) : (
          <Tooltip title={"Interventi"}>
            <Circle color="success" />
          </Tooltip>
        ),
    },
    {
      field: "executiondate",
      headerName: dictionary["date"],
      headerAlign: "center",
      align: "center",
      minWidth: 100,
      valueFormatter: ({ value }) => {
        return moment(value).format("DD/MM/YYYY");
      },
      renderCell: ({ row }) => (
        <Typography>
          {moment(row.executiondate).format("DD/MM/YYYY")}
        </Typography>
      ),
    },
    {
      field: "costcenter",
      headerName: "Centro di Costo",
      flex: 2,
    },
    {
      field: "minutes",
      headerName: "Ore",
      headerAlign: "right",
      align: "right",
      minWidth: 100,
      valueFormatter: ({ value }) => {
        let time = value / 60;
        return formattedTimeStats(time) + ":00";
      },
      renderCell: ({ row }) => {
        let time = row.minutes / 60;
        return <Typography>{formattedTimeStats(time)}</Typography>;
      },
    },
  ];

  let query = useQueryParams(dictionary);

  const { id } = useParams();

  useEffect(() => {
    let params = {
      id_user: id,
      datefrom: query("datefrom"),
      dateto: query("dateto"),
    };
    setReportsAnalytics(params);
  }, []);

  useEffect(() => {
    setAnalyticsReportData(analyticsReport);
  }, [setReportsAnalytics]);

  const {
    values: statsForm,
    changeHandler,
    setValues,
    errors,
    touched,
    isValid,
  } = useForm({ idcostcenter: "ALL" });

  const filterStats = (e) => {
    changeHandler("idcostcenter", e.target.value);
    if (e.target.value !== "ALL") {
      let data = analyticsReport;
      const filteredData = data.filter(
        (item) => item.costcenterid === e.target.value
      );
      setAnalyticsReportData(filteredData);
    } else {
      let params = {
        id_user: id,
        datefrom: query("datefrom"),
        dateto: query("dateto"),
      };
      setReportsAnalytics(params);
    }
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer direction={"flex-end"}>
        <Card elevation={0} sx={{ width: "100%" }}>
          <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
            Indietro
          </Button>
          <CardHeader
            title={"Dettaglio Statistiche"}
            action={<GridToolbarExport />}
          />
          <Grid item container spacing={4}>
            <Grid item xs={12} md={6} lg={6}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <VerifiedUser></VerifiedUser>
                  </ListItemAvatar>
                  <ListItemText
                    primary={dictionary["user"]}
                    secondary={userSelect.name}
                    secondaryTypographyProps={{
                      fontSize: 20,
                      fontWeight: "bold",
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <DateRange></DateRange>
                  </ListItemAvatar>
                  <ListItemText
                    primary={"Riferimento"}
                    secondary={userSelect.date}
                    secondaryTypographyProps={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <FilterAltTwoTone />
                  </ListItemAvatar>
                  <TextField
                    id="select"
                    label="Filtra per Centro di Costo"
                    select
                    value={statsForm.idcostcenter || "ALL"}
                    size="small"
                    sx={{ minWidth: 500 }}
                    onChange={(e) => filterStats(e)}
                  >
                    <MenuItem value={"ALL"}>Tutti</MenuItem>
                    {costcenters
                      .filter((res) =>
                        analyticsReport.some(
                          (item) => item.costcenterid === res.id
                        )
                      )
                      .map((costcenter, i) => (
                        <MenuItem key={i} value={costcenter.id}>
                          {costcenter.description}
                        </MenuItem>
                      ))}
                  </TextField>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Stack direction={"row"} justifyContent={"right"}>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Circle color="info" />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={"Ordinario"}
                      secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Circle color="error" />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={"Straordinario"}
                      secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Circle color="success" />
                    </ListItemAvatar>
                    <ListItemText
                      secondary={"Interventi"}
                      secondaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    />
                  </ListItem>
                </List>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </GridToolbarContainer>
    );
  }
  return (
    <Stack spacing={2}>
      <Card elevation={5}>
        <CardContent>
          <DataGrid
            mb={1}
            rows={analyticsReportData}
            autoHeight
            columns={columns}
            disableColumnMenu
            disableColumnSelector
            disableSelectionOnClick
            hideFooter
            /* pageSize={100} */
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </CardContent>
      </Card>
    </Stack>
  );
};

export default ManageAnaliticsReports;
