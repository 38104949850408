import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import moment from "moment";
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { openModal } from "../hooks/useModals";
import { DataGrid } from "@mui/x-data-grid";
import useCostCenters from "../hooks/useCostCenters";
import {
  renderHeader,
  translateMinutesInTime,
  translateTimeInMinutes,
} from "../utilities";
import { useDictionary, useForm } from "../hooks";
import { ReportsContext } from "../contexts";
import { isRequired } from "../hooks/useForm";

const ReportsForm = ({ report, formRef, totMin, isMobile }) => {
  const getTotJustifiedMinutes = (newJustifications) => {
    let _totMinutes = 0;
    newJustifications.forEach((next) => {
      _totMinutes += next.minutescount;
    });
    return _totMinutes;
  };
  const [justifications, setJustifications] = useState([]);
  const [totMinutes, setTotMinutes] = useState(
    getTotJustifiedMinutes(report.justifications)
  );

  const { minutescount: maxMinutesToCompile, datetimefrom, datetimeto } = report;

  const costcenters = useCostCenters(null, moment(datetimefrom).format("DD-MM-YYYY"), moment(datetimeto).format("DD-MM-YYYY"));
  const dictionary = useDictionary();
  

  const INITIAL_STATE = {
    minutescount: maxMinutesToCompile,
    costcenter: "",
    notes: "",
  };

  const validations = [
    ({ costcenter }) =>
      isRequired(costcenter) || {
        costcenter: dictionary["reports"]["errors"]["commissionRequired"],
      },
    ({ minutescount }) =>
      minutescount + totMinutes <= maxMinutesToCompile || {
        minutescount: dictionary["reports"]["errors"]["timeExceeded"],
      },
  ];

  const {
    values: costcentersForm,
    changeHandler,
    errors,
    touched,
    isValid,
    setValues,
  } = useForm(INITIAL_STATE, validations);

  const handleSetJustifications = (newJustifications) => {
    setJustifiedMinutes(newJustifications);
    setJustifications(newJustifications);
    formRef.justifications = newJustifications;
  };

  useEffect(() => {
    if (report.justifications.length > 0 ) {
      const newJustifications = report.justifications.map((justification) => {
        const costcenter = justification.costcenter
        return {
          ...justification,
          costcenter,
        };
      });
      handleSetJustifications(newJustifications);
    }
  }, [report, costcenters]);

  useEffect(() => {
    if (justifications.length > 0) {
      justifications.forEach((justification) => {
        costcenters.filter((costcenter) => costcenter.id !== justification.id);
      });
    }
  }, [justifications]);

  const setJustifiedMinutes = (newJustifications) => {
    setTotMinutes(getTotJustifiedMinutes(newJustifications));
  };

  const addCommission = () => {
    const costcenter = costcenters.find(
      (c) => c.id === costcentersForm.costcenter
    );
    const newJustifications = [
      ...justifications,
      {
        costcenter,
        minutescount: costcentersForm.minutescount,
        notes: costcentersForm.notes,
      },
    ];
    setValues({
      minutescount: maxMinutesToCompile - (totMinutes + costcentersForm.minutescount),
      costcenter: "",
      notes: ""
    })
    handleSetJustifications(newJustifications);
  };

  const deleteCostCenter = (e, row) => {
    e.stopPropagation();
    const newJustifications = justifications.filter(
      (justification) => justification.costcenter.id !== row.costcenter.id
    );
    changeHandler("costcenter", "")
    handleSetJustifications(newJustifications);
  };

  const columnsMobile = [
    {
      field: "minutescount",
      headerName: dictionary["reports"]["hours"],
      renderHeader: renderHeader(dictionary["reports"]["hours"]),
      renderCell: ({ value }) => translateMinutesInTime(value),
    },
    {
      field: "costcenter",
      renderCell: ({ value }) => value.description,
      headerName: dictionary["dailyactivities"]["costcenter"],
      renderHeader: renderHeader(dictionary["dailyactivities"]["costcenter"]),
    },
    {
      field: "",
      headerName: dictionary["actions"],
      renderHeader: renderHeader(dictionary["actions"]),
      renderCell: (parmas) => {
        const costcenter = costcenters.find(
          (c) => c.id === parmas.row.costcenterid
        );
        const notFound = costcenter === undefined;
        let isApprovedByAdmin =parmas.row.notes.includes("ADMIN APPROVED")
        if (!isApprovedByAdmin) {
          return (
            <Stack direction="row" spacing={1}>
              <Tooltip title={dictionary["delete"]} placement="top-start">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={(e) => deleteCostCenter(e, parmas.row)}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }
      },
    },
  ];

  const columnsDesk = [
    {
      field: "minutescount",
      headerName: dictionary["reports"]["hours"],
      renderHeader: renderHeader(dictionary["reports"]["hours"]),
      renderCell: ({ value }) => translateMinutesInTime(value),
    },
    {
      field: "costcenter",
      renderCell: ({ value }) => value.description,
      headerName: dictionary["dailyactivities"]["costcenter"],
      renderHeader: renderHeader(dictionary["dailyactivities"]["costcenter"]),
      flex: 1,
    },
    {
      field: "notes",
      headerName: dictionary["notes"],
      renderHeader: renderHeader(dictionary["notes"]),
    },
    {
      field: "dsadsa",
      headerName: dictionary["actions"],
      renderHeader: renderHeader(dictionary["actions"]),
      renderCell: (parmas) => {
        const costcenter = costcenters.find(
          (c) => c.id === parmas.row.costcenterid
          );
        const notFound = costcenter === undefined;
        let isApprovedByAdmin =parmas.row.notes.includes("ADMIN APPROVED")
        if (!isApprovedByAdmin){
        return (
          <Stack direction="row" spacing={1}>
            <Tooltip title={dictionary["delete"]} placement="top-start">
              <IconButton
                color="primary"
                size="small"
                onClick={(e) => deleteCostCenter(e, parmas.row)}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
        }
      },
    },
  ];

  let columns = [];

  isMobile ? (columns = columnsMobile) : (columns = columnsDesk);

  const getRowId = useCallback((row) => row.costcenter.id, []);
  let hasMatch =
    justifications.length > 0
      ? justifications.some(function (value) {
        return value.status == "APPROVED";
      })
      : false;
  totMin = totMinutes;
  if (!hasMatch || totMinutes > 0) {
    return (
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12} md={12} lg={12}>
          <DataGrid
            density="compact"
            rows={justifications}
            getRowId={getRowId}
            autoHeight
            columns={columns}
            disableColumnMenu
            disableSelectionOnClick
            hideFooter
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <TextField
            fullWidth
            size="small"
            id="time"
            error={Boolean(touched.minutescount && errors.minutescount)}
            helperText={touched.minutescount && errors.minutescount}
            label={dictionary["reports"]["workedHours"]}
            type="time"
            value={translateMinutesInTime(costcentersForm.minutescount)}
            onChange={({ target }) => {
              changeHandler(
                "minutescount",
                translateTimeInMinutes(target.value)
              );
            }}
            step="00:15"
          />
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          <FormControl fullWidth size="small">
            <InputLabel id="eventType-select">
              {dictionary["dailyactivities"]["costcenter"]}
            </InputLabel>
            <Select
              id="eventType-select"
              value={costcentersForm.costcenter || ""}
              onChange={(e) => changeHandler("costcenter", e.target.value)}
              label={"Centro di costo"}
            >
              <MenuItem value="">
                {dictionary["dailyactivities"]["notcostcenter"]}
              </MenuItem>
              {costcenters.map((costcenter, i) => (
                <MenuItem key={i} value={costcenter.id}>
                  {costcenter.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Stack direction="row" spacing={1}>
            <TextField
              size="small"
              fullWidth
              autoComplete="note"
              id="note"
              label={dictionary["notes"]}
              variant="outlined"
              value={costcentersForm.notes || ""}
              onChange={(e) => changeHandler("notes", e.target.value)}
            />
            <Button
              color="success"
              size="small"
              disabled={!isValid || maxMinutesToCompile === totMinutes}
              variant="contained"
              onClick={addCommission}
            >
              {dictionary["add"]}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12} md={12} lg={12}>
          <DataGrid
            density="compact"
            rows={justifications}
            getRowId={getRowId}
            autoHeight
            sx={{minHeight:150}}
            columns={columns}
            disableColumnMenu
            disableSelectionOnClick
            hideFooter
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        </Grid>
      </Grid>
    );
  }
};

const ReportsModal = ({ report, searchForm, justificationsType, isMobile }) => {
  const { justificationsReport } = useContext(ReportsContext);
  let totMinutes = 0;
  const dictionary = useDictionary();

  const handleAddClocking = () => {
    let isNotApproved = false;
    report.justifications.forEach((approved) => {
      if (approved.status !== "APPROVED" || totMinutes > 0) {
        isNotApproved = false;
      }
      isNotApproved = true;
    });

    const form = {};
    openModal(
      `${dictionary["reports"]["compile"]} ${moment(report.datetimefrom).format("DD/MM/YYYY")}`,
      <ReportsForm
        formRef={form}
        report={report}
        totMin={totMinutes}
        isMobile={isMobile}
      />,
      undefined,
      (closeModal) => (
        <>
          <Stack direction="row" spacing={2}>
            <Button
              color="success"
              size="small"
              variant="contained"
              disabled={isNotApproved && totMinutes > 0}
              onClick={() => {
                justificationsReport(
                  report,
                  form,
                  searchForm,
                  justificationsType
                );
                closeModal();
              }}
            >
              {dictionary["confirm"]}
            </Button>
            <Button
              color="error"
              variant="contained"
              size="small"
              onClick={closeModal}
            >
              {dictionary["cancel"]}
            </Button>
          </Stack>
        </>
      ),
      "md"
    );
  };

  return (
    <Tooltip title={dictionary["compile"]} placement="top-start">
      <IconButton color="primary" size="small" onClick={handleAddClocking}>
        <Edit fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default ReportsModal;
