import {MoreVert, OpenInNew} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {ClockingsContext} from "../contexts";
import {useDictionary, useUser} from "../hooks";
import {openModal} from "../hooks/useModals";
import {deleteMillisFromTime} from "../utilities";
import ClockingForm from "./ClockingForm";

const UserImage = () => {
  const {user, image, getUserImage} = useUser();
  const [currentuserid, setCurrentuserid] = useState(user.id)

  useEffect(() => {
    if(user.id !== currentuserid){
      getUserImage('current')
      setCurrentuserid(user.id)
    }
  }, [user])

  return (<Avatar sx={{height: "300px", width: "100%", borderRadius: "unset"}} src={image} alt={user.emailAddress} />)
}

const ClockingPanel = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const timeRef = useRef();
  const dateRef = useRef();
  const {
    state: {lastClocking},
    setLastClocking,
    confirmClock,
    deleteLastClocking,
  } = useContext(ClockingsContext);

  const {user, image, getUserImage, geolocationEnabled} = useUser();
  const dictionary = useDictionary();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDeleteClocking = () => {
    deleteLastClocking(dictionary, lastClocking);
    handleClose();
  };

  useEffect(() => {
    if (user.id && !image){
      getUserImage('current')
    }
  }, [user, image])


  useEffect(() => {
    setLastClocking();
  }, []);

  const handleClock = (direction) => {
    openModal(
      dictionary["clockings"][direction === "OUT" ? "clockOut" : "clockIn"],
      <ClockingForm
        direction={direction}
        geolocationEnabled={geolocationEnabled}
        // lastClocking={lastClocking}
        dictionary={dictionary}
        timeRef={timeRef}
        dateRef={dateRef}
      />,
      null,
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            color="success"
            variant="contained"
            size="small"
            onClick={() => confirmClock(direction, dateRef.current, timeRef.current, closeModal, geolocationEnabled)}>
            {dictionary["confirm"]}
          </Button>
          <Button color="error" variant="contained" size="small" onClick={closeModal}>
            {dictionary["cancel"]}
          </Button>
        </Stack>
      )
    );
  };

  return (
    <Card>
      <Box sx={{position: "relative"}}>
        <IconButton
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 2,
            backgroundColor: "rgba(0,0,0,0.3)",
            color: (theme) => theme.palette.common.white,
          }}
          component={Link}
          to="/profile">
          <OpenInNew />
        </IconButton>
        <UserImage/>
      </Box>
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Typography gutterBottom variant="h4" component="div">
              {user.firstname} {user.lastname}
            </Typography>
          </Grid>
          {lastClocking && lastClocking.clockingdirection && (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant="body1" sx={{fontWeight: "bold"}} color="text.secondary">
                  {dictionary["clockings"]["lastClocking"]}
                </Typography>
              </Grid>
              <Grid sx={{display: "flex", alignItems: "center"}} item xs={11} md={11} lg={11}>
                <Typography variant="body1" color="text.secondary">
                  <span style={{fontWeight: "bold"}}>
                    {dictionary["clockings"]["clockingDirections"][lastClocking.clockingdirection]}
                  </span>
                  {" >> "}
                  {moment(lastClocking.declareddate).format("ddd:")}&nbsp;
                  {moment(lastClocking.declareddate).format("DD/MM/YYYY")} -&nbsp;
                  {deleteMillisFromTime(lastClocking.declaredtime)}
                </Typography>
              </Grid>
              <Grid sx={{display: "flex", alignItems: "center"}} item xs={1} md={1} lg={1}>
                <IconButton
                  size="small"
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleOpen}>
                  <MoreVert fontSize="small" />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}>
                  <MenuItem id="delete-last-btn" onClick={handleDeleteClocking}>{dictionary["clockings"]["deleteLast"]}</MenuItem>
                </Menu>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant="body1" sx={{fontWeight: "bold"}} color="text.secondary">
                  {dictionary["clockings"]["clockingPoint"]}:{" "}
                  <span style={{fontWeight: "normal"}}>{lastClocking?.clockingpointdescription}</span>
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <CardActions>
        <Button
         id="clock-in-btn"
          fullWidth
          variant="contained"
          disabled={lastClocking?.clockingdirection === "IN"}
          onClick={() => handleClock("IN")}>
          {dictionary["clockings"]["clockIn"]}
        </Button>
        <Button
        id="clock-out-btn"
          fullWidth
          disabled={lastClocking ? lastClocking.clockingdirection === "OUT" : true}
          variant="contained"
          onClick={() => handleClock("OUT")}>
          {dictionary["clockings"]["clockOut"]}
        </Button>
      </CardActions>
    </Card>
  );
};

export default ClockingPanel;
