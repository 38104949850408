import React, {useContext, useRef} from "react";
import {Typography, Stack, Grid, IconButton, Button} from "@mui/material";
import moment from "moment";
import {useDictionary} from "../hooks";
import {Edit} from "@mui/icons-material";
import {openModal} from "../hooks/useModals";
import ClockingForm from "./ClockingForm";
import {CalendarContext} from "../contexts";
import { deleteMillisFromTime } from "../utilities";

const ClockingsDetailModal = ({clockings, closeModalRef, firstDay, lastDay, userid}) => {
  const dictionary = useDictionary();
  const timeRef = useRef();
  const dateRef = useRef();

  const {
    setCalendarUserEvents,
    setCalendarUserClockings,
    setCalendarUserAnomalies,
    setCalendarUserPermits,
    setCalendarUserHolidays,
    updateClock,
  } = useContext(CalendarContext);

  const handleClock = (direction, item) => {
    openModal(
      `${dictionary["clockings"]["edit"]} ${dictionary["clockings"]["clockingDirections"][direction]}`,
      <ClockingForm
        direction={direction}
        lastClocking={item}
        dictionary={dictionary}
        timeRef={timeRef}
        dateRef={dateRef}
        edit={true}
      />,
      null,
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            color="success"
            variant="contained"
            size="small"
            onClick={() =>
              updateClock(
                item.id,
                dateRef.current,
                timeRef.current,
                () => {
                  setCalendarUserEvents(userid, firstDay, lastDay);
                  setCalendarUserClockings(firstDay, lastDay);
                  setCalendarUserAnomalies(userid, firstDay, lastDay);
                  setCalendarUserPermits(userid, firstDay, lastDay);
                  setCalendarUserHolidays(userid, firstDay, lastDay);
                  closeModal();
                  closeModalRef.current();
                },
                dictionary
              )
            }>
            {dictionary["confirm"]}
          </Button>
          <Button color="error" variant="contained" size="small" onClick={closeModal}>
            {dictionary["cancel"]}
          </Button>
        </Stack>
      )
    );
  };

  return (
    <Stack>
      <Grid container>
        {clockings.map((item, index) => (
          <Grid item xs={12} md={12} lg={12} key={`${index}-clocking-modal`}>
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <Typography variant="body1">
                  {item.clockingdirection === "IN" ? (
                    <span>
                      <span style={{fontWeight: "bold"}}>{dictionary["reports"]["dateFrom"]}</span>
                      {" >> "}
                      {moment(item.declareddate).format("ddd:")}&nbsp;
                      {moment(item.declareddate).format("DD/MM/YYYY")} -&nbsp;
                      {deleteMillisFromTime(item.declaredtime)}
                    </span>
                  ) : null}
                  {item.clockingdirection === "OUT" ? (
                    <span>
                      <span style={{fontWeight: "bold"}}>{dictionary["reports"]["dateTo"]}</span>
                      {" >> "}
                      {moment(item.declareddate).format("ddd:")}&nbsp;
                      {moment(item.declareddate).format("DD/MM/YYYY")} -&nbsp;
                      {deleteMillisFromTime(item.declaredtime)}
                    </span>
                  ) : null}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Typography variant="body1">
                  CP {" >> "} {item.clockingpointdescription}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <IconButton color="primary" size="small" onClick={() => handleClock(item.clockingdirection, item)}>
                  <Edit fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default ClockingsDetailModal;
