import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { sendNotification } from "../hooks/useNotification";

const useQueryParams = (dictionary) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  
  const get = useCallback((key) => {
    let value = params.get(key);
    if (value) {
        return value; 
    }
    sendNotification(dictionary["errors"]["base"], dictionary["errors"]["paramsrequests"], "error", 5);
  }, [search])
  return get;
};

export default useQueryParams;