import React, {useContext, useEffect} from "react";
import {useQueryParams} from "../hooks";
import {useNavigate} from "react-router-dom";
import {STORE_JWT, getRedirectUrl} from "../utilities";
import { AuthContext } from "../contexts";

const Landing = () => {
  const query = useQueryParams();
  const navigate = useNavigate();
  const jwt = query("jwt");
  const zona = query("zona");
  const {getUser} = useContext(AuthContext);

  useEffect(() => {
    const asyncGetUser = async () => {
      await getUser();
    }

    if (jwt && zona) {
      localStorage.setItem(STORE_JWT, query("jwt"));
      asyncGetUser();
      const routes = getRedirectUrl(query("zona"));
      navigate(routes)
    } else {
      navigate("/");
    }

  }, []);

  return <div></div>;
};

export default Landing;
