import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import { useForm } from "../hooks";

export default function FormDialog({icon, row, methodForm, isActivity}) {
  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { values, isValid, errors, touched, changeHandler } = useForm(row, []);

  return (
    <React.Fragment>
      <IconButton
        size="small"
        onClick={handleClickOpen}
      >
        {icon}
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Modifica </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Qui puoi modificare la descrizione del file caricato!
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            value={values.description}
            onChange={(e)=>changeHandler("description", e.target.value)}
            name="description"
            label="Descrizione File"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button id="confirm-description" onClick={()=> {methodForm(row.entityid, row.id, isActivity, values), handleClose()}} color="success" variant="contained">Conferma</Button>
          <Button onClick={handleClose} color="error" variant="contained">Annulla</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
