import React from 'react'
import { useDictionary } from "../hooks";

const NotFound = () => {
  const dictionary = useDictionary();
  
  return (
    <>
      <p className="zoom-area">{dictionary["404"]["message"]} </p>
      <section className="error-container">
        <span className="four"><span className="screen-reader-text">4</span></span>
        <span className="zero"><span className="screen-reader-text">0</span></span>
        <span className="four"><span className="screen-reader-text">4</span></span>
      </section>
    </>
  )
}

export default NotFound;