import React from 'react';
import { Box, CircularProgress, Snackbar } from '@mui/material';

const LoadingBackdrop = () => {
    return (
        <Box sx={{
            opacity: 0.7, backgroundColor: 'black', display: 'flex',
            justifyContent: "center", alignItems: "center",
            position: 'fixed', height:'100vh', top: 0, bottom: 0, left: 0, right: 0, zIndex: 9999
        }}>
            <CircularProgress />
            <Snackbar
                open
                sx={{ zIndex: 10000}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                message="Fetching data..."
            />
        </Box>
    );
};

export default LoadingBackdrop;
