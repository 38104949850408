import { Typography, Stack, Divider, Box, Chip } from '@mui/material'
import React from 'react'
import moment from 'moment';
import { useDictionary, useJustificationsTypes } from '../hooks';
import { deleteMillisFromTime, translateMinutesInTime } from '../utilities';

const EventDetailModal = ({ event, commissions }) => {

  const dictionary = useDictionary();
  const justificationTypes = useJustificationsTypes();
 
  const [datefrom, timefrom] = event.datetimefrom.split("T");
  const [dateto, timeto] = event.datetimeto.split("T");
  
  
  return (
    <Stack>
      <Typography variant="body1">
        <span style={{ fontWeight: "bold" }}>
          {dictionary["hours"]}:
        </span>
        {translateMinutesInTime(event.minutescount)}
      </Typography>
      <Typography variant="body1">
        <span style={{ fontWeight: "bold" }}>
          {dictionary["reports"]["dateFrom"]}
        </span>
        {' >> '}
        {moment(datefrom).format('ddd:')}&nbsp;
        {moment(datefrom).format('DD/MM/YYYY')} -&nbsp;{deleteMillisFromTime(timefrom)}
      </Typography>
      <Typography variant="body1">
        <span style={{ fontWeight: "bold" }}>
          {dictionary["reports"]["dateTo"]}
        </span>
        {' >> '}
        {moment(dateto).format('ddd:')}&nbsp;
        {moment(dateto).format('DD/MM/YYYY')} -&nbsp;{deleteMillisFromTime(timeto)}
      </Typography>
      {
        event.justifications.length > 0
          ? <Stack spacing={2}>
            <Divider />
            {event.justifications.map((item, index) =>
              <Box sx={{ pl: 2, borderLeft: `5px solid`, borderColor: _ => _.palette.primary.main }} key={`${index}-anomaly-just`}>
                {item.status === "APPROVED" ? <Chip sx={{ width: "100%" }} size="small" label={dictionary["requestStatus"][item.status]} color="success" /> : null}
                <Typography variant="body1">
                  <span style={{ fontWeight: "bold" }}>
                    {dictionary["type"]}:
                  </span>
                  {justificationTypes.find(just => just.id === item.typeid)?.description}
                </Typography>
                {
                  translateMinutesInTime(item.minutescount) !== "00:00"
                    ? <Typography variant="body1">
                      <span style={{ fontWeight: "bold" }}>
                        {dictionary["hours"]}:
                      </span>
                      {translateMinutesInTime(item.minutescount)}
                    </Typography>
                    : null
                }
                { item.costcenter  && (
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>
                      {dictionary["dailyactivities"]["costcenter"]}:
                    </span>
                    {" "+ item.costcenter?.description}
                  </Typography>
                )}
                { item.notes && (
                  <Typography variant="body1">
                    <span style={{ fontWeight: "bold" }}>
                      {dictionary["notes"]}:
                    </span>
                    {item.notes}
                  </Typography>
                )}
              </Box>
            )}
          </Stack>
          : null
      }
    </Stack>
  )
}

export default EventDetailModal