import { useState } from 'react';

export const ROWS_PER_PAGE = [
    25, 50, 100
];
const usePagination = () => {

    const [pageSize, setPageSize] = useState(25);

    return [pageSize, setPageSize];
}

export default usePagination;