import {IconButton, Stack, Card, CardContent, Typography, Badge, TextField, Button} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import React, {useState} from "react";
import {ROWS_PER_PAGE} from "../hooks/usePagination";
import {useDictionary, useEventTypes, useJustificationsTypes, useMediaQuery, usePagination, useForm} from "../hooks";
import moment from "moment";
import {Block, Done, Refresh, Search} from "@mui/icons-material";
import {openModal} from "../hooks/useModals";
import AddNotes from "./AddNotes";
import {deleteMillisFromTime} from "../utilities";

const ManageJustificationsTables = ({
  mini = false,
  searchedJustifications,
  searchForm = {},
  approveManageJustification,
}) => {
  const [pageSize, setPageSize] = usePagination();
  const types = useEventTypes(mini);
  const dictionary = useDictionary();
  const [isMobile] = useMediaQuery("down", "md");

  const columnsDash = [
    {
      field: "userfirstname",
      headerName: dictionary["user"],
      flex: 1,
      valueGetter: (params) => params.row.userfirstname + " " + params.row.userlastname,
    },
    {
      field: "type",
      headerName: dictionary["type"],
      flex: 1,
      valueGetter: (params) => params.row.justification.typedescription,
    },
  ];

  const Approved = (row) => {
    let data = [];
    data = {...row, status: "APPROVED"};
    approveManageJustification(row.id, data, dictionary);
  };

  const NotApproved = (row) => {
    let data = [];
    const action = async (form, closeModal) => {
      data = {...row, status: "NOT_APPROVED", notes: form.notes};
      approveManageJustification(row.id, data, closeModal);
    };
    const form = {notes: ""};
    openModal(dictionary["note"], <AddNotes formRef={form} />, "info", (closeModal) => (
      <Stack direction="row" spacing={2}>
        <Button
          color="success"
          variant="contained"
          size="small"
          onClick={async () => {
            await action(form, closeModal);
          }}>
          {dictionary["confirm"]}
        </Button>
        <Button color="error" variant="contained" size="small" onClick={closeModal}>
          {dictionary["cancel"]}
        </Button>
      </Stack>
    ));
  };

  let columns = [];

  function contieneElementi(array1, array2) {
    for (let i = 0; i < array1.length; i++) {
      if (array2.includes(array1[i])) {
        return true; // Trovato un elemento comune
      }
    }
    return false; // Nessun elemento comune trovato
  }

  const columnsDesk = [
    {
      field: "userfirstname",
      headerName: dictionary["user"],
      flex: 1,
      renderCell: (params) => params.row.userfirstname + " " + params.row.userlastname,
    },
    {
      field: "timbrature",
      headerName: dictionary["managejustification"]["clockings"],
      flex: 1,
      renderCell: (params) => {
        if (params.row.clockings.length > 0) {
          return (
            <Stack spacing={0.5}>
              <Stack direction="row" spacing={0.5} mt={1}>
                <Typography sx={{fontWeight: "bold"}} variant="body2">
                  {dictionary["IN"]}:{" "}
                </Typography>
                {params.row.clockings.map((clock, i) => {
                  if (clock.clockingdirection == "IN") {
                    return (
                      <Typography key={i} variant="body2">
                        {moment(clock.declareddate).format("DD/MM/YYYY")}&nbsp;
                        {deleteMillisFromTime(clock.declaredtime)}
                      </Typography>
                    );
                  }
                })}
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography sx={{fontWeight: "bold"}} variant="body2">
                  {dictionary["OUT"]}:{" "}
                </Typography>
                {params.row.clockings.map((clock, i) => {
                  if (clock.clockingdirection == "OUT") {
                    return (
                      <Typography key={i} variant="body2">
                        {moment(clock.declareddate).format("DD/MM/YYYY")}&nbsp;
                        {deleteMillisFromTime(clock.declaredtime)}
                      </Typography>
                    );
                  }
                })}
              </Stack>
            </Stack>
          );
        }
      },
    },
    {
      field: "type",
      headerName: dictionary["managejustification"]["relevance"],
      flex: 1,
      renderCell: (params) => {
        let filter = types.find((res) => res.id === params.row.anomaly.typeid);
        let period = params.row.anomaly.periods.replaceAll("||", " - ").replaceAll("|", "");
        let periodSplit = period.split(" - ");
        return (
          <>
            <Stack spacing={0.5}>
              <Typography variant="body2">{filter && dictionary["types"][filter.description]}</Typography>
              <Stack spacing={0.5} mt={1}>
                {periodSplit.map((p, idx) => {
                  const [date, time] = p.includes("T") ? p.split("T") : p.split(" ");
                  return (
                    <Typography variant="body3" key={idx}>
                      {moment(date).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(time)}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </>
        );
      },
    },
    {
      field: "justification",
      headerName: dictionary["managejustification"]["justification"],
      flex: 1,
      renderCell: ({row}) => row.justification.typedescription,
    },
    {
      field: "status",
      headerName: dictionary["managejustification"]["justificationstatus"],
      flex: 1,
      renderCell: ({row}) => dictionary["requestStatus"][row.justification.status],
    },
    {
      field: "notes",
      headerName: dictionary["managejustification"]["notes"],
      flex: 1,
      cellClassName: "super-app-theme--header",
      renderCell: (params) => params.row.justification.notes,
    },
    {
      field: "actions",
      headerName: dictionary["actions"],
      flex: 1,
      renderCell: ({row}) => (
        <Stack direction="row">
          {row.justification.status !== "JUSTIFIED" || row.status !== "PENDING" ? (
            <Stack direction="row" justifyItems="center" justifyContent={"center"} ml={4}>
              <Typography variant="text">
                <Badge badgeContent={`${dictionary["requestStatus"][row.status]}`} color="success" />
              </Typography>
            </Stack>
          ) : (
            <Stack direction="row">
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  Approved(row);
                }}
                aria-label="delete-request"
                color="info"
                size="small"
                disabled={row.justification.status !== "JUSTIFIED"}>
                <Done fontSize="inherit" />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  NotApproved(row);
                }}
                disabled={row.justification.status !== "JUSTIFIED"}
                aria-label="delete-request"
                color="error"
                size="small">
                <Block fontSize="inherit" />
              </IconButton>
            </Stack>
          )}
        </Stack>
      ),
    },
  ];

  const columnsMobile = [
    {
      field: "userfirstname",
      headerName: dictionary["user"],
      flex: 1,
      renderCell: (params) => params.row.userlastname,
    },
    {
      field: "type",
      headerName: dictionary["managejustification"]["relevance"],
      flex: 2,
      renderCell: (params) => {
        let filter = types.find((res) => res.id === params.row.anomaly.typeid);
        let period = params.row.anomaly.periods.replaceAll("||", " - ").replaceAll("|", "");
        let periodSplit = period.split(" - ");
        return (
          <>
            <Stack spacing={0.5}>
              <Typography variant="body2">{filter && dictionary["types"][filter.description]}</Typography>
              <Stack spacing={0.5} mt={1}>
                {periodSplit.map((p, idx) => {
                  const [date, time] = p.includes("T") ? p.split("T") : p.split(" ");
                  return (
                    <Typography variant="body3" key={idx}>
                      {moment(date).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(time)}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </>
        );
      },
    },
    {
      field: "status",
      headerName: dictionary["managejustification"]["justificationstatus"],
      flex: 1,
      renderCell: (params) => dictionary["requestStatus"][params.row.justification.status],
    },
    {
      field: "actions",
      headerName: dictionary["actions"],
      flex: 1,
      renderCell: ({row}) => (
        <Stack direction="row">
          {row.justification.status !== "JUSTIFIED" || row.status !== "PENDING" ? (
            <Stack direction="row" justifyItems="center" justifyContent={"center"} ml={4}>
              <Typography variant="text">
                <Badge badgeContent={`${dictionary["requestStatus"][row.justification.status]}`} color="success" />
              </Typography>
            </Stack>
          ) : (
            <Stack direction="row">
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  Approved(row);
                }}
                aria-label="delete-request"
                color="info"
                size="small"
                disabled={row.justification.status !== "PENDING"}>
                <Done fontSize="inherit" />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  NotApproved(row);
                }}
                disabled={row.justification.status !== "PENDING"}
                aria-label="delete-request"
                color="error"
                size="small">
                <Block fontSize="inherit" />
              </IconButton>
            </Stack>
          )}
        </Stack>
      ),
    },
  ];

  columns = isMobile ? columnsMobile : columnsDesk;

  return (
    <DataGrid
      disableSelectionOnClick
      autoHeight
      rowHeight={mini ? 60 : 120}
      // getRowHeight={({id, densityFactor, model}) => {
      //   if (model.clockings.length > 0) {
      //     if (id === model.id) {
      //       return 100 * densityFactor;
      //     }
      //   }
      //   return null;
      // }}
      density={mini ? "compact" : "standard"}
      rows={searchedJustifications}
      columns={mini ? columnsDash : columns}
      pagination
      hideFooter={mini}
      onPageSizeChange={setPageSize}
      pageSize={mini ? 100 : pageSize}
      rowsPerPageOptions={ROWS_PER_PAGE}
    />
  );
};

export default ManageJustificationsTables;
