import React, {useState} from "react";
import {
  Card,
  CardContent,
} from "@mui/material";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Legend,
  Title,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { scaleBand } from '@devexpress/dx-chart-core';
import {useDictionary} from "../hooks";
import {Stack as GridStats, HoverState, EventTracker, ArgumentScale, ValueScale} from "@devexpress/dx-react-chart";
import { formattedTimeStats } from "../utilities";

const compareTargets = ({series, point}, {series: targetSeries, point: targetPoint}) =>
  series === targetSeries && point === targetPoint;

const StatisticsTables = ({statistics}) => {
  const dictionary = useDictionary();
  const [tooltipTarget, setTooltipTarget] = useState(null);
  const [tooltipEnabled, setTooltipEnabled] = useState(true);
  const [selection, setSelection] = useState({series: "", point: 0});
  const [hover, setHover] = useState(true);

  const changeTooltip = (targetItem) => setTooltipTarget(targetItem);
  const changeHover = (hover) => setHover(hover);

  const tooltipContentTitleStyle = {
    fontWeight: "bold",
    paddingBottom: 0,
  };
  const tooltipContentBodyStyle = {
    paddingTop: 0,
  };

  const click = ({targets}) => {
    const target = targets[0];
    if (target) {
      setSelection((selection) => (selection && compareTargets(selection, target) ? {series: "", point: 0} : target));
    }
  };

  const TooltipContent = (props) => {
    const {targetItem, text, ...restProps} = props;
    let number = parseInt(text);
    let stats = {...statistics};
    stats = statistics.find((statsOne) => statsOne.minutesOrd === number);
    return (
      <div>
        <div>
          <Tooltip.Content {...restProps} style={tooltipContentTitleStyle} text={targetItem.series} />
        </div>
        <div>
          <Tooltip.Content
            {...restProps}
            style={tooltipContentBodyStyle}
            text={stats?.costcenter == undefined ? "" : stats.costcenter}
          />
        </div>
        <div>
          <Tooltip.Content {...restProps} style={tooltipContentBodyStyle} text={text + " " + dictionary["hours"]} />
        </div>
      </div>
    );
  };

  const sortedStatistics = [...statistics].sort((a, b) => {
    // Ordina in base al campo 'costcenter'
    if (a.costcenter < b.costcenter) return -1;
    if (a.costcenter > b.costcenter) return 1;
    return 0;
  });

  const manipulateData = (data) => {
    return data.map(item => {
      let minutes= formattedTimeStats(item.minutesAct).split(":")
      let minutesAct = parseFloat(formattedTimeStats(item.minutesAct))+parseFloat(minutes[1])/100
      return {
        ...item,
        minutesAct, 
      };
    });
  };

  const manipulatedStatistics = manipulateData(sortedStatistics);

  return (
    <Card elevation={5} sx={{height:"100%"}}>
        <Chart data={manipulatedStatistics} rotated height={statistics.length > 6 ? statistics.length*80 : 600}>
          <ArgumentScale factory={scaleBand} />
          <ArgumentAxis />
          <ValueScale name="hours" />
          <ValueAxis scaleName="hours" showGrid={true} />
          <BarSeries
            name={dictionary["types"]["ORDINARY HOURS"]}
            valueField="minutesOrd"
            argumentField="costcenter"
            scaleName="hours"
          />
          <BarSeries
            name={dictionary["types"]["OVERTIME"]}
            valueField="minutesStr"
            argumentField="costcenter"
            scaleName="hours"
          />
          <BarSeries
            name={dictionary["routes"]["dailyActivities"]}
            valueField="minutesAct"
            argumentField="costcenter"
            scaleName="hours"
          />
          <Title text={dictionary["statistics"]["title"]} />
          <Legend position={"bottom"} />
          <GridStats />
          <EventTracker onClick={click} />
          <HoverState hover={hover} onHoverChange={changeHover} />
          <Tooltip
            targetItem={tooltipEnabled && tooltipTarget}
            onTargetItemChange={changeTooltip}
            contentComponent={TooltipContent}
          />
        </Chart>
      
    </Card>
  );
};

export default StatisticsTables;
