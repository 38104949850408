import React from 'react'
import RequestsCategory from './RequestsCategory';

const RequestsGeneralPanel = ({
  requests = [],
  columns = []
}) => {
  const categories = {};

  requests.forEach(request => {
    if (!categories[request.status]) {
      categories[request.status] = [];
    }
    categories[request.status].push(request);
  });

  return (<>
    {Object.entries(categories).map(([category, rows]) => (
      <RequestsCategory
        key={category}
        columns={columns}
        category={category}
        rows={rows}
      />
    ))}
  </>
  )
}

export default RequestsGeneralPanel;