import React from 'react';
import { Paper } from '@mui/material';

const IconBox = ({ Icon }) => {
    return (
        <Paper elevation={3} sx={{
            borderRadius: '3px',
            backgroundColor: (theme) => theme.palette.primary.main,
            padding: '20px',
            marginTop: '-25px',
            marginLeft: '15px',
            position: 'absolute',
            color: 'white'
        }}>
            <Icon sx={{ fontSize: '1.8rem' }} />
        </Paper>
    );
};

export default IconBox;