import {Badge, Stack, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import React from "react";
import {ROWS_PER_PAGE} from "../hooks/usePagination";
import {useDictionary, useJustificationsTypes, usePagination, useUser, useMediaQuery} from "../hooks";
import moment from "moment";
import AnomaliesModal from "./AnomaliesModal";
import {renderHeader, getCompiledHours, uuidv4, deleteMillisFromTime} from "../utilities";

const AnomaliesTables = ({mini = false, onlyView = false, searchedAnomalies, types = [], searchForm}) => {
  const [pageSize, setPageSize] = usePagination();
  const justificationTypes = useJustificationsTypes();
  const {user} = useUser();
  const dictionary = useDictionary();
  const [isMobile] = useMediaQuery("down", "md");

  const columnsDash = [
    {
      field: "minutescount",
      headerName: dictionary["hours"],
      renderHeader: renderHeader(dictionary["hours"]),
      renderCell: (params) => {
        return (
          <Stack justifyItems="center" justifyContent={"center"} ml={4}>
            {/* <Badge
              badgeContent={` ${params.value / 60} ${dictionary["hours"]}`}
              color="secondary"
            /> */}
            {params.value > 0 ? (
              <Badge badgeContent={` ${params.value / 60} ${dictionary["hours"]}`} color="secondary" />
            ) : (
              "-"
            )}
          </Stack>
        );
      },
    },
    {
      field: "datetimefrom",
      headerName: dictionary["date"],
      renderHeader: renderHeader(dictionary["date"]),
      flex: 2,
      renderCell: ({value}) => moment(value).format("DD/MM/YYYY"),
    },
  ];

  let columns = [];

  const columnsDesk = [
    {
      field: "typeid",
      headerName: dictionary["anomalies"]["type"],
      renderHeader: renderHeader(dictionary["anomalies"]["type"]),
      flex: 1,
      renderCell: (params) => {
        let filter = types.find((res) => res.id === params.value);
        return <Typography>{filter && dictionary["types"][filter.description]}</Typography>;
      },
    },
    {
      field: "minutescount",
      headerName: dictionary["hours"],
      renderHeader: renderHeader(dictionary["hours"]),
      renderCell: (params) => {
        return (
          <Stack justifyItems="center" justifyContent={"center"} ml={4}>
            {params.value > 0 ? (
              <Badge badgeContent={` ${params.value / 60} ${dictionary["hours"]}`} color="secondary" />
            ) : (
              "-"
            )}
          </Stack>
        );
      },
    },
    {
      field: "minutestojustify",
      headerName: dictionary["anomalies"]["periodToJustify"],
      renderHeader: renderHeader(dictionary["anomalies"]["periodToJustify"]),
      flex: 1,
      renderCell: ({row}) => {
        // FIX TEMPORANEO, CAPIRE CRITERIO PER CUI è ORARIO DISCORDANTE
        if (row.typeid === 6) {
          const [declared, real] = row.periods.replaceAll("|", "").split(" - ");
          let splitBy = " ";
          if (declared.includes("T")) {
            splitBy = "T";
          }
          const [declareddate, declaredtime] = declared.split(splitBy);
          const [realdate, realtime] = real.split(splitBy);
          return (
            <Stack spacing={0.5}>
              <Stack direction="row" spacing={0.5} mt={1}>
                <Typography sx={{fontWeight: "bold"}} variant="body2">
                  {dictionary["declared"]}:{" "}
                </Typography>
                <Typography variant="body2">
                  {moment(declareddate).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(declaredtime)}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography sx={{fontWeight: "bold"}} variant="body2">
                  {dictionary["executed"]}:{" "}
                </Typography>
                <Typography variant="body2">
                  {moment(realdate).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(realtime)}
                </Typography>
              </Stack>
            </Stack>
          );
        }
        const [datefrom, timefrom] = row.datetimefrom.split("T");
        const [dateto, timeto] = row.datetimeto.split("T");
        return (
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={0.5} mt={1}>
              <Typography sx={{fontWeight: "bold"}} variant="body2">
                {dictionary["from"]}:{" "}
              </Typography>
              <Typography variant="body2">
                {moment(datefrom).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(timefrom)}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Typography sx={{fontWeight: "bold"}} variant="body2">
                {dictionary["to"]}:{" "}
              </Typography>
              <Typography variant="body2">
                {moment(dateto).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(timeto)}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "minutesjustified",
      headerName: dictionary["anomalies"]["compiledHours"],
      renderHeader: renderHeader(dictionary["anomalies"]["compiledHours"]),
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack justifyItems="center" justifyContent={"center"} ml={4}>
            {params.row.minutescount > 0 ? (
              <Badge badgeContent={getCompiledHours(params.row.justifications)} color="secondary" />
            ) : (
              "-"
            )}
          </Stack>
        );
      },
    },
    {
      field: "",
      headerName: dictionary["actions"],
      renderHeader: renderHeader(dictionary["actions"]),
      renderCell: ({row}) => (
        <AnomaliesModal
          anomaly={{
            ...row,
            justifications: row.justifications.map((j) => ({...j, _uid: uuidv4()})),
          }}
          user={user}
          searchForm={searchForm}
          justificationTypes={justificationTypes}
        />
      ),
    },
  ];

  const columnsMobile = [
    {
      field: "minutestojustify",
      headerName: dictionary["anomalies"]["periodToJustify"],
      renderHeader: renderHeader(dictionary["anomalies"]["periodToJustify"]),
      flex: 2,
      renderCell: (params) => {
        if (params.row.typeid === 6) {
          const [declared, real] = params.row.periods.replaceAll("|", "").split(" - ");
          const [declareddate, declaredtime] = declared.split("  ");
          const [realdate, realtime] = real.split(" ");
          return (
            <Stack spacing={0.5}>
              <Stack direction="row" spacing={0.5} mt={1}>
                <Typography sx={{fontWeight: "bold"}} variant="body2">
                  {dictionary["declared"]}:{" "}
                </Typography>
                <Typography variant="body2">
                  {moment(declareddate).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(declaredtime)}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography sx={{fontWeight: "bold"}} variant="body2">
                  {dictionary["executed"]}:{" "}
                </Typography>
                <Typography variant="body2">
                  {moment(realdate).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(realtime)}
                </Typography>
              </Stack>
            </Stack>
          );
        }
        const [datefrom, timefrom] = params.row.datetimefrom.split("T");
        const [dateto, timeto] = params.row.datetimeto.split("T");
        return (
          <Stack spacing={0.5}>
            <Stack direction="row" mt={1}>
              <Typography variant="body2">
                {moment(datefrom).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(timefrom)}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Typography variant="body2">
                {moment(dateto).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(timeto)}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "minutesjustified",
      headerName: dictionary["reports"]["hours"],
      renderHeader: renderHeader(dictionary["reports"]["hours"]),
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack justifyItems="center" justifyContent={"center"} ml={4}>
            {params.row.minutescount > 0 ? (
              <Badge badgeContent={getCompiledHours(params.row.justifications)} color="secondary" />
            ) : (
              "-"
            )}
          </Stack>
        );
      },
    },
    {
      field: "",
      headerName: dictionary["actions"],
      renderHeader: renderHeader(dictionary["actions"]),
      renderCell: ({row}) => (
        <AnomaliesModal
          anomaly={{
            ...row,
            justifications: row.justifications.map((j) => ({...j, _uid: uuidv4()})),
          }}
          user={user}
          searchForm={searchForm}
          justificationTypes={justificationTypes}
        />
      ),
    },
  ];

  let columnsOnlyView = [];

  const columnsOnlyViewDesk = [
    {
      field: "typeid",
      headerName: dictionary["anomalies"]["type"],
      renderHeader: renderHeader(dictionary["anomalies"]["type"]),
      flex: 1,
      renderCell: (params) => {
        let filter = types.find((res) => res.id === params.value);
        return <Typography>{filter && dictionary["types"][filter.description]}</Typography>;
      },
    },
    {
      field: "minutescount",
      headerName: dictionary["hours"],
      renderHeader: renderHeader(dictionary["hours"]),
      renderCell: (params) => {
        return (
          <Stack justifyItems="center" justifyContent={"center"} ml={4}>
            <Badge badgeContent={` ${params.value / 60} ${dictionary["hours"]}`} color="secondary" />
          </Stack>
        );
      },
    },
    {
      field: "minutestojustify",
      headerName: dictionary["anomalies"]["periodToJustify"],
      renderHeader: renderHeader(dictionary["anomalies"]["periodToJustify"]),
      flex: 1,
      renderCell: (params) => {
        const [datefrom, timefrom] = params.row.datetimefrom.split("T");
        const [dateto, timeto] = params.row.datetimeto.split("T");
        return (
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={0.5} mt={1}>
              <Typography sx={{fontWeight: "bold"}} variant="body2">
                {dictionary["from"]}:{" "}
              </Typography>
              <Typography variant="body2">
                {moment(datefrom).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(timefrom)}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Typography sx={{fontWeight: "bold"}} variant="body2">
                {dictionary["to"]}:{" "}
              </Typography>
              <Typography variant="body2">
                {moment(dateto).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(timeto)}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
  ];

  const columnsOnlyViewMobile = [
    {
      field: "typeid",
      headerName: dictionary["anomalies"]["type"],
      renderHeader: renderHeader(dictionary["anomalies"]["type"]),
      flex: 1,
      renderCell: (params) => {
        let filter = types.find((res) => res.id === params.value);
        return <Typography>{filter && dictionary["types"][filter.description]}</Typography>;
      },
    },
    {
      field: "minutescount",
      headerName: dictionary["hours"],
      renderHeader: renderHeader(dictionary["hours"]),
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack justifyItems="center" justifyContent={"center"} ml={4}>
            <Badge badgeContent={` ${params.value / 60} ${dictionary["hours"]}`} color="secondary" />
          </Stack>
        );
      },
    },
    {
      field: "minutestojustify",
      headerName: dictionary["anomalies"]["periodToJustify"],
      renderHeader: renderHeader(dictionary["anomalies"]["periodToJustify"]),
      flex: 2,
      renderCell: (params) => {
        if (params.row.typeid === 6) {
          const [declared, real] = params.row.periods.replaceAll("|", "").split(" - ");
          const [declareddate, declaredtime] = declared.split("  ");
          const [realdate, realtime] = real.split(" ");
          return (
            <Stack spacing={0.5}>
              <Stack direction="row" spacing={0.5} mt={1}>
                <Typography sx={{fontWeight: "bold"}} variant="body2">
                  {dictionary["declared"]}:{" "}
                </Typography>
                <Typography variant="body2">
                  {moment(declareddate).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(declaredtime)}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography sx={{fontWeight: "bold"}} variant="body2">
                  {dictionary["executed"]}:{" "}
                </Typography>
                <Typography variant="body2">
                  {moment(realdate).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(realtime)}
                </Typography>
              </Stack>
            </Stack>
          );
        }
        const [datefrom, timefrom] = params.row.datetimefrom.split("T");
        const [dateto, timeto] = params.row.datetimeto.split("T");
        return (
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={0.5} mt={1}>
              <Typography variant="body2">
                {moment(datefrom).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(timefrom)}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Typography variant="body2">
                {moment(dateto).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(timeto)}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
  ];

  isMobile ? (columns = columnsMobile) : (columns = columnsDesk);

  isMobile ? (columnsOnlyView = columnsOnlyViewMobile) : (columnsOnlyView = columnsOnlyViewDesk);

  return (
    <DataGrid
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
      rows={searchedAnomalies}
      columns={mini ? columnsDash : onlyView ? columnsOnlyView : columns}
      density={mini ? "compact" : "standard"}
      pagination
      hideFooter={mini || onlyView}
      onPageSizeChange={setPageSize}
      pageSize={mini || onlyView ? 100 : pageSize}
      rowsPerPageOptions={ROWS_PER_PAGE}
    />
  );
};

export default AnomaliesTables;
