import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { useDictionary, useForm, useUser } from "../hooks";
import CardHeader from "@mui/material/CardHeader";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from "@mui/system";
import { DoneAll, Lock, Refresh, Upload } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { AuthContext } from "../contexts";
import { isRequired } from "../hooks/useForm";

const Profile = () => {
  const {user, image, setUser, getUserImage} = useUser();

  useEffect(() => {
    if (user.id && !image){
      getUserImage('current')
    }
  }, [user, image])

  const dictionary = useDictionary();

  const Input = styled("input")({
    display: "none",
  });

  const validations = [
    ({ firstname }) =>
      isRequired(firstname) || {
        firstname:  dictionary["profilemode"]["insertemail"],
      },
    ({ lastname }) =>
      isRequired(lastname) || {
        lastname:  dictionary["profilemode"]["insertemail"],
      },
    ({ dob }) =>
      isRequired(dob) || {
        dob:  dictionary["profilemode"]["insertemail"],
      },
    ({ emailaddress }) =>
      isRequired(emailaddress) || {
        emailaddress: dictionary["profilemode"]["insertemail"],
      },];

  const {
    values: userForm,
    changeHandler,
    touched,
    errors,
    isValid,
    setValues,
    reset,
  } = useForm(user, validations);

  const { logout, setImage, resetPin, updateInfoUser } = React.useContext(AuthContext);

  const uploadImage = (id, file) => {
    const formData = new FormData();
    formData.append("file", file);
    setImage(id, formData)
  }
   
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6}>
        <Card elevation={3}>
          <CardHeader
            sx={{
              color: (theme) => theme.palette.common.white,
              backgroundColor: (theme) => theme.palette.primary.light,
            }}
            title={dictionary["profilemode"]["title"]}
            component="div"
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="text"
                  autoComplete="text"
                  type="text"
                  label={dictionary["profilemode"]["name"]}
                  variant="outlined"
                  disabled={true}
                  error={Boolean(touched.firstname && errors.firstname)}
                  helperText={touched.firstname && errors.firstname}
                  value={userForm.firstname}
                  onChange={(e) => changeHandler("firstname", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="text"
                  autoComplete="text"
                  type="text"
                  label={dictionary["profilemode"]["lastname"]}
                  variant="outlined"
                  disabled={true}
                  error={Boolean(touched.lastname && errors.lastname)}
                  helperText={touched.lastname && errors.lastname}
                  value={userForm.lastname}
                  onChange={(e) => changeHandler("lastname", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <DatePicker
                  label={"data"}
                  inputFormat="DD/MM/yyyy"
                  value={userForm.dob || ""}
                  disabled={true}
                  onChange={(newValue) =>
                    changeHandler("dob", newValue?.format("yyyy-MM-DD"))
                  }
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      fullWidth
                      {...params}
                      sx={{ textTransform: "capitalize" }}
                      error={Boolean(touched.dob && errors.dob)}
                      helperText={touched.dob && errors.dob}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="text"
                  autoComplete="text"
                  type="text"
                  label={dictionary["profilemode"]["email"]}
                  variant="outlined"
                  error={Boolean(touched.emailaddress && errors.emailaddress)}
                  helperText={touched.emailaddress && errors.emailaddress}
                  value={userForm.emailaddress}
                  onChange={(e) =>
                    changeHandler("emailaddress", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="text"
                  autoComplete="text"
                  type="text"
                  label={dictionary["profilemode"]["phone1"]}
                  variant="outlined"
                  value={userForm.phone1}
                  onChange={(e) => changeHandler("phone1", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="text"
                  autoComplete="text"
                  type="text"
                  label={dictionary["profilemode"]["phone2"]}
                  variant="outlined"
                  value={userForm.phone2}
                  onChange={(e) => changeHandler("phone2", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="text"
                  autoComplete="text"
                  type="text"
                  label={dictionary["profilemode"]["privatephone1"]}
                  variant="outlined"
                  value={userForm.privatephone1}
                  onChange={(e) =>
                    changeHandler("privatephone1", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  size="small"
                  fullWidth
                  id="text"
                  autoComplete="text"
                  type="text"
                  label={dictionary["profilemode"]["privatephone2"]}
                  variant="outlined"
                  value={userForm.privatephone2}
                  onChange={(e) =>
                    changeHandler("privatephone2", e.target.value)
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Stack
                direction="row"
                justifyContent={"flex-end"}
                spacing={2}
                mt={2}
              >
                <Button
                  color="info"
                  variant="contained"
                  startIcon={<Lock />}
                  size="small"
                  onClick={() => resetPin(logout, dictionary)}
                >
                  Reset Pin Code
                </Button>

                <Button
                  color="success"
                  variant="contained"
                  disabled={!isValid}
                  size="small"
                  startIcon={<DoneAll />}
                  onClick={() => updateInfoUser(userForm, dictionary, setValues)}
                >
                  {dictionary["confirm"]}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  startIcon={<Refresh />}
                  onClick={() => reset()}
                  
                >
                  {dictionary["cancel"]}
                </Button>
              </Stack>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Card elevation={3}>
          <Box sx={{ position: "relative" }}>
            <label htmlFor="icon-button-file">
              <Input
                accept="image/*"
                id="icon-button-file"
                type="file"
                onChange={(e) => uploadImage('current', e.target.files[0])}
              />
              <IconButton
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  zIndex: 2,
                  backgroundColor: "rgba(0,0,0,0.3)",
                  color: (theme) => theme.palette.common.white,
                }}
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <Upload />
              </IconButton>
            </label>
            {image ? <Avatar
              sx={{ height: "300px", width: "100%", borderRadius: "unset" }}
              src={image}
              alt={user.emailAddress}
            /> : null}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Profile;
