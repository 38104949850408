import React from 'react'
import { Typography, Stack, Grid } from '@mui/material'
import moment from 'moment';
import { useDictionary } from '../hooks';

const BusinesstripDetailModal = ({ businesstrip }) => {
  const dictionary = useDictionary();

  return (
    <Stack
      direction="row"
      spacing={2}
    >
      <Grid container>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>
              {dictionary["reports"]["dateFrom"]}
            </span>
            {' >> '}
            {moment(businesstrip.datefrom).format('ddd:')}&nbsp;
            {moment(businesstrip.datefrom).format('DD/MM/YYYY')}
          </Typography>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>
              {dictionary["reports"]["dateTo"]}
            </span>
            {' >> '}
            {moment(businesstrip.dateto).format('ddd:')}&nbsp;
            {moment(businesstrip.dateto).format('DD/MM/YYYY')}
          </Typography>
          <Typography variant="body1">
            <span style={{ fontWeight: "bold" }}>
              {dictionary["businesstrips"]["days"]} :
            </span>
            {businesstrip.days}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="body1">
          <span style={{ fontWeight: "bold" }}>Note Richiedente:</span>
          </Typography>
          {businesstrip.notes}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <hr />
        </Grid>
        {businesstrip.outcomes.map((b) =>
          b.notes ? (
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1">
                <span style={{fontWeight: "bold"}}>Note Approvatore:</span>
              </Typography>
              {b.notes}
            </Grid>
          ) : null
        )}
      </Grid>
    </Stack>
  )
}

export default BusinesstripDetailModal