import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { Grid, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import moment from 'moment';
import { useForm } from '../hooks';

const ClockingPeriodForm = ({ dictionary, dateERef, timeERef, dateURef, timeURef }) => {
    const validations = [];

    const DATE_FORMAT = "YYYY-MM-DD";
    const TIME_FORMAT = "HH:mm";

    const {
        values: clockingForm,
        changeHandler,
        errors,
        touched,
        isValid
    } = useForm({
        dateE: new Date(),
        timeE: new Date(),
        dateU: new Date(),
        timeU: new Date(),
    }, validations);

    useEffect(() => {
        const now = new Date();
        dateERef.current = moment(now).format(DATE_FORMAT);
        timeERef.current = moment(now);
        dateURef.current = moment(now).format(DATE_FORMAT);
        timeURef.current = moment(now);
    }, []);

    return (
        <Grid container spacing={2} sx={{ pt: theme => theme.spacing(1) }}>
            <Grid item xs={12} md={12} lg={6}>
                <DatePicker
                    inputFormat='DD/MM/YYYY'
                    label={dictionary["calendar"]["dateE"]}
                    value={clockingForm.dateE ||""}
                    onChange={newValue => {
                        changeHandler("dateE", newValue?.format(DATE_FORMAT));
                        dateERef.current = newValue?.format(DATE_FORMAT);
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
                <TimePicker
                    ampm={false}
                    label={dictionary["calendar"]["timeE"]}
                    value={clockingForm.timeE || ""}
                    onChange={newValue => {
                        changeHandler("timeE", newValue);
                        timeERef.current = newValue;
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
                <DatePicker
                    inputFormat='DD/MM/YYYY'
                    label={dictionary["calendar"]["dateU"]}
                    value={clockingForm.dateU || ""}
                    onChange={newValue => {
                        changeHandler("dateU", newValue?.format(DATE_FORMAT));
                        dateURef.current = newValue?.format(DATE_FORMAT);
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
                <TimePicker
                    ampm={false}
                    label={dictionary["calendar"]["timeU"]}
                    value={clockingForm.timeU || ""}
                    onChange={newValue => {
                        changeHandler("timeU", newValue);
                        timeURef.current = newValue;
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                />
            </Grid>
        </Grid>
    )
}

export default ClockingPeriodForm