import createDataContext from "./createContext";
import BaseRequest from "../api/Base";

const SET_JUSTIFICATIONS_TYPES = "set_justifications_type";

const INITIAL_STATE = {
  justification: {},
  justificationsTypes: [],
};

const JustificationsTypesReducer = (state, action) => {
  switch (action.type) {
    case SET_JUSTIFICATIONS_TYPES: {
      return {
        ...state,
        justificationsTypes: [...action.payload],
        justification: {},
      };
    }
    default: {
      return state;
    }
  }
};

const setJustificationsType = (dispatch) => async () => {
  const response = await BaseRequest.get(`justifications/type/country/current`);
  dispatch({ type: SET_JUSTIFICATIONS_TYPES, payload: response.data.justificationstypes });
};


export const { Provider, Context } = createDataContext(
    JustificationsTypesReducer,
  {
    setJustificationsType,
  }, // actions
  INITIAL_STATE // initial state
);
