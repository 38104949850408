import { Badge, Box, Divider, Typography } from '@mui/material'
import React from 'react'

const HeaderCollapse = ({ notificationCount, Icon, title, color, textColor, actions }) => {

  return (
    <Box sx={{
      display: "flex",
      backgroundColor: theme => theme.palette[color] ? theme.palette[color].main : (color || theme.palette.primary.main),
      color: textColor || (theme => theme.palette.common.white),
      py: theme => theme.spacing(1),
      px: theme => theme.spacing(1)
    }}>
      {notificationCount > 0
        ? <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          overlap="circular"
          badgeContent={notificationCount}
          color="error"
        >
          <Icon fontSize={"large"} />
        </Badge>
        : <Icon fontSize={"large"} />
      }
      <Divider flexItem sx={{ mx: theme => theme.spacing(1) }} orientation="vertical" />
      <Typography variant="h6" sx={{
        flex: 1,
        alignSelf: "flex-end"
      }}>
        {title}
      </Typography>
      {actions}
    </Box>
  )
}

export default HeaderCollapse