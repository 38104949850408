import React, { useContext, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IconBox, StatisticsTables } from "../components";
import { Refresh, Search } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { StatisticsContext } from "../contexts";
import { useCostCenters, useDictionary, useForm, useMediaQuery } from "../hooks";
import { isDateGreater } from "../hooks/useForm";
import { DataGrid } from "@mui/x-data-grid";
import { formattedTimeStats } from "../utilities";

const Statistics = () => {
  const dictionary = useDictionary();
  const [isMobile] = useMediaQuery("down", "md");

  let columns = [];
  const columnsDesk = [
    {
      field: "costcenter",
      headerName: "Centro di Costo",
      flex: 1,
    },
    {
      field: "minutesOrd",
      headerName: dictionary["statistics"]["ordinarywork"],
      align: "right",
      headerAlign: "right",
      minWidth: 180,
      renderCell: (params) => {
        let minutesOrd =
          params.row.minutesOrd == undefined ? 0 : params.row.minutesOrd;
        return (
          <Typography>{formattedTimeStats(minutesOrd)}</Typography>
        );
      },
    },
    {
      field: "minutesStr",
      headerName: dictionary["statistics"]["overtime"],
      align: "right",
      minWidth: 110,
      headerAlign: "right",
      renderCell: (params) => {
        let minutesStr =
          params.row.minutesStr == undefined ? 0 : params.row.minutesStr;
        return (
          <Typography>{formattedTimeStats(minutesStr)}</Typography>
        );
      },
    },
    {
      field: "minutesAct",
      headerName: dictionary["routes"]["dailyActivities"],
      align: "right",
      minWidth: 110,
      headerAlign: "right",
      renderCell: (params) => {
        let minutesAct =
          params.row.minutesAct == undefined ? 0 : params.row.minutesAct;
        return (
          <Typography>{formattedTimeStats(minutesAct)}</Typography>
        );
      },
    },
    {
      field: "",
      headerName: dictionary["statistics"]["total"],
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {
        let minutesOrd =
          params.row.minutesOrd == undefined ? 0 : params.row.minutesOrd;
        let minutesStr =
          params.row.minutesStr == undefined ? 0 : params.row.minutesStr;
        let minutesAct =
          params.row.minutesAct == undefined ? 0 : params.row.minutesAct;
        let total =
          parseFloat(minutesOrd) +
          parseFloat(minutesStr) +
          parseFloat(minutesAct);
        return (
          <Typography fontWeight={"bold"}>
            {formattedTimeStats(total)}
          </Typography>
        );
      },
    },
  ];

  const columnsMobile = [
    {
      field: "costcenter",
      headerName: "Centro di costo",
      flex: 1,
    },
    {
      field: "",
      headerName: dictionary["statistics"]["total"],
      flex: 1,
      headerAlign: "right",
      renderCell: (params) => {
        let minutesOrd =
          params.row.minutesOrd == undefined ? 0 : params.row.minutesOrd;
        let minutesStr =
          params.row.minutesStr == undefined ? 0 : params.row.minutesStr;
        let minutesAct =
          params.row.minutesAct == undefined ? 0 : params.row.minutesAct;
        let total =
          parseFloat(minutesOrd) +
          parseFloat(minutesStr) +
          parseFloat(minutesAct);
        
        return <Typography fontWeight={"bold"}>{formattedTimeStats(total)}</Typography>;
      },
    },
  ];

  isMobile ? (columns = columnsMobile) : (columns = columnsDesk);

  const {
    state: { statistics },
    setStatistics,
    refreshStats,
  } = useContext(StatisticsContext);

  useEffect(() => {
    return () => {
      refreshStats();
    };
  }, []);

  const validations = [
    ({ datetimefrom, datetimeto }) =>
      isDateGreater(datetimefrom, datetimeto) || {
        datetimefrom: "Data Errata",
      },
  ];
  const costcenters = useCostCenters();

  const {
    values: stats,
    changeHandler,
    setValues,
    errors,
    touched,
    isValid,
  } = useForm({}, validations);

  return (
    <Stack spacing={2}>
      <Card elevation={5}>
        <IconBox Icon={Search} />
        <CardHeader
          title={dictionary["statistics"]["personaltitle"]}
          sx={{ pl: (theme) => theme.spacing(12) }}
        />
        <CardContent component="form">
          <Grid container spacing={2} mb={1}>
            <Grid item xs={12} md={3} lg={3}>
              <DatePicker
                label={dictionary["datefrom"]}
                inputFormat="DD/MM/yyyy"
                value={stats.datetimefrom || ""}
                onChange={(newValue) =>
                  changeHandler("datetimefrom", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.datetimefrom && errors.datetimefrom)}
                    helperText={touched.datetimefrom && errors.datetimefrom}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <DatePicker
                label={dictionary["dateto"]}
                inputFormat="DD/MM/yyyy"
                value={stats.datetimeto || ""}
                onChange={(newValue) =>
                  changeHandler("datetimeto", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.datetimeto && errors.datetimeto)}
                    helperText={touched.datetimeto && errors.datetimeto}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="status-select">{"Centro di costo"}</InputLabel>
                <Select
                  id="status-select"
                  value={stats.costcenterid || "ALL"}
                  label={"Centro di costo"}
                  onChange={(e) =>
                    changeHandler("costcenterid", e.target.value)
                  }
                >
                  <MenuItem value={"ALL"}>Tutti</MenuItem>
                  {costcenters.map((costcenter, i) => (
                    <MenuItem key={i} value={costcenter.id}>
                      {costcenter.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
              <Stack direction="row" justifyContent={"flex-end"} spacing={2}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    height: { md: 40, lg: 40 },
                  }}
                  startIcon={<Search />}
                  type="submit"
                  disabled={!isValid}
                  onClick={(e) => {
                    e.preventDefault();
                    setStatistics(stats);
                  }}
                >
                  {dictionary["search"]}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    height: { md: 40, lg: 40 },
                  }}
                  startIcon={<Refresh />}
                  type="submit"
                  onClick={(e) => {}}
                >
                  {dictionary["reset"]}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card elevation={5}>
        <CardContent>
          <DataGrid
            rows={statistics}
            autoHeight
            columns={columns}
            disableColumnMenu
            hideFooter
            pageSize={100}
          />
        </CardContent>
      </Card>
      {isMobile ? (
        <></>
      ) : (
        <StatisticsTables statistics={statistics} dictionary={dictionary} />
      )}
    </Stack>
  );
};

export default Statistics;
