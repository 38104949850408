import { Button, Stack } from "@mui/material";
import {ErrorTitle} from "../components";
import { openModal } from "../hooks/useModals";
import {sendNotification} from "../hooks/useNotification";
import {STORE_JWT, STORE_USER} from "../utilities";
// import endpoints from "./endpoint.json";

const isProduction = process.env.NODE_ENV === "production";

export const BASE_URL = isProduction ? "/api/v1" : "http://localhost:3000/api/v1";

export const getToken = () => (localStorage.getItem(STORE_JWT) ? localStorage.getItem(STORE_JWT) : null);

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

export const setLoading = (loading) => window.dispatchEvent(new CustomEvent("change_loading", {detail: loading}));

export const requestInterceptor = (request) => {
  if (getToken()) {
    request.headers["Authorization"] = getAuthorizationHeader();
  }
};

export const errorInterceptor = (error, serviceName) => {
  console.log('error.response.status: ', error.response.status);
  if ([403, 401].includes(error.response.status)) {
    localStorage.removeItem(STORE_USER);
    localStorage.removeItem(STORE_JWT);
    window.location.assign("/");
  }else if([412].includes(error.response.status)){
    openModal(
      error.response.data.message,
      error.response.data.detailedmessage,
      "error",
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            sx={{ textTransform: "capitalize" }}
            variant="outlined"
            onClick={async () => {
              closeModal();
            }}
          >
            Ok
          </Button>
        </Stack>
      )
    );
  } else{
    sendNotification(<ErrorTitle service={serviceName} />, error.response.data?.detailedmessage || error.response.data?.message, "error", 5);
  }
};
// export const getEndpoint = (endpoint) =>
//   production ? endpoints.production[endpoint] : endpoints.development[endpoint];
