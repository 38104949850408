import React, { useContext, useEffect } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { IconBox, StatisticsTables } from "../components";
import { Assessment, Refresh, Search } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  AuthContext,
  DailyActivitiesContext,
  StatisticsContext,
} from "../contexts";
import {
  useCostCenters,
  useDictionary,
  useForm,
  useMediaQuery,
} from "../hooks";
import { isDateGreater, isRequired } from "../hooks/useForm";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { formattedTimeStats } from "../utilities";

const ManageStatistics = () => {
  const dictionary = useDictionary();
  const [isMobile] = useMediaQuery("down", "md");

  let columns = [];
  const columnsDesk = [
    {
      field: "costcenter",
      headerAlign: "center",
      align: "center",
      headerName: dictionary["statistics"]["employee"],
      flex: 1,
    },
    {
      field: "minutesOrd",
      headerName: dictionary["statistics"]["ordinarywork"],
      align: "right",
      minWidth: 180,
      headerAlign: "right",
      renderCell: (params) => {
        let minutesOrd =
          params.row.minutesOrd == undefined ? 0 : params.row.minutesOrd;
        return (
          <Typography >{formattedTimeStats(minutesOrd)}</Typography>
        );
      },
    },
    {
      field: "minutesStr",
      headerName: dictionary["statistics"]["overtime"],
      flex: 1,
      renderCell: (params) => {
        let minutesStr =
          params.row.minutesStr == undefined ? 0 : params.row.minutesStr;
        return (
          <Typography >{formattedTimeStats(minutesStr)}</Typography>
        );
      },
      headerAlign: "right",
      align: "right",
      minWidth: 110,
    },
    {
      field: "minutesAct",
      headerName: dictionary["routes"]["dailyActivities"],
      flex: 1,
      renderCell: (params) => {
        let minutesAct =
          params.row.minutesAct == undefined ? 0 : params.row.minutesAct;
        return (
          <Typography>{formattedTimeStats(minutesAct)}</Typography>
        );
      },
      headerAlign: "right",
      align: "right",
      minWidth: 110,
    },
    {
      field: "",
      headerName: dictionary["statistics"]["total"],
      flex: 1,
      headerAlign: "right",
      renderCell: (params) => {
        let minutesOrd =
          params.row.minutesOrd == undefined ? 0 : params.row.minutesOrd;
        let minutesStr =
          params.row.minutesStr == undefined ? 0 : params.row.minutesStr;
        let minutesAct =
          params.row.minutesAct == undefined ? 0 : params.row.minutesAct;
        let total =
        parseFloat(minutesOrd) + parseFloat(minutesStr) + parseFloat(minutesAct);
        return <Typography fontWeight={"bold"}>{formattedTimeStats(total)}</Typography>;
      },
      align: "right",
    },
    {
      field: "detail",
      headerName: dictionary["detail"],
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <Button
          endIcon={<Assessment />}
          component={Link}
          to={`analytical-report/${params.row.id}?datefrom=${statsForm.datefrom || "ALL"}&dateto=${
            statsForm.dateto || "ALL"
          }`}
        ></Button>
      ),
    },
  ];

  const columnsMobile = [
    {
      field: "costcenter",
      headerName: dictionary["statistics"]["employee"],
      flex: 1,
    },
    {
      field: "",
      headerName: dictionary["statistics"]["total"],
      flex: 1,
      renderCell: (params) => {
        let minutesOrd =
          params.row.minutesOrd == undefined ? 0 : params.row.minutesOrd;
        let minutesStr =
          params.row.minutesStr == undefined ? 0 : params.row.minutesStr;
        let minutesAct =
          params.row.minutesAct == undefined ? 0 : params.row.minutesAct;
        let total =
          parseFloat(minutesOrd) + parseFloat(minutesStr) + parseFloat(minutesAct);
        return <Typography fontWeight={"bold"}>{formattedTimeStats(total)}</Typography>;
      },
    },
    {
      field: "detail",
      headerName: dictionary["detail"],
      flex: 1,
      renderCell: (params) => (
        <Button
          endIcon={<Assessment />}
          component={Link}
          to={`analytical-report/${params.row.id}?comid=${params.row.commissionid}&datefrom=${statsForm.datefrom}&dateto=${statsForm.dateto}`}
        ></Button>
      ),
    },
  ];

  isMobile ? (columns = columnsMobile) : (columns = columnsDesk);

  const costcenters = useCostCenters();

  const {
    state: { statistics, manageStatsForm },
    setManageStatistics,
    refreshStats,
  } = useContext(StatisticsContext);

  const {
    state: { user },
  } = useContext(AuthContext);

  const {
    state: { userListForDailyActivities },
    getUserListForSupervisorStats,
  } = React.useContext(DailyActivitiesContext);

  useEffect(() => {
    getUserListForSupervisorStats();
    return () => {
      refreshStats();
    };
  }, []);

  const validations = [
    ({ datefrom }) =>
      isRequired(datefrom) || {
        datefrom: "Data Obbligatoria",
      },
    ({ datefrom, dateto }) =>
      isDateGreater(datefrom, dateto) || {
        datefrom: dictionary["statistics"]["wrongdate"],
      },
  ];

  const {
    values: statsForm,
    changeHandler,
    setValues,
    errors,
    touched,
    isValid,
  } = useForm(manageStatsForm, validations);

  return (
    <Stack spacing={2}>
      <Card elevation={5}>
        <IconBox Icon={Search} />
        <CardHeader
          title={dictionary["statistics"]["title"]}
          sx={{ pl: (theme) => theme.spacing(12) }}
        />
        <CardContent component="form">
          <Grid container spacing={2} mb={1}>
            <Grid item xs={12} md={6} lg={6}>
              <DatePicker
                label={dictionary["datefrom"]}
                inputFormat="DD/MM/yyyy"
                value={statsForm.datefrom || ""}
                onChange={(newValue) =>
                  changeHandler("datefrom", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.datefrom && errors.datefrom)}
                    helperText={touched.datefrom && errors.datefrom}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <DatePicker
                label={dictionary["dateto"]}
                inputFormat="DD/MM/yyyy"
                value={statsForm.dateto || ""}
                onChange={(newValue) =>
                  changeHandler("dateto", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.dateto && errors.dateto)}
                    helperText={touched.dateto && errors.dateto}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                select
                id="filled-password-input"
                label={"Dipendenti"}
                type="text"
                value={statsForm.idcollaborator || ""}
                autoComplete="current-password"
                error={Boolean(touched.idcollaborator && errors.idcollaborator)}
                helperText={touched.idcollaborator && errors.idcollaborator}
                size="small"
                onChange={(e) =>
                  changeHandler("idcollaborator", e.target.value)
                }
              >
                <MenuItem value={"ALL"}>{`Tutti`}</MenuItem>
                {userListForDailyActivities.map((res, idx) => (
                  <MenuItem
                    key={idx}
                    value={res.id}
                  >{`${res.firstname} ${res.lastname}`}</MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="status-select">{"Centro di costo"}</InputLabel>
                <Select
                  id="status-select"
                  value={statsForm.idcostcenter || "ALL"}
                  label={"Centro di costo"}
                  onChange={(e) =>
                    changeHandler("idcostcenter", e.target.value)
                  }
                >
                  <MenuItem value={"ALL"}>Tutti</MenuItem>
                  {costcenters.map((costcenter, i) => (
                    <MenuItem key={i} value={costcenter.id}>
                      {costcenter.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Stack direction="row" justifyContent={"flex-end"} spacing={2}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    height: { md: 40, lg: 40 },
                  }}
                  startIcon={<Search />}
                  type="submit"
                  disabled={!isValid}
                  onClick={(e) => {
                    e.preventDefault();
                    setManageStatistics(statsForm);
                  }}
                >
                  {dictionary["search"]}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    height: { md: 40, lg: 40 },
                  }}
                  startIcon={<Refresh />}
                  type="submit"
                  onClick={(e) => {}}
                >
                  {dictionary["reset"]}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card elevation={5}>
        <CardContent>
          <DataGrid
            rows={statistics}
            autoHeight
            columns={columns}
            disableColumnMenu
            hideFooter
            pageSize={100}
            initialState={{
              sorting: {
                sortModel: [{ field: 'costcenter', sort: 'asc' }],
              },
            }}
          />
        </CardContent>
      </Card>
      {isMobile ? <></> : <StatisticsTables statistics={statistics} />}
    </Stack>
  );
};

export default ManageStatistics;
