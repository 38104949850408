import React from "react";
import {Typography, Stack, Grid} from "@mui/material";
import moment from "moment";
import {useDictionary} from "../hooks";

const HolidayDetailModal = ({holiday}) => {
  const dictionary = useDictionary();

  return (
    <Stack direction="row" spacing={2}>
      <Grid container>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="body1">
            <span style={{fontWeight: "bold"}}>{dictionary["reports"]["dateFrom"]}</span>
            {" >> "}
            {moment(holiday.datefrom).format('ddd:')}&nbsp;
            {moment(holiday.datefrom).format('DD/MM/YYYY')}
          </Typography>
          <Typography variant="body1">
            <span style={{fontWeight: "bold"}}>{dictionary["reports"]["dateTo"]}</span>
            {" >> "}
            {moment(holiday.dateto).format('ddd:')}&nbsp;
            {moment(holiday.dateto).format('DD/MM/YYYY')}
          </Typography>
          <Typography variant="body1">
            <span style={{fontWeight: "bold"}}>{dictionary["holidays"]["days"]}:</span>
            {holiday.days}
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <Typography variant="body1">
            <span style={{fontWeight: "bold"}}>Note Richiedente:</span>
          </Typography>
          {holiday.notes}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <hr />
        </Grid>
        {holiday.outcomes.map((o) =>
          o.notes ? (
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="body1">
                <span style={{fontWeight: "bold"}}>Note Approvatore:</span>
              </Typography>
              {o.notes}
            </Grid>
          ) : null
        )}
      </Grid>
    </Stack>
  );
};

export default HolidayDetailModal;
