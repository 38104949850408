import React, { useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { useDictionary, useForm } from "../hooks";
import {
  isRequired,
  isSame,
  isValidLengthPasswod,
  isNotSame,
} from "../hooks/useForm";

const ResetPassword = ({ formRef }) => {
  const dictionary = useDictionary()
  const INITIAL_STATE = {
    oldpin: "",
    newpin: "",
    confirmpin: "",
  };
  useEffect(() => {
    formRef.oldpin = INITIAL_STATE.oldpin;
    formRef.newpin = INITIAL_STATE.newpin;
    formRef.confirmpin = INITIAL_STATE.confirmpin;
  }, []);

  const validations = [
    ({ oldpin }) =>
      isRequired(oldpin) || {
        oldpin: dictionary["profilemode"]["oldpassenter"],
      },
    ({ newpin }) =>
      isValidLengthPasswod(newpin) || {
        newpin: dictionary["profilemode"]["advlength"],
      },
    ({ newpin, oldpin }) =>
      isNotSame(newpin, oldpin) || {
        newpin: dictionary["profilemode"]["samepass"],
      },
    ({ newpin, confirmpin }) =>
      isSame(newpin, confirmpin) || {
        confirmpin:  dictionary["profilemode"]["nomatchpass"],
      },
  ];

  const { values, isValid, errors, touched, changeHandler } = useForm(
    INITIAL_STATE,
    validations
  );

  formRef.isValid = isValid;

  return (
    <Grid container spacing={2} sx={{ mt: (theme) => theme.spacing(1) }}>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          size="small"
          fullWidth
          label={dictionary["profilemode"]["oldpin"]}
          value={values.oldpin}
          error={Boolean(touched.oldpin && errors.oldpin)}
          helperText={touched.oldpin && errors.oldpin}
          type="password"
          onChange={({ target }) => {
            changeHandler("oldpin", target.value);
            formRef.oldpin = target.value;
          }}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          size="small"
          fullWidth
          type="password"
          label={dictionary["profilemode"]["newpin"]}
          value={values.newpin}
          error={Boolean(touched.newpin && errors.newpin)}
          helperText={touched.newpin && errors.newpin}
          onChange={({ target }) => {
            changeHandler("newpin", target.value);
            formRef.newpin = target.value;
          }}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          size="small"
          fullWidth
          type="password"
          label={dictionary["profilemode"]["repeatpin"]}
          value={values.confirmpin}
          error={Boolean(touched.confirmpin && errors.confirmpin)}
          helperText={touched.confirmpin && errors.confirmpin}
          onChange={({ target }) => {
            changeHandler("confirmpin", target.value);
            formRef.confirmpin = target.value;
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
