import React, {useContext, useEffect} from "react";
import {AuthContext} from "../contexts";

const useUser = () => {
  const {
    state: {user, image, geolocationEnabled},
    setUser,
    getUser,
    getUserImage
  } = useContext(AuthContext);

  return {user, image, setUser, geolocationEnabled, getUserImage};
};

export default useUser;
