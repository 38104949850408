import { Grid, TextField, Typography } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import React, { useEffect } from 'react';
import { useDictionary, useForm } from '../hooks';
import moment from 'moment';
import { isDateGreater, isRequired } from '../hooks/useForm';
import { dateDiff } from '../utilities';

const AddNotes = ({ formRef }) => {

    const INITIAL_STATE = {
        notes: '',
    };
    const dictionary = useDictionary();
    const validations = [
    ];

    useEffect(() => {
        formRef.notes = INITIAL_STATE.notes;
    }, []);

    const { values, changeHandler } = useForm({notes: ''});


    return (
        <Grid container spacing={2} sx={{ mt: theme => theme.spacing(1) }}>
            <Grid item xs={12} md={12} lg={12}>
                <TextField
                    size="small"
                    minRows={2}
                    maxRows={7}
                    fullWidth
                    multiline
                    label={dictionary["note"]}
                    value={values.notes}
                    onChange={({ target }) => {
                        changeHandler("notes", target.value);
                        formRef.notes = target.value;
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default AddNotes;