import React, { useContext, useEffect } from 'react'
import {
    Stack, Card, CardContent, Typography,
    Accordion, AccordionSummary, AccordionDetails, Badge, CardHeader
} from "@mui/material";
import { ReportsContext } from '../contexts';
import { ReportsTables } from "../components";
import { ExpandMore } from '@mui/icons-material';
import { useDictionary } from "../hooks";

const ReportsUsersStatus = () => {
    const {
        state: { usersReports },
        setUsersReports,
    } = useContext(ReportsContext);
   

    useEffect(() => {
        setUsersReports();
    }, []);

    const dictionary = useDictionary();

    const orderedReports = [];
    usersReports.map(report => {
        let key = `${report.firstname.trim()} ${report.lastname.trim()}`;
        if (!orderedReports[key]) {
            orderedReports[key] = [];
        }
        orderedReports[key]?.push(report);
    });

    return (
        <Stack spacing={2}>
            <Card>
                <CardHeader title={dictionary["routes"]["reportsToCompile"]} />
                <CardContent>
                    {Object.entries(orderedReports).map((item, index) => (
                        <Accordion key={`${item[0]}-${index}`}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />} >
                                <Typography variant="outlined">
                                    {item[0]}
                                </Typography>
                                <Badge badgeContent={item[1].length} color={"reports"} sx={{ marginLeft: "15px" }} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <ReportsTables
                                    mini={false}
                                    onlyView={true}
                                    searchedReport={item[1]}
                                />
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </CardContent>
            </Card>
        </Stack >
    )
}

export default ReportsUsersStatus