import React from 'react'
import { useDictionary } from "../hooks"
import {
    Grid,
    Typography,
    List,
    ListItemIcon,
    ListItem,
    ListItemText
} from "@mui/material";
import { Person } from "@mui/icons-material";

const ApproversList = ({ approvers }) => {
    const dictionary = useDictionary();

    return (
        <Grid container spacing={2} textAlign="center">
            {

                approvers ?
                    Object.entries(approvers)?.map(item =>
                        <Grid
                            item
                            xs={12} md={3} lg={3}
                            sx={{ display: { xs: "row", md: "row", lg: "flex" } }}
                            key={item[0]}
                        >
                            <Typography variant="subtitle1">
                                {dictionary['approvers'][item[0]]}

                                <List>
                                    {item[1].map(role =>
                                        <ListItem key={`${item[0]}-${role.id}`}>
                                            <ListItemIcon>
                                                <Person />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {`${role.firstname} ${role.lastname}`}
                                            </ListItemText>
                                        </ListItem>,
                                    )}
                                </List>

                            </Typography>
                        </Grid>
                    ) : null}
        </Grid>
    )
}

export default ApproversList