import {
  Add,
  Delete,
  LaunchTwoTone,
  Refresh,
  Search,
} from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { IconBox, Note } from "../components";
import {
  BusinesstripsContext,
  HolidaysContext,
  PermitsContext,
  RequestsContext,
} from "../contexts";
import {
  useDictionary,
  useForm,
  useMediaQuery,
  usePagination,
  useUser,
} from "../hooks";
import { ROWS_PER_PAGE } from "../hooks/usePagination";
import {
  addMonthsToDate,
  deleteMillisFromTime,
  renderHeader,
  translateMinutesInTime,
} from "../utilities";
import { openModal } from "../hooks/useModals";

const Requests = () => {
  const dictionary = useDictionary();
  const openModalNotes = (r) => {
    openModal(
      dictionary["notes"],
      <Note richiesta={r} />,
      undefined,
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            color="error"
            size="small"
            variant="contained"
            onClick={() => {
              closeModal();
            }}
          >
            {dictionary["close"]}
          </Button>
        </Stack>
      ),
      "md"
    );
  };
  const INITIAL_STATE = {
    from: new Date(),
    to: addMonthsToDate(new Date(), 1),
    status: "NEW",
    type: "ALL",
  };
  const validations = [];
  const {
    values: searchForm,
    changeHandler,
    touched,
    errors,
    reset,
    isValid,
  } = useForm(INITIAL_STATE, validations);
  const { user } = useUser();
  const { addHoliday, deleteHoliday } = useContext(HolidaysContext);
  const { addBusinesstrip, deleteBusinesstrip } =
    useContext(BusinesstripsContext);
  const { addPermit, deletePermit } = useContext(PermitsContext);
  const {
    state: { requests },
    setRequests,
  } = useContext(RequestsContext);
  const [pageSize, setPageSize] = usePagination();
  const [isMobile] = useMediaQuery("down", "md");

  useEffect(() => {
    setRequests(INITIAL_STATE);
  }, []);

  const caseRequests = (row) => {
    switch (row.requesttype) {
      case "PERMIT":
        deletePermit(row, dictionary, setRequests, INITIAL_STATE);
        break;
      case "HOLIDAY":
        deleteHoliday(row, dictionary, setRequests, INITIAL_STATE);
        break;
      case "BUSINESSTRIP":
        deleteBusinesstrip(row, dictionary, setRequests, INITIAL_STATE);
        break;
      default:
        console.error("Request type not implemented");
        break;
    }
  };

  let columns = [];

  const columnsDesk = [
    {
      field: "requesttype",
      renderCell: ({ value }) => dictionary["requests"]["types"][value],
      headerName: dictionary["requests"]["type"],
      renderHeader: renderHeader(dictionary["requests"]["type"]),
    },
    {
      field: "days",
      renderCell: ({ row }) =>
        ["HOLIDAY", "BUSINESSTRIP"].includes(row.requesttype)
          ? row.days
          : translateMinutesInTime(row.minutes),
      headerName: dictionary["requests"]["days/hours"],
      renderHeader: renderHeader(dictionary["requests"]["days/hours"]),
    },
    {
      field: "datefrom",
      flex: 1,
      renderCell: (params) => (
        <Stack spacing={0.5}>
          {["HOLIDAY", "BUSINESSTRIP"].includes(params.row.requesttype) ? (
            <React.Fragment>
              <Stack direction="row" spacing={0.5} mt={1}>
                <Typography sx={{ fontWeight: "bold" }} variant="body2">
                  {dictionary["from"]}:{" "}
                </Typography>
                <Typography variant="body2">{`${moment(
                  params.row.datefrom
                ).format("DD/MM/YYYY")}`}</Typography>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography sx={{ fontWeight: "bold" }} variant="body2">
                  {dictionary["to"]}:{" "}
                </Typography>
                <Typography variant="body2">{`${moment(
                  params.row.dateto
                ).format("DD/MM/YYYY")}`}</Typography>
              </Stack>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Stack direction="row" spacing={0.5} mt={1}>
                <Typography sx={{ fontWeight: "bold" }} variant="body2">
                  {dictionary["permits"]["from"]}:{" "}
                </Typography>
                <Typography variant="body2">{`${moment(
                  params.row.datefrom
                ).format("DD/MM/YYYY")} - ${deleteMillisFromTime(
                  params.row.startinghour
                )}`}</Typography>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography sx={{ fontWeight: "bold" }} variant="body2">
                  {dictionary["permits"]["to"]}:{" "}
                </Typography>
                <Typography variant="body2">{`${moment(
                  params.row.dateto
                ).format("DD/MM/YYYY")} - ${deleteMillisFromTime(
                  params.row.endinghour
                )}`}</Typography>
              </Stack>
            </React.Fragment>
          )}
        </Stack>
      ),
      headerName: dictionary["requests"]["period"],
      renderHeader: renderHeader(dictionary["requests"]["period"]),
    },
    {
      field: "requestdatetime",
      flex: 1,
      renderCell: ({ value }) => {
        const [date, time] = value.split("T");
        return `${moment(date).format("DD/MM/YYYY")}, ${deleteMillisFromTime(
          time
        )}`;
      },
      headerName: dictionary["requests"]["createdAt"],
      renderHeader: renderHeader(dictionary["requests"]["createdAt"]),
    },
    {
      field: "status",
      renderCell: ({ value }) => dictionary["requestStatus"][value],
      headerName: dictionary["requests"]["status"],
      renderHeader: renderHeader(dictionary["requests"]["status"]),
    },
    {
      field: "notes",
      headerName: dictionary["notes"],
      renderHeader: renderHeader(dictionary["notes"]),
      renderCell: ({ row }) => (
        <Stack direction={"row"} spacing={2}>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => openModalNotes(row)}
          >
            <LaunchTwoTone fontSize="inherit" />
          </IconButton>
        </Stack>
      ),
    },
    {
      headerName: "actions",
      field: "",
      renderHeader: renderHeader(dictionary["actions"]),
      renderCell: ({ row }) => (
        <Stack direction="row">
          <IconButton
            onClick={() => caseRequests(row)}
            aria-label="delete-request"
            color="error"
            size="small"
          >
            <Delete fontSize="inherit" />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const columnsMobile = [
    {
      field: "requesttype",
      renderCell: ({ value }) =>
        dictionary["requests"]["types"][value].substring(0, 4) + ".",
      headerName: dictionary["requests"]["type"],
      renderHeader: renderHeader(dictionary["requests"]["type"]),
      flex: 1,
    },
    {
      field: "datefrom",
      flex: 2,
      renderCell: (params) => (
        <Stack spacing={0.5}>
          {["HOLIDAY", "BUSINESSTRIP"].includes(params.row.requesttype) ? (
            <React.Fragment>
              <Stack direction="row" spacing={0.5} mt={1}></Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography variant="body2">{`${moment(
                  params.row.dateto
                ).format("DD/MM/YY")}`}</Typography>
              </Stack>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Stack direction="row" spacing={0.5} mt={1}>
                <Typography variant="body2">{`${moment(
                  params.row.datefrom
                ).format("DD/MM/YY")} - ${deleteMillisFromTime(
                  params.row.startinghour
                )}`}</Typography>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography variant="body2">{`${moment(
                  params.row.dateto
                ).format("DD/MM/YY")} - ${deleteMillisFromTime(
                  params.row.endinghour
                )}`}</Typography>
              </Stack>
            </React.Fragment>
          )}
        </Stack>
      ),
      headerName: dictionary["requests"]["period"],
      renderHeader: renderHeader(dictionary["requests"]["period"]),
    },
    {
      field: "status",
      renderCell: ({ value }) =>
        dictionary["requestStatus"][value].substring(0, 6) + ".",
      headerName: dictionary["requests"]["status"],
      renderHeader: renderHeader(dictionary["requests"]["status"]),
      flex: 1,
    },
    {
      field: "notes",
      headerName: dictionary["notes"],
      renderHeader: renderHeader(dictionary["notes"]),
      renderCell: ({ row }) => (
        <Stack direction={"row"} spacing={2}>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => openModalNotes(row)}
          >
            <LaunchTwoTone fontSize="inherit" />
          </IconButton>
        </Stack>
      ),
    },
    {
      headerName: "actions",
      field: "",
      flex: 1,
      renderHeader: renderHeader(dictionary["actions"]),
      renderCell: ({ row }) => (
        <Stack direction="row">
          <IconButton
            onClick={() =>
              row.requesttype === "PERMIT"
                ? deletePermit(row, dictionary, setRequests, INITIAL_STATE)
                : row.requesttype === "HOLIDAY"
                ? deleteHoliday(row, dictionary, setRequests, INITIAL_STATE)
                : row.requesttype === "BUSINESSTRIP"
                ? deleteBusinesstrip(
                    row,
                    dictionary,
                    setRequests,
                    INITIAL_STATE
                  )
                : console.error("Request type not implemented")
            }
            aria-label="delete-request"
            color="error"
            size="small"
          >
            {row.status === "NEW" ? <Delete fontSize="inherit" /> : <></>}
          </IconButton>
        </Stack>
      ),
    },
  ];

  isMobile ? (columns = columnsMobile) : (columns = columnsDesk);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            startIcon={<Add />}
            color="requests"
            size="small"
            variant="contained"
            onClick={() => addHoliday(user.id, dictionary)}
          >
            {dictionary["requests"]["askHoliday"]}
          </Button>
          <Button
            startIcon={<Add />}
            color="requests"
            size="small"
            variant="contained"
            // variant="outlined"
            onClick={() => addPermit(user.id, dictionary)}
          >
            {dictionary["requests"]["askPermit"]}
          </Button>
          <Button
            startIcon={<Add />}
            color="requests"
            size="small"
            variant="contained"
            // variant="outlined"
            onClick={() => addBusinesstrip(user.id, dictionary)}
          >
            {dictionary["requests"]["askBusinesstrip"]}
          </Button>
        </Stack>
      </GridToolbarContainer>
    );
  };

  return (
    <Stack spacing={2}>
      <Card>
        <IconBox Icon={Search} />
        <CardHeader
          title={dictionary["requests"]["title"]}
          sx={{ pl: (theme) => theme.spacing(12) }}
        />
        <CardContent component="form">
          <Grid container spacing={2} mb={1}>
            <Grid item xs={12} md={6} lg={3}>
              <DesktopDatePicker
                label={dictionary["requests"]["from"]}
                inputFormat="DD/MM/yyyy"
                value={searchForm.from}
                onChange={(newValue) => changeHandler("from", newValue)}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DesktopDatePicker
                label={dictionary["requests"]["to"]}
                inputFormat="DD/MM/yyyy"
                value={searchForm.to}
                onChange={(newValue) => changeHandler("to", newValue)}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  {dictionary["requests"]["status"]}
                </InputLabel>
                <Select
                  id="demo-simple-select"
                  value={searchForm.status}
                  label={dictionary["requests"]["status"]}
                  onChange={(e) => changeHandler("status", e.target.value)}
                >
                  <MenuItem value={"ALL"}>
                    {dictionary["requestStatus"]["ALL"]}
                  </MenuItem>
                  <MenuItem value={"NEW"}>
                    {dictionary["requestStatus"]["NEW"]}
                  </MenuItem>
                  <MenuItem value={"APPROVED"}>
                    {dictionary["requestStatus"]["APPROVED"]}
                  </MenuItem>
                  <MenuItem value={"REVOKED"}>
                    {dictionary["requestStatus"]["REVOKED"]}
                  </MenuItem>
                  <MenuItem value={"CANCELLED"}>
                    {dictionary["requestStatus"]["CANCELLED"]}
                  </MenuItem>
                  <MenuItem value={"DELETED"}>
                    {dictionary["requestStatus"]["DELETED"]}
                  </MenuItem>
                  <MenuItem value={"REFUSED"}>
                    {dictionary["requestStatus"]["REFUSED"]}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  {dictionary["requests"]["type"]}
                </InputLabel>
                <Select
                  id="demo-simple-select"
                  value={searchForm.type}
                  label={dictionary["requests"]["type"]}
                  onChange={(e) => changeHandler("type", e.target.value)}
                >
                  <MenuItem value={"ALL"}>
                    {dictionary["requests"]["types"]["ALL"]}
                  </MenuItem>
                  <MenuItem value={"PERMIT"}>
                    {dictionary["requests"]["types"]["PERMIT"]}
                  </MenuItem>
                  <MenuItem value={"HOLIDAY"}>
                    {dictionary["requests"]["types"]["HOLIDAY"]}
                  </MenuItem>
                  <MenuItem value={"BUSINESSTRIP"}>
                    {dictionary["requests"]["types"]["BUSINESSTRIP"]}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Stack direction="row" justifyContent={"flex-end"} spacing={2}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    // height: { md: 40, lg: 40 },
                  }}
                  disabled={!isValid}
                  startIcon={<Search />}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    setRequests(searchForm);
                  }}
                >
                  {dictionary["search"]}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                    // height: { md: 40, lg: 40 },
                  }}
                  startIcon={<Refresh />}
                  onClick={reset}
                >
                  {dictionary["reset"]}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card elevation={5}>
        <CardContent>
          <DataGrid
            disableColumnMenu
            disableSelectionOnClick
            autoHeight
            rows={requests}
            columns={columns}
            pagination
            onPageSizeChange={setPageSize}
            pageSize={pageSize}
            rowsPerPageOptions={ROWS_PER_PAGE}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </CardContent>
      </Card>
    </Stack>
  );
};

export default Requests;
