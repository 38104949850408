import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import { useDictionary, useUser } from "../hooks";
import {
  AddTwoTone,
  AdminPanelSettingsTwoTone,
  CalendarMonth,
  Delete,
  DoneAll,
  Edit,
  EventBusyOutlined,
  EventRepeat,
  GroupAdd,
  PersonTwoTone,
  PlayArrowRounded,
  StartTwoTone,
  StopCircleRounded,
  Visibility,
  Warning,
} from "@mui/icons-material";
import { DailyActivitiesContext } from "../contexts";
import { openModal } from "../hooks/useModals";
import DailyActivitiesForm from "./DailyActivitiesForm";
import { red } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const DailyActivitiesTabels = ({
  mini = false,
  onlyView = false,
  searchedDailyAct,
  manage = false,
}) => {
  const dictionary = useDictionary();
  const { user, geolocationEnabled } = useUser();
  const navigate = useNavigate();

  const DATE_FORMAT = "DD-MM-YYYY";
  const {
    startActivities,
    stopActivities,
    pauseActivities,
    getUserListForSupervisor,
    deleteActivities,
  } = useContext(DailyActivitiesContext);

  useEffect(() => {
    if (manage) {
      getUserListForSupervisor();
    }
  }, []);

  const timeRef = useRef();
  const dateRef = useRef();
  const notesRef = useRef();
  const positionRef = useRef();

  const handleStartActClock = (direction, item) => {
    openModal(
      direction === "OUT"
        ? "Termina Intervento"
        : direction === "PAUSE"
        ? "Blocca Intervento"
        : "Inizia Intervento",
      <DailyActivitiesForm
        direction={direction}
        title={item.title}
        costcenter={item.costcenter}
        geolocationEnabled={geolocationEnabled}
        dateRef={dateRef}
        timeRef={timeRef}
        notesRef={notesRef}
        positionRef={positionRef}
        dictionary={dictionary}
      />,
      null,
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            color="success"
            variant="contained"
            size="small"
            onClick={() =>
              direction === "OUT"
                ? stopActivities(
                    dateRef.current,
                    timeRef.current,
                    closeModal,
                    positionRef,
                    user,
                    item,
                    manage,
                    false,
                    notesRef.current
                  )
                : direction === "PAUSE"
                ? pauseActivities(
                    dateRef.current,
                    timeRef.current,
                    closeModal,
                    positionRef,
                    user,
                    item,
                    manage,
                    notesRef.current
                  )
                : startActivities(
                    dateRef.current,
                    timeRef.current,
                    closeModal,
                    positionRef,
                    user,
                    item,
                    manage,
                    notesRef.current
                  )
            }
          >
            {dictionary["confirm"]}
          </Button>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={closeModal}
          >
            {dictionary["cancel"]}
          </Button>
        </Stack>
      )
    );
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Stack
        sx={{ mb: (theme) => theme.spacing(1) }}
        direction="row"
        justifyContent="flex-end"
        spacing={3}
      >
        <Button
          id="create-new-activity-btn"
          variant="contained"
          onClick={() =>
            navigate(
              `/daily-activities/new?mode=new&role=${
                manage ? "manage" : "user"
              }`
            )
          }
          startIcon={<AddTwoTone />}
          size="small"
        >
          {dictionary["dailyactivities"]["newActivity"]}
        </Button>
      </Stack>
    </GridToolbarContainer>
  );

  const detailDailyActivities = (activities) => {
    navigate(
      `/daily-activities/${activities.id}?mode=view&role=${
        manage ? "manage" : "user"
      }`
    );
  };

  const editMode = (activities) => {
    navigate(
      `/daily-activities/${activities.id}?mode=edit&role=${
        manage ? "manage" : "user"
      }`
    );
  };

  return (
    <>
      <Stack
        sx={{ mb: (theme) => theme.spacing(1) }}
        direction="row"
        spacing={3}
      >
        <Button
          id="create-new-activity-btn"
          variant="contained"
          onClick={() =>
            navigate(
              `/daily-activities/new?mode=new&role=${
                manage ? "manage" : "user"
              }`
            )
          }
          startIcon={<AddTwoTone />}
          size="small"
        >
          {dictionary["dailyactivities"]["newActivity"]}
        </Button>
      </Stack>
      {searchedDailyAct?.map((row, i) => (
        <Card id={`card-${row.id}`} key={i + row.id} variant="outlined">
          <CardHeader
            sx={{ padding: "10px !important;" }}
            avatar={
              <Stack direction={"row"} justifyContent={"center"}>
                {row.creatorid === user.id ? (
                  <Tooltip title={"Creato da " + row.creator}>
                    <IconButton size="sm">
                      <PersonTwoTone color="info" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={"Creato da " + row.creator}>
                    <IconButton size="sm">
                      <AdminPanelSettingsTwoTone color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            }
            title={row.title}
            titleTypographyProps={{ fontSize: 20, fontWeight: "bold" }}
            subheader={row.costcenter}
            action={
              <Stack direction={"row"} spacing={2}>
                {row.executions.length > 0 &&
                row.executions.find(
                  (res) => res.execendactivitydate === null
                ) ? (
                  <Box
                    sx={{ position: "relative" }}
                    onClick={() => handleStartActClock("OUT", row)}
                  >
                    <IconButton
                      aria-label="delete"
                      size="sm"
                      /* onClick={() => handleStartActClock("OUT", row)} */
                    >
                      <StopCircleRounded
                        fontSize="inherit"
                        sx={{ fontSize: 35 }}
                        color="error"
                      />
                    </IconButton>

                    {/* {loading && ( */}
                    <CircularProgress
                      size={42}
                      sx={{
                        color: red[500],
                        position: "absolute",
                        top: 5,
                        left: 5,
                        zIndex: 1,
                      }}
                    />
                    {/*  )} */}
                  </Box>
                ) : row.executions.length > 0 &&
                  row.executions.filter(
                    (res) => res.execendactivitydate !== null
                  ).length > 0 ? (
                  <IconButton aria-label="delete" size="sm">
                    <DoneAll
                      fontSize="inherit"
                      sx={{ fontSize: 35 }}
                      color="success"
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="delete"
                    size="sm"
                    onClick={() => handleStartActClock("IN", row)}
                  >
                    <PlayArrowRounded sx={{ fontSize: 35 }} color="info" />
                  </IconButton>
                )}
              </Stack>
            }
          />
          <CardContent sx={{ padding: "0px !important;" }}>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {manage && (
                <ListItem>
                  <ListItemAvatar>
                    <GroupAdd />
                  </ListItemAvatar>
                  <ListItemText primary={`${dictionary["dailyactivities"]["form"]["assignedto"]} ${row.user}`} />
                </ListItem>
              )}
              {row.plannedstartactivitydate === null &&
                row.plannedendactivitydate === null &&
                row.notbeforedate === null &&
                row.performwithindate === null && (
                  <ListItem>
                    <ListItemAvatar>
                      <EventRepeat />
                    </ListItemAvatar>

                    <ListItemText primary={`${dictionary["dailyactivities"]["form"]["notplanned"]}`} />
                  </ListItem>
                )}
              {row.plannedstartactivitydate && (
                <ListItem>
                  <ListItemAvatar>
                    <CalendarMonth />
                  </ListItemAvatar>

                  <ListItemText
                    primary={`Intervento pianificato per il ${moment(
                      row.plannedstartactivitydate
                    ).format(DATE_FORMAT)} ${
                      row.plannedstartactivitytime
                        ? row.plannedstartactivitytime
                        : ""
                    }`}
                  />
                </ListItem>
              )}
              {row.plannedendactivitydate && (
                <ListItem>
                  <ListItemAvatar>
                    <EventBusyOutlined />
                  </ListItemAvatar>

                  <ListItemText
                    primary={`Termine pianificato per il ${moment(
                      row.plannedendactivitydate
                    ).format(DATE_FORMAT)} ${
                      row.plannedendactivitytime
                        ? row.plannedendactivitytime
                        : ""
                    }`}
                  />
                </ListItem>
              )}
              {row.notbeforedate && (
                <ListItem>
                  <ListItemAvatar>
                    <Warning color="warning" />
                  </ListItemAvatar>

                  <Box sx={{ width: "100%" }}>
                    {row.notbeforedate ? (
                      <>
                        <Typography fontWeight={"bold"}>
                          {dictionary["dailyactivities"]["form"]["notbeforeHinit"]}:{" "}
                          {row.notbeforedate
                            ? moment(row.notbeforedate).format(DATE_FORMAT)
                            : ""}{" "}
                          {row.notbeforehour ? `alle ${row.notbeforehour}` : ""}
                        </Typography>
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </ListItem>
              )}
              {row.performwithindate && (
                <ListItem>
                  <ListItemAvatar>
                    <Warning color="warning" />
                  </ListItemAvatar>

                  <Box sx={{ width: "100%" }}>
                    {row.performwithindate ? (
                      <>
                        <Typography fontWeight={"bold"}>
                        {dictionary["dailyactivities"]["form"]["performwithindateHinit"]}:{" "}
                          {row.performwithindate
                            ? moment(row.performwithindate).format(DATE_FORMAT)
                            : ""}{" "}
                          {row.performwithinhour
                            ? `alle ${row.performwithinhour}`
                            : ""}
                        </Typography>
                      </>
                    ) : (
                      <></>
                    )}
                  </Box>
                </ListItem>
              )}
              {row?.executions?.length > 0 && (
                <Stack direction={"row"} spacing={5}>
                  <ListItem>
                    <ListItemAvatar>
                      <StartTwoTone color="success" />
                    </ListItemAvatar>
                    <ListItemText
                      primary={dictionary["dailyactivities"]["start"]}
                      secondary={
                        row.executions[0].execstartactivitydate !== null
                          ? `${moment(
                              row.executions[0].execstartactivitydate
                            ).format("DD/MM/GG")} alle ${
                              row.executions[0].execstartactivitytime !== null
                                ? `${row.executions[0].execstartactivitytime}`
                                : "Intervento non iniziato ..."
                            }`
                          : "Intervento da iniziare"
                      }
                    />
                  </ListItem>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  {row.executions[0].execendactivitydate !== null && (
                    <ListItem>
                      <ListItemAvatar>
                        <DoneAll color="success" />
                      </ListItemAvatar>
                      <ListItemText
                        primary={dictionary["dailyactivities"]["terminated"]}
                        secondary={
                          row.executions[0].execendactivitydate !== null
                            ? `${moment(
                                row.executions[0].execendactivitydate
                              ).format("DD/MM/GG")} alle ${
                                row.executions[0].execendactivitytime !== null
                                  ? `${row.executions[0].execendactivitytime}`
                                  : "Intervento in esecuzione ..."
                              }`
                            : "Intervento in esecuzione"
                        }
                      />
                    </ListItem>
                  )}
                </Stack>
              )}
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"right"}
                pr={2}
              >
                <IconButton onClick={() => detailDailyActivities(row)}>
                  <Visibility color="info" />
                </IconButton>
                {row.creatorid === user.id || manage ? (
                  <IconButton onClick={() => editMode(row)}>
                    <Edit color="primary" />
                  </IconButton>
                ) : null}
                {row.executions.length > 0 &&
                row.executions.find(
                  (res) => res.execendactivitydate === null
                ) ? (
                  <></>
                ) : row.executions.length > 0 &&
                  row.executions.filter(
                    (res) => res.execendactivitydate !== null
                  ).length > 0 ? (
                  <></>
                ) : row.creatorid === user.id || manage ? (
                  <IconButton
                    onClick={() => deleteActivities(row, manage, user)}
                  >
                    <Delete color="error" />
                  </IconButton>
                ) : null}
              </Stack>
            </List>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default DailyActivitiesTabels;
