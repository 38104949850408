import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Skeleton,
  Stack,
  Switch,
  Tab,
  TextField,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useCostCenters,
  useDictionary,
  useForm,
  useQueryParams,
  useUser,
} from "../hooks";
import { CostCenterContext, DailyActivitiesContext } from "../contexts";
import { isDateGreater, isRequired } from "../hooks/useForm";
import {
  Add,
  AddLocationAlt,
  Cancel,
  CloudUpload,
  Delete,
  DoneAll,
  Download,
  Edit,
  PlayArrowRounded,
  PlayArrowSharp,
  SaveAsTwoTone,
  Stop,
  Visibility,
  WarningTwoTone,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { DailyActivitiesForm, FormDialog, IconBox } from "../components";
import { openModal } from "../hooks/useModals";
import { red } from "@mui/material/colors";
import { sendNotification } from "../hooks/useNotification";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

const iconPerson = new L.Icon({
  iconUrl: require("../assets/avatar_user2.png"),
  iconRetinaUrl: require("../assets/avatar_user2.png"),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(20, 20),
  className: "leaflet-div-icon",
});

const DailyActivity = () => {
  const dictionary = useDictionary();
  const [valueTab, setValueTab] = React.useState("1");
  const costcenters = useCostCenters();

  const {
    state: { costCenterUser },
    setCostCenterUser,
  } = useContext(CostCenterContext);

  const { user, image, getUserImage, geolocationEnabled } = useUser();
  const [isBound, setIsBound] = useState(false);

  const { id } = useParams();
  const query = useQueryParams(dictionary);
  let mode = query("mode");
  let role = query("role");

  const manage = role == "manage" ? true : false;
  const view = mode == "view" ? true : false;
  const edit = mode == "edit" ? false : true;

  
  const navigate = useNavigate();

  const DATE_FORMAT = "YYYY-MM-DD";

  const handleClose = () => {
    if (manage) {
      navigate("/collaborators/daily-activities");
    } else {
      navigate("/daily-activities");
    }
  };

  const {
    state: {
      dailyActivity,
      userListForDailyActivities,
      dataToPrevision,
      dataToActivities,
    },
    insertDailyActivities,
    updateDailyActivities,
    setActivity,
    resetActivity,
    getUserListForSupervisor,
    addFileToPrevision,
    getFileToPrevision,
    addFileToActivities,
    deleteFileToActivities,
    stopActivities,
    pauseActivities,
    startActivities,
    newDownloadFileToActivities,
    editFileToActivities,
    resetListFile,
    deleteActivities,
  } = React.useContext(DailyActivitiesContext);

  const exec = dailyActivity?.executions?.length > 0 ? true : false;
  console.log('exec: ', exec);

  const isFinish = dailyActivity?.executions?.length > 0 &&
  dailyActivity?.executions?.filter(
    (res) => dailyActivity.execendactivitydate !== null
  ).length > 0 ? true : false;


  const validations = [
    ({ notbeforedate, performwithindate }) =>
      isDateGreater(notbeforedate, performwithindate) || {
        notbeforedate:
          dictionary["businesstrips"]["form"]["errors"]["fromGreaterTo"],
        performwithindate:
          dictionary["businesstrips"]["form"]["errors"]["fromGreaterTo"],
      },
    ({ plannedstartactivitydate, plannedendactivitydate }) =>
      isDateGreater(plannedstartactivitydate, plannedendactivitydate) || {
        plannedstartactivitydate:
          dictionary["businesstrips"]["form"]["errors"]["fromGreaterTo"],
        plannedendactivitydate:
          dictionary["businesstrips"]["form"]["errors"]["fromGreaterTo"],
      },
    ({ title }) =>
      isRequired(title) || {
        title: dictionary["dailyactivities"]["form"]["errors"]["title"],
      },
    ({ costcenterid }) =>
      isRequired(costcenterid) || {
        costcenterid:
          dictionary["dailyactivities"]["form"]["errors"]["costcenter"],
      },
  ];

  const handleFileChange = (event, isActivity) => {
    const file = event.target.files[0];
    if (file) {
      // Controllo la dimensione del file (in bytes)
      if (file.size > 5485760) { // 10 MB
    
        sendNotification(
          "Errore Carimento File",
          "Il file è troppo grande. Dimensione massima consentita: 5 MB.",
          "error",
          5
        );
        return; // Interrompe l'esecuzione se il file è troppo grande
      }

      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result;
        const base64CleanString = base64.split(",")[1];
        if (isActivity) {
          addFileToActivities(id, {
            base64: base64CleanString,
            name: file.name,
          });
        } else {
          addFileToPrevision(id, {
            base64: base64CleanString,
            name: file.name,
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const {
    values: dailyForm,
    changeHandler,
    errors,
    touched,
    isValid,
    setValues,
    reset,
    setErrors,
  } = useForm(dailyActivity, validations);

  useEffect(() => {
    if (id !== "new") {
      setActivity(id, setIsBound);
      getFileToPrevision(id);
    }
    if (manage) {
      getUserListForSupervisor();
    }
    return () => {
      reset();
      resetActivity();
    };
  }, []);

  useEffect(() => {
    return () => {
      resetListFile();
    };
  }, []);

  useEffect(() => {
    if (id !== "new") {
      setValues({ ...dailyActivity, objstatus: "osDirty" });
      if (dailyActivity.userid) {
        setValues(dailyActivity);
        setCostCenterUser(dailyActivity.userid, 1);
      }
    } else if (id == "new" && manage) {
      setValues({ ...dailyActivity, userid: null, creatorid: user.id });
    } else if (id == "new" && role === "user") {
      setValues({ ...dailyActivity, userid: user.id, creatorid: user.id });
    }
  }, [setActivity, dailyActivity]);

  const handleUserChange = async (event) => {
    event.preventDefault();
    const userId = event.target.value;
    changeHandler("userid", userId);
    setCostCenterUser(userId, 1);
  };

  const timeRef = useRef();
  const dateRef = useRef();
  const notesRef = useRef();
  const positionRef = useRef();

  const positionModal = (type, latitude, longitude) => {
    openModal(
      type === "IN"
        ? "Posizione inizio intervento"
        : "Posizione fine intervento",
      <Grid item xs={12} md={12} lg={12}>
        <MapContainer
          center={[latitude, longitude]}
          zoom={13}
          style={{ height: 300, width: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[latitude, longitude]} icon={iconPerson}>
            <Popup>Tu sei qui</Popup>
          </Marker>
        </MapContainer>
      </Grid>,
      null,
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={closeModal}
          >
            {dictionary["cancel"]}
          </Button>
        </Stack>
      )
    );
  };

  const handleStartActClock = (direction, item, dictionary) => {
    openModal(
      direction === "OUT"
        ? dictionary["dailyactivities"]["form"]["stop"]
        : direction === "PAUSE"
        ? dictionary["dailyactivities"]["form"]["pause"]
        : dictionary["dailyactivities"]["form"]["play"],
      <DailyActivitiesForm
        direction={direction}
        title={item.title}
        costcenter={item.costcenter}
        geolocationEnabled={geolocationEnabled}
        dateRef={dateRef}
        timeRef={timeRef}
        notesRef={notesRef}
        dictionary={dictionary}
        positionRef={positionRef}
      />,
      null,
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            id="confirm-start-activity"
            color="success"
            variant="contained"
            size="small"
            onClick={() =>
              direction === "OUT"
                ? stopActivities(
                    dateRef.current,
                    timeRef.current,
                    closeModal,
                    positionRef,
                    user,
                    item,
                    manage,
                    false,
                    notesRef.current,
                    true,
                    geolocationEnabled
                  )
                : direction === "PAUSE"
                ? pauseActivities(
                    dateRef.current,
                    timeRef.current,
                    closeModal,
                    positionRef,
                    user,
                    item,
                    manage,
                    notesRef.current,
                    geolocationEnabled
                  )
                : startActivities(
                    dateRef.current,
                    timeRef.current,
                    closeModal,
                    positionRef,
                    user,
                    item,
                    manage,
                    true,
                    geolocationEnabled
                  )
            }
          >
            {dictionary["confirm"]}
          </Button>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={closeModal}
          >
            {dictionary["cancel"]}
          </Button>
        </Stack>
      )
    );
  };

  const changeIsBound = (dailyForms) => {
    setValues({
      ...dailyForms,
      plannedstartactivitydate: null,
      plannedstartactivitytime: null,
      plannedstartnotbeforedate: null,
      plannedstartnotbeforehour: null,
      plannedendactivitydate: null,
      plannedendactivitytime: null,
      performwithindate: null,
      performwithinhour: null,
      notbeforedate: null,
      notbeforehour: null,
    });
    setIsBound(!isBound);
  };

  return (
    <Card>
      <IconBox Icon={!edit ? Edit : !view ? Add : Visibility} />
      <CardHeader
        sx={{ pl: (theme) => theme.spacing(12) }}
        title={
          !edit
            ? dictionary["dailyactivities"]["form"]["edit"]
            : !view
            ? dictionary["dailyactivities"]["form"]["new"]
            : dictionary["dailyactivities"]["form"]["view"]
        }
      />
      <CardContent>
        <Grid container spacing={2} mt={1}>
          <TabContext value={valueTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                centered
                onChange={(event, newValue) => setValueTab(newValue)}
                aria-label="lab API tabs example"
              >
                <Tab
                  label={dictionary["dailyactivities"]["form"]["planned"]}
                  value="1"
                />
                {id !== "new" ? (
                  <Tab
                    label={dictionary["dailyactivities"]["form"]["report"]}
                    value="2"
                  />
                ) : (
                  <></>
                )}
              </TabList>
            </Box>
          </TabContext>
          <Grid item xs={12} md={12} lg={12} ml={1} pr={3} mt={1}>
            {!edit && dailyForm.title == "" ? (
              <Skeleton height={60} animation="wave" />
            ) : (
              <TextField
                fullWidth
                id="title-newactivity"
                label={dictionary["dailyactivities"]["form"]["title"]}
                InputProps={{
                  readOnly: view || exec,
                }}
                type="text"
                autoComplete="current-password"
                size="small"
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                value={dailyForm.title}
                onChange={(e) => changeHandler("title", e.target.value)}
              ></TextField>
            )}
          </Grid>
          {manage ? (
            <Grid item xs={12} md={12} lg={12} ml={1} pr={3} mt={1}>
              {!edit && dailyForm.userid == "" ? (
                <Skeleton height={60} animation="wave" />
              ) : (
                <TextField
                  fullWidth
                  select
                  id="assignedto-field"
                  label={dictionary["dailyactivities"]["form"]["assignedto"]}
                  InputProps={{
                    readOnly: view || exec,
                  }}
                  type="text"
                  value={dailyForm.userid || ""}
                  autoComplete="current-password"
                  error={Boolean(touched.userid && errors.userid)}
                  helperText={touched.userid && errors.userid}
                  size="small"
                  onChange={(e) => handleUserChange(e)}
                >
                  {userListForDailyActivities.map((res, idx) => (
                    <MenuItem
                    id={`assignedto-${res.id}`}
                      key={idx}
                      value={res.id}
                    >{`${res.firstname} ${res.lastname}`}</MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          ) : (
            <></>
          )}
          {manage ? (
            <Grid item xs={12} md={12} lg={12} ml={1} pr={3} mt={1}>
              {!edit && dailyForm.costcenterid == "" ? (
                <Skeleton height={60} animation="wave" />
              ) : (
                <TextField
                id="costcenter-field"
                  fullWidth
                  select
                  disabled={costCenterUser.length == 0}
                  InputProps={{
                    readOnly: view || exec,
                  }}
                    label={dictionary["dailyactivities"]["form"]["costcenter"]}
                  type="text"
                  autoComplete="current-password"
                  value={dailyForm.costcenterid || ""}
                  error={Boolean(touched.costcenterid && errors.costcenterid)}
                  helperText={touched.costcenterid && errors.costcenterid}
                  size="small"
                  onChange={(e) =>
                    changeHandler("costcenterid", e.target.value)
                  }
                >
                  {costCenterUser.map((res, idx) => (
                    <MenuItem id={`costcenter-${idx}`} key={idx} value={res.id}>
                      {res.description}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          ) : (
            <Grid item xs={12} md={12} lg={12} ml={1} pr={3} mt={1}>
              {!edit && dailyForm.costcenterid == null ? (
                <Skeleton height={60} animation="wave" />
              ) : (
                <TextField
                id="costcenter-field"
                  fullWidth
                  select
                    label={dictionary["dailyactivities"]["form"]["costcenter"]}
                  InputProps={{
                    readOnly: view || exec,
                  }}
                  autoComplete="current-password"
                  value={dailyForm.costcenterid || ""}
                  type="text"
                  error={Boolean(touched.costcenterid && errors.costcenterid)}
                  helperText={touched.costcenterid && errors.costcenterid}
                  size="small"
                  onChange={(e) =>
                    changeHandler("costcenterid", e.target.value)
                  }
                >
                  {costcenters.map((res, idx) => (
                    <MenuItem id={`costcenter-${idx}`} key={idx} value={res.id}>
                      {res.description}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          )}

          <TabContext value={valueTab} sx={{ width: "100%" }}>
            <TabPanel value="1" sx={{ width: "100%" }}>
              {/* {!edit  && dailyForm.title == ""? (
                <Skeleton height={450} animation="wave" />
              ) : ( */}
              <Grid container spacing={2}>
                {!isBound ? (
                  <Grid item xs={12} md={12} lg={12}>
                    <Card
                      elevation={0}
                      sx={{
                        border: 1,
                        borderColor: (theme) => theme.palette.info.light,
                      }}
                    >
                      <CardHeader
                        avatar={
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={view || exec || isFinish }
                                checked={isBound}
                                onChange={() => changeIsBound(dailyForm)}
                                name={isBound ? "Vincolata" : "Semplice"}
                              />
                            }
                            label={
                              isBound
                                ? dictionary["dailyactivities"]["form"]["boundAction"]
                                : dictionary["dailyactivities"]["form"]["plannedAction"]
                            }
                          />
                        }
                      />
                      <CardContent>
                        <Grid container spacing={2} mt={1}>
                          <Grid item xs={12} md={6} lg={6}>
                            <DatePicker
                              inputFormat="DD/MM/YYYY"
                              label={"Data Inizio"}
                              disabled={view || exec}
                              value={dailyForm.plannedstartactivitydate}
                              onChange={(newValue) => {
                                if (newValue && newValue.isValid()) {
                                  changeHandler(
                                    "plannedstartactivitydate",
                                    newValue?.format(DATE_FORMAT)
                                  );
                                } else {
                                  setErrors({});;
                                  setValues({
                                    ...dailyForm,
                                    plannedstartactivitydate: null,
                                    plannedstartactivitytime: null,
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  id="datefrom-field"
                                {...params}
                                  fullWidth
                                  size="small"
                                  error={Boolean(
                                    touched.plannedstartactivitydate &&
                                      errors.plannedstartactivitydate
                                  )}
                                  helperText={
                                    touched.plannedstartactivitydate &&
                                    errors.plannedstartactivitydate
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <TimePicker
                              ampm={false}
                              label={"Ora Inizio"}
                              disabled={
                                !dailyForm.plannedstartactivitydate ||
                                dailyForm.plannedstartactivitydate == "" ||
                                view ||
                               
                              exec
                              }
                              value={
                                dailyForm.plannedstartactivitytime
                                  ? moment(
                                      dailyForm.plannedstartactivitytime,
                                      "HH:mm:ss"
                                    )
                                  : null
                              }
                              onChange={(newValue) => {
                                // Verifica se newValue è valido prima di aggiornare lo stato
                                if (newValue && newValue.isValid()) {
                                  changeHandler(
                                    "plannedstartactivitytime",
                                    moment(newValue).format("HH:mm:ss")
                                  );
                                } else {
                                  // Gestisci il caso in cui l'orario è rimosso o invalido
                                  changeHandler(
                                    "plannedstartactivitytime",
                                    null
                                  );
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  size="small"
                                  error={Boolean(
                                    touched.plannedstartactivitytime &&
                                      errors.plannedstartactivitytime
                                  )}
                                  helperText={
                                    touched.plannedstartactivitytime &&
                                    errors.plannedstartactivitytime
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <DatePicker
                              inputFormat="DD/MM/YYYY"
                              label={"Data Fine"}
                              disabled={view || exec}
                              value={dailyForm.plannedendactivitydate || view}
                              onChange={(newValue) => {
                                if (newValue && newValue.isValid()) {
                                  changeHandler(
                                    "plannedendactivitydate",
                                    newValue?.format(DATE_FORMAT)
                                  );
                                } else {
                                  setErrors({});;
                                  // Gestisci il caso in cui l'orario è rimosso o invalido
                                  setValues({
                                    ...dailyForm,
                                    plannedendactivitydate: null,
                                    plannedendactivitytime: null,
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  id="dateto-field"
                                {...params}
                                  fullWidth
                                  size="small"
                                  error={Boolean(
                                    touched.plannedendactivitydate &&
                                      errors.plannedendactivitydate
                                  )}
                                  helperText={
                                    touched.plannedendactivitydate &&
                                    errors.plannedendactivitydate
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <TimePicker
                              ampm={false}
                              label={"Ora Fine"}
                              disabled={
                                
                              !dailyForm.plannedendactivitydate ||
                                view ||
                                exec
                              
                            }
                              value={
                                dailyForm.plannedendactivitytime
                                  ? moment(
                                      dailyForm.plannedendactivitytime,
                                      "HH:mm:ss"
                                    )
                                  : null
                              }
                              onChange={(newValue) => {
                                // Verifica se newValue è valido prima di aggiornare lo stato
                                if (newValue && newValue.isValid()) {
                                  changeHandler(
                                    "plannedendactivitytime",
                                    moment(newValue).format("HH:mm:ss")
                                  );
                                } else {
                                  // Gestisci il caso in cui l'orario è rimosso o invalido
                                  changeHandler("plannedendactivitytime", null);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  size="small"
                                  error={Boolean(
                                    touched.plannedendactivitytime &&
                                      errors.plannedendactivitytime
                                  )}
                                  helperText={
                                    touched.plannedendactivitytime &&
                                    errors.plannedendactivitytime
                                  }
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={12} lg={12}>
                    <Card
                      elevation={0}
                      sx={{
                        border: 1,
                        borderColor: (theme) => theme.palette.error.light,
                      }}
                    >
                      <CardHeader
                        avatar={
                          <FormControlLabel
                          disabled={view || exec || isFinish }
                            control={
                              <Switch
                                checked={isBound}
                                onChange={() => changeIsBound(dailyForm)}
                                name={isBound ? "Vincolata" : "Semplice"}
                              />
                            }
                            label={
                              isBound
                                ? dictionary["dailyactivities"]["form"]["boundAction"]
                                : dictionary["dailyactivities"]["form"]["plannedAction"]
                            }
                          />
                        }
                      />
                      <CardContent>
                        <Grid container spacing={2} mt={1}>
                          <Grid item xs={12} md={6} lg={6}>
                            <DatePicker
                              inputFormat="DD/MM/YYYY"
                              label={dictionary["dailyactivities"]["form"]["notbeforedate"]}
                              disabled={view || exec}
                              value={dailyForm.notbeforedate}
                              onChange={(newValue) => {
                                if (newValue && newValue.isValid()) {
                                  changeHandler(
                                    "notbeforedate",
                                    newValue?.format(DATE_FORMAT)
                                  );
                                } else {
                                  // Gestisci il caso in cui l'orario è rimosso o invalido
                                  setErrors({});;
                                  setValues({
                                    ...dailyForm,
                                    notbeforedate: null,
                                    notbeforehour: null,
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  id="notbefore-field"
                                {...params}
                                  fullWidth
                                  size="small"
                                  error={Boolean(
                                    touched.notbeforedate &&
                                      errors.notbeforedate
                                  )}
                                  helperText={
                                    touched.notbeforedate &&
                                    errors.notbeforedate
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <TimePicker
                              ampm={false}
                              label="Non prima delle"
                              disabled={
                                !dailyForm.notbeforedate || view || exec
                              }
                              value={
                                dailyForm.notbeforehour
                                  ? moment(dailyForm.notbeforehour, "HH:mm:ss")
                                  : null
                              }
                              onChange={(newValue) => {
                                // Verifica se newValue è valido prima di aggiornare lo stato
                                if (newValue && newValue.isValid()) {
                                  changeHandler(
                                    "notbeforehour",
                                    moment(newValue).format("HH:mm:ss")
                                  );
                                } else {
                                  // Gestisci il caso in cui l'orario è rimosso o invalido
                                  changeHandler("notbeforehour", null);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  size="small"
                                  error={Boolean(
                                    touched.notbeforehour &&
                                      errors.notbeforehour
                                  )}
                                  helperText={
                                    touched.notbeforehour &&
                                    errors.notbeforehour
                                  }
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <DatePicker
                              inputFormat="DD/MM/YYYY"
                              label={dictionary["dailyactivities"]["form"]["performwithindate"]}
                              disabled={view || exec}
                              value={dailyForm.performwithindate}
                              onChange={(newValue) => {
                                if (newValue && newValue.isValid()) {
                                  changeHandler(
                                    "performwithindate",
                                    newValue?.format(DATE_FORMAT)
                                  );
                                } else {
                                  // Gestisci il caso in cui l'orario è rimosso o invalido
                                  setErrors({});
                                  setValues({
                                    ...dailyForm,
                                    performwithindate: null,
                                    performwithinhour: null,
                                  });
                                }
                              }
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                size="small"
                                error={Boolean(
                                  touched.performwithindate &&
                                    errors.performwithindate
                                )}
                                helperText={
                                  touched.performwithindate &&
                                  errors.performwithindate
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <TimePicker
                            ampm={false}
                            label={"Entro le"}
                            disabled={!dailyForm.performwithindate || view || exec}
                            value={
                              dailyForm.performwithinhour
                                ? moment(
                                    dailyForm.performwithinhour,
                                    "HH:mm:ss"
                                  )
                                : null
                            }
                            onChange={(newValue) => {
                              // Verifica se newValue è valido prima di aggiornare lo stato
                              if (newValue && newValue.isValid()) {
                                changeHandler(
                                  "performwithinhour",
                                  moment(newValue).format("HH:mm:ss")
                                );
                              } else {
                                // Gestisci il caso in cui l'orario è rimosso o invalido
                                changeHandler("performwithinhour", null);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                size="small"
                                error={Boolean(
                                  touched.performwithinhour &&
                                    errors.performwithinhour
                                )}
                                helperText={
                                  touched.performwithinhour &&
                                  errors.performwithinhour
                                }
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                )}
                </Grid>
              {/* )} */}
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      id="note-field"
                      label="Note"
                      type="text"
                      value={dailyForm.notes || ""}
                      disabled={view || exec}
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      multiline
                      size="small"
                      autoComplete="Note"
                      onChange={(e) => changeHandler("notes", e.target.value)}
                    />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              {dailyActivity?.executions?.length > 0 &&
              dailyForm?.executions?.length > 0 ? (
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12} md={12} lg={12}>
                    <List
                      sx={{
                        width: "100%",
                        /*  maxWidth: 360, */
                        bgcolor: "background.paper",
                      }}
                    >
                      {dailyActivity.executions.length > 0 ? (
                        <>
                          <ListItem
                            secondaryAction={
                              dailyActivity.executions[0].geolatitudein ? (
                                <IconButton
                                  onClick={() =>
                                    positionModal(
                                      "IN",
                                      dailyActivity.executions[0].geolatitudein,
                                      dailyActivity.executions[0].geolongitudein
                                    )
                                  }
                                >
                                  <AddLocationAlt color="info" />
                                </IconButton>
                              ) : null
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <PlayArrowSharp />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={dictionary["dailyactivities"]["start"]}
                              secondary={
                                dailyActivity.executions[0]
                                  .execstartactivitydate !== null
                                  ? `${moment(
                                      dailyActivity.executions[0]
                                        .execstartactivitydate
                                    ).format("DD/MM/GG")} alle ${
                                      dailyActivity.executions[0]
                                        .execstartactivitytime !== null
                                        ? `${dailyActivity.executions[0].execstartactivitytime}`
                                        : ""
                                    }`
                                  : ""
                              }
                            />
                          </ListItem>
                          <ListItem
                            secondaryAction={
                              dailyActivity.executions[0].geolatitudeout ? (
                                <IconButton
                                  onClick={() =>
                                    positionModal(
                                      "OUT",
                                      dailyActivity.executions[0]
                                        .geolatitudeout,
                                      dailyActivity.executions[0]
                                        .geolongitudeout
                                    )
                                  }
                                >
                                  <AddLocationAlt color="success" />
                                </IconButton>
                              ) : null
                            }
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <Stop />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                             primary={dictionary["dailyactivities"]["terminated"]}
                              secondary={
                                dailyActivity.executions[0]
                                  .execendactivitydate !== null
                                  ? `${moment(
                                      dailyActivity.executions[0]
                                        .execendactivitydate
                                    ).format("DD/MM/GG")} alle ${
                                      dailyActivity.executions[0]
                                        .execendactivitytime !== null
                                        ? `${dailyActivity.executions[0].execendactivitytime}`
                                        : ""
                                    }`
                                  : ""
                              }
                            />
                          </ListItem>
                        </>
                      ) : (
                        <></>
                      )}
                    </List>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextField
                      fullWidth
                      id="closed-notes-field"
                      label={dictionary["dailyactivities"]["noteTerminated"]}
                      disabled
                      type="text"
                      value={dailyForm?.executions[0]?.note || ""}
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      multiline
                      size="small"
                      autoComplete="Note"
                      /* onChange={(e) => setValues({...dailyForm, executions:[...dailyForm.executions, ]}e.target.value)} */
                    />
                  </Grid>
                </Grid>
              ) : (
                <Alert
                  icon={<WarningTwoTone fontSize="inherit" />}
                  severity="warning"
                >
                  L' Intervento non è stata ancora avviato(n.t)
                </Alert>
              )}
            </TabPanel>
          </TabContext>
        </Grid>
      </CardContent>
      <TabContext value={valueTab}>
        <TabPanel value="1">
          {!edit || view ? (
            <>
              <Grid item xs={12} md={12} lg={12} mt={2}>
                <Divider>
                  <Chip label="File" size="small" />
                </Divider>
              </Grid>
              <Grid item xs={12} md={12} lg={12} pt={1} mb={3}>
                <div>
                  <input
                    disabled={view}
                    accept="image/*, .pdf"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      if (!view) {
                        handleFileChange(e, false);
                      }
                    }}
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      id="upload-button"
                      variant="contained"
                      component="span"
                      startIcon={<CloudUpload />}
                      disabled={view}
                    >
                      Upload File
                    </Button>
                  </label>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12} pt={1} mb={3}>
                <DataGrid
                  disableSelectionOnClick
                  autoHeight
                  rowHeight={60}
                  density={"compact"}
                  rows={dataToPrevision}
                  columns={[
                    {
                      field: "filename",
                      headerName: "Filename",
                      flex: 1,
                      renderCell: (params) => (
                        <div id={`cell-filename`}>{params.value}</div>
                      ),
                    },
                    {
                      field: "description",
                      headerName: dictionary["description"],
                      flex: 2,
                      renderCell: (params) => (
                        <div data-testid={`cell-description`}>
                          {params.value}
                        </div>
                      ),
                    },
                    {
                      field: "actions",
                      headerName: dictionary["actions"],
                      flex: 1,
                      renderCell: ({ row }) => (
                        <Stack direction={"row"} spacing={2}>
                          <IconButton
                            id="download-attachment-btn"
                            size="small"
                            onClick={() =>
                              newDownloadFileToActivities(row, false)
                            }
                          >
                            <Download color="success" />
                          </IconButton>
                          {view ? (
                            <></>
                          ) : (
                            <>
                              <FormDialog
                                icon={
                                  <Edit
                                    id="edit-attachment-btn"
                                    color="primary"
                                  />
                                }
                                row={row}
                                methodForm={editFileToActivities}
                                isActivity={false}
                              ></FormDialog>
                              <IconButton
                                id="delete-attachment-btn"
                                size="small"
                                onClick={() =>
                                  deleteFileToActivities(
                                    row.entityid,
                                    row.id,
                                    false
                                  )
                                }
                              >
                                <Delete color="error" />
                              </IconButton>
                            </>
                          )}
                        </Stack>
                      ),
                    },
                  ]}
                  getRowId={(row) => row.id}
                  pagination
                  hideFooter={true}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
        </TabPanel>
        <TabPanel value="2">
          {dailyActivity?.executions?.length > 0 ? (
            <>
              <Grid item xs={12} md={12} lg={12} mt={2}>
                <Divider>
                  <Chip label="File" size="small" />
                </Divider>
              </Grid>
              <Grid item xs={12} md={12} lg={12} pt={1} mb={3}>
                <div>
                  <input
                    accept="image/*, .pdf"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={(e) => handleFileChange(e, true)}
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      variant="contained"
                      component="span"
                      startIcon={<CloudUpload />}
                    >
                      Upload File
                    </Button>
                  </label>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12} pt={1} mb={3}>
                <DataGrid
                  disableSelectionOnClick
                  autoHeight
                  rowHeight={60}
                  density={"compact"}
                  rows={dataToActivities}
                  columns={[
                    { field: "filename", headerName: "Filename", flex: 1 },
                    {
                      field: "description",
                      headerName: dictionary["description"],
                      flex: 2,
                    },
                    {
                      field: "actions",
                      headerName: dictionary["actions"],
                      flex: 1,
                      renderCell: ({ row }) => (
                        <Stack direction={"row"} spacing={2}>
                          <FormDialog
                            icon={<Edit color="primary" />}
                            row={row}
                            methodForm={editFileToActivities}
                            isActivity={true}
                          ></FormDialog>
                          <IconButton
                            size="small"
                            onClick={() =>
                              newDownloadFileToActivities(row, true)
                            }
                          >
                            <Download color="success" />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() =>
                              deleteFileToActivities(row.entityid, row.id, true)
                            }
                          >
                            <Delete color="error" />
                          </IconButton>
                        </Stack>
                      ),
                    },
                  ]}
                  getRowId={(row) => row.id}
                  pagination
                  hideFooter={true}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
        </TabPanel>
      </TabContext>
      <Stack
        spacing={2}
        p={2}
        direction={/* view ? "row-reverse" :  */ "row"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} spacing={2}>
          {dailyActivity?.executions?.length > 0 &&
          dailyActivity?.executions?.find(
            (res) => res.execendactivitydate === null
          ) ? (
            <Box
              sx={{ position: "relative" }}
              onClick={() =>
                handleStartActClock("OUT", dailyActivity, dictionary)
              }
            >
              <Fab id="stop-intervento" aria-label="save" color="default">
                <Stop fontSize="large" color="error" />
              </Fab>

              {/* {loading && ( */}
              <CircularProgress
                size={68}
                sx={{
                  color: red[500],
                  position: "absolute",
                  top: -6,
                  left: -6,
                  zIndex: 1,
                }}
              />
              {/*  )} */}
            </Box>
          ) : dailyActivity?.executions?.length > 0 &&
            dailyActivity?.executions?.filter(
              (res) => dailyActivity.execendactivitydate !== null
            ).length > 0 ? (
            <Fab id="success-intervento" aria-label="save">
              <DoneAll color="success" />
            </Fab>
          ) : id !== "new" ? (
            <Fab
              id="start-intervento"
              variant="extended"
              aria-label="IN"
              onClick={() =>
                handleStartActClock("IN", dailyActivity, dictionary)
              }
            >
              <PlayArrowRounded
                fontSize={"large"}
                color="info"
                sx={{ mr: 1 }}
              />
              Avvia Intervento
            </Fab>
          ) : (
            <></>
          )}
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Button
            id="close-activity-btn"
            onClick={handleClose}
            variant="contained"
            color="warning"
            startIcon={<Cancel />}
          >
            Chiudi
          </Button>
          {view ? (
            <></>
          ) : (
            <>
              {dailyActivity?.executions?.length > 0 &&
              dailyActivity?.executions?.find(
                (res) => res.execendactivitydate === null
              ) ? (
                <></>
              ) : dailyActivity?.executions?.length > 0 &&
                dailyActivity?.executions?.filter(
                  (res) => res.execendactivitydate !== null
                ).length > 0 ? (
                <></>
              ) : dailyActivity?.creatorid === user.id ? (
                <Button
                  id="delete-activity-button"
                  onClick={() => deleteActivities(dailyActivity, manage, user)}
                  variant="contained"
                  color="error"
                  startIcon={<Delete />}
                >
                  Elimina
                </Button>
              ) : (
                <></>
              )}

              <Button
                id="confirm-new-activity-btn"
                type="submit"
                variant="contained"
                color="success"
                startIcon={<SaveAsTwoTone />}
                disabled={!isValid}
                onClick={() => {
                  !edit
                    ? updateDailyActivities(
                        dailyForm,
                        user,
                        manage,
                        handleClose,
                        navigate
                      )
                    : insertDailyActivities(
                        dailyForm,
                        user,
                        manage,
                        handleClose,
                        navigate
                      );
                }}
              >
                {" "}
                {!edit ? "Aggiorna Intervento" : "Inserisci Intervento"}
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};


export default DailyActivity;
