import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CostCenterContext, LanguageContext } from "../contexts";
import {
  Card,
  CardContent,
  Stack,
  Grid,
  TextField,
  Button,
  Alert,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tab,
} from "@mui/material";
import {
  HelpTwoTone,
  Add,
  Edit,
  Delete,
  CancelTwoTone,
  UpdateTwoTone,
  ArrowBack,
  ArrowBackTwoTone,
} from "@mui/icons-material";
import { useForm, usePagination } from "../hooks";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { DataGrid } from "@mui/x-data-grid";
import { renderHeader } from "../utilities";
import { ROWS_PER_PAGE } from "../hooks/usePagination";
import { ModalForm } from "../components";
import { isRequired, isRequiredNumber } from "../hooks/useForm";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CenterCostsRevenueTab from "./CenterCostsRevenueTab";

const CenterCostsAnalyticsCostTab = ({ id, title }) => {

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  const [pageSize, setPageSize] = usePagination();

  const [openModal, setOpenModal] = useState(false);

  const {
    state: { dictionary },
  } = useContext(LanguageContext);
  const {
    state: { costCenterAnalyticsCosts, analyticCost, factivities },
    setCostCenter,
    addAnalyticsCosts,
    setAnalyticsCostsFromCostCenter,
    deleteAnalyticsCostFromCenter,
    putAnalyticsCostsFromCostCenter,
    setFactitives,
  } = useContext(CostCenterContext);

  const validationsAnalyticsCost = [
    ({ description }) =>
      isRequired(description) || {
        description: "Descrizione Obbligatoria",
      },
    ({ datefrom }) =>
      isRequired(datefrom) || {
        datefrom: "datefrom Obbligatoria",
      },
    ({ dateto }) =>
      isRequired(dateto) || {
        dateto: "dateto Obbligatoria",
      },
    ({ amount }) =>
      isRequiredNumber(amount) || {
        amount: "amount Obbligatoria",
      },
  ];

  const analyticsCostsForm = useForm(analyticCost, validationsAnalyticsCost);

  const analyticsCostsFormEdit = useForm({}, [
    ({ description }) =>
      isRequired(description) || {
        description: "Descrizione Obbligatoria",
      },
    ({ datefrom }) =>
      isRequired(datefrom) || {
        datefrom: "datefrom Obbligatoria",
      },
    ({ dateto }) =>
      isRequired(dateto) || {
        dateto: "dateto Obbligatoria",
      },
    ({ amount }) =>
      isRequiredNumber(amount) || {
        amount: "amount Obbligatoria",
      },
  ]);

  useEffect(() => {
    if (id !== "new") {
      setAnalyticsCostsFromCostCenter(id);
      setCostCenter(id);
      setFactitives();
    }
  }, []);

  const isCreating = id === "new";

  const cardTitleAnalytics = isCreating ? "Costi Analitici" : "Costi Analitici";

  const actionColumn = {
    field: "",
    renderHeader: renderHeader(dictionary["actions"]),
    headerName: dictionary["actions"],
    headerAlign: "right",
    align: "right",
    flex: 1,
    renderCell: (params) => (
      <Stack direction="row" spacing={1}>
        <Tooltip title={dictionary["edit"]} placement="top-start">
          <IconButton
            variant="outlined"
            color="secondary"
            size="small"
            onClick={() => {
              analyticsCostsFormEdit.setValues(params.row);
              setOpenModal(!openModal);
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={dictionary["delete"]} placement="top-start">
          <IconButton
            variant="outlined"
            color="error"
            size="small"
            onClick={() =>
              deleteAnalyticsCostFromCenter(id, params.row.id, dictionary)
            }
          >
            <Delete fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
    ),
  };

  const columns = [
    {
      field: "description",
      renderHeader: renderHeader("Descrizione"),
      headerName: "Descrizione",
      flex: 1,
    },
    {
      field: "datefrom",
      renderHeader: renderHeader("datefrom"),
      headerName: "Dal",
      flex: 1,
    },
    {
      field: "dateto",
      renderHeader: renderHeader("dateto"),
      headerName: "Al",
      flex: 1,
    },
    {
      field: "amount",
      renderHeader: renderHeader("Importo"),
      headerName: "Importo",
      renderCell: ({ row }) => (
        <Typography> € {row.amount.toFixed(2).replace(".", ",")}</Typography>
      ),
      headerAlign: "right",
      align: "right",
    },
    actionColumn,
  ];

  const handleCloseModal = () => {
    setOpenModal(!openModal);
  };
  return (
    <>
       {openModal && (
        <ModalForm openModal={openModal} handleCloseModal={handleCloseModal}>
          <Grid component="form" container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                id="descrizione"
                name="descrizione"
                sx={{ textTransform: "capitalize" }}
                label={"Descrizione"}
                error={Boolean(
                  analyticsCostsFormEdit.touched.description &&
                    analyticsCostsFormEdit.errors.description
                )}
                helperText={
                  Boolean(
                    analyticsCostsFormEdit.touched.description &&
                      analyticsCostsFormEdit.errors.description
                  )
                    ? analyticsCostsFormEdit.errors.description
                    : analyticsCostsFormEdit.errors.description
                    ? "* " + "Descrizione Obbligatoria"
                    : "* " + "Descrizione Obbligatoria"
                }
                type="text"
                variant="outlined"
                size="small"
                value={analyticsCostsFormEdit.values.description || ""}
                onChange={(e) =>
                  analyticsCostsFormEdit.changeHandler(
                    "description",
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DesktopDatePicker
                label={"Dal"}
                inputFormat="DD/MM/yyyy"
                value={analyticsCostsFormEdit.values.datefrom || null}
                onChange={(newValue) =>
                  analyticsCostsFormEdit.changeHandler(
                    "datefrom",
                    newValue?.format("yyyy-MM-DD")
                  )
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(
                      analyticsCostsFormEdit.touched.datefrom &&
                        analyticsCostsFormEdit.errors.datefrom
                    )}
                    helperText={
                      Boolean(
                        analyticsCostsFormEdit.touched.datefrom &&
                          analyticsCostsFormEdit.errors.datefrom
                      )
                        ? analyticsCostsFormEdit.errors.datefrom
                        : analyticsCostsFormEdit.errors.datefrom
                        ? "* " + "Data Obbligatoria"
                        : "* " + "Data Obbligatoria"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <DesktopDatePicker
                label={"Al"}
                inputFormat="DD/MM/yyyy"
                value={analyticsCostsFormEdit.values.dateto || ""}
                onChange={(newValue) =>
                  analyticsCostsFormEdit.changeHandler(
                    "dateto",
                    newValue?.format("yyyy-MM-DD")
                  )
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(
                      analyticsCostsFormEdit.touched.dateto &&
                        analyticsCostsFormEdit.errors.dateto
                    )}
                    /* helperText={touched.dob && errors.dob} */
                    helperText={
                      Boolean(
                        analyticsCostsFormEdit.touched.dateto &&
                          analyticsCostsFormEdit.errors.dateto
                      )
                        ? analyticsCostsFormEdit.errors.dateto
                        : analyticsCostsFormEdit.errors.dateto
                        ? "* " + "Data Obbligatoria"
                        : "* " + "Data Obbligatoria"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">
                  Tipo di costo
                </InputLabel>
                <Select
                  id="demo-simple-select"
                  value={analyticsCostsFormEdit.values.idtype || " "}
                  label="Tipo di costo"
                  onChange={(e) =>
                    analyticsCostsFormEdit.changeHandler(
                      "idtype",
                      e.target.value
                    )
                  }
                >
                  <MenuItem value={" "}>...Seleziona tipo di costo</MenuItem>
                  {factivities.map((data, index) => {
                    return (
                      <MenuItem key={index} value={data.id}>
                        {data.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                id="nome"
                name="import"
                sx={{ textTransform: "capitalize" }}
                label={"Importo"}
                error={Boolean(
                  analyticsCostsFormEdit.touched.amount &&
                    analyticsCostsFormEdit.errors.amount
                )}
                helperText={
                  Boolean(
                    analyticsCostsFormEdit.touched.amount &&
                      analyticsCostsFormEdit.errors.amount
                  )
                    ? analyticsCostsFormEdit.errors.amount
                    : analyticsCostsFormEdit.errors.amount
                    ? "* " + "Importo Obbligatorio"
                    : "* " + "Importo Obbligatorio"
                }
                type="number"
                variant="outlined"
                size="small"
                value={analyticsCostsFormEdit.values.amount || ""}
                onChange={(e) =>
                  analyticsCostsFormEdit.changeHandler(
                    "amount",
                    parseFloat(e.target.value)
                  )
                }
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Stack direction={"row"} spacing={2} justifyContent={"flex-end"}>
                <Button
                  startIcon={<CancelTwoTone />}
                  color="warning"
                  variant="outlined"
                  onClick={() => {
                    handleCloseModal();
                  }}
                  sx={{ textTransform: "capitalize" }}
                >
                  {"Annulla"}
                </Button>
                <Button
                  startIcon={<UpdateTwoTone />}
                  color="secondary"
                  variant="outlined"
                  disabled={!analyticsCostsFormEdit.isValid}
                  onClick={() => {
                    putAnalyticsCostsFromCostCenter(
                      analyticsCostsFormEdit,
                      id,
                      navigate,
                      false,
                      dictionary
                    );
                    handleCloseModal();
                  }}
                  sx={{ textTransform: "capitalize" }}
                >
                  {"Aggiorna Costo"}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </ModalForm>
      )}
      
          <Card elevation={0}>
            <Alert icon={<HelpTwoTone fontSize="inherit" />} severity="warning">
              I campi contrassegnati con il simbolo * sono obbligatori
            </Alert>
            <CardContent>
              <Grid component="form" container spacing={2}>
                <Grid item xs={12} md={6} lg={2}>
                  <TextField
                    fullWidth
                    id="descrizione"
                    name="descrizione"
                    sx={{ textTransform: "capitalize" }}
                    label={"Descrizione"}
                    error={Boolean(
                      analyticsCostsForm.touched.description &&
                        analyticsCostsForm.errors.description
                    )}
                    helperText={
                      Boolean(
                        analyticsCostsForm.touched.description &&
                          analyticsCostsForm.errors.description
                      )
                        ? analyticsCostsForm.errors.description
                        : analyticsCostsForm.errors.description
                        ? "* " + "Descrizione Obbligatoria"
                        : "* " + "Descrizione Obbligatoria"
                    }
                    type="text"
                    variant="outlined"
                    size="small"
                    value={analyticsCostsForm.values.description || ""}
                    onChange={(e) =>
                      analyticsCostsForm.changeHandler(
                        "description",
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <DesktopDatePicker
                    label={"Dal"}
                    inputFormat="DD/MM/yyyy"
                    value={
                      analyticsCostsForm.values.datefrom == ""
                        ? null
                        : analyticsCostsForm.values.datefrom
                    }
                    onChange={(newValue) => {
                      analyticsCostsForm.changeHandler(
                        "datefrom",
                        newValue?.format("yyyy-MM-DD")
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        fullWidth
                        {...params}
                        sx={{ textTransform: "capitalize" }}
                        error={Boolean(
                          analyticsCostsForm.touched.datefrom &&
                            analyticsCostsForm.errors.datefrom
                        )}
                        /* helperText={touched.dob && errors.dob} */
                        helperText={
                          Boolean(
                            analyticsCostsForm.touched.datefrom &&
                              analyticsCostsForm.errors.datefrom
                          )
                            ? analyticsCostsForm.errors.datefrom
                            : analyticsCostsForm.errors.datefrom
                            ? "* " + "Data Obbligatoria"
                            : "* " + "Data Obbligatoria"
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <DesktopDatePicker
                    label={"Al"}
                    inputFormat="DD/MM/yyyy"
                    value={
                      analyticsCostsForm.values.dateto == ""
                        ? null
                        : analyticsCostsForm.values.dateto
                    }
                    onChange={(newValue) =>
                      analyticsCostsForm.changeHandler(
                        "dateto",
                        newValue?.format("yyyy-MM-DD")
                      )
                    }
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        fullWidth
                        {...params}
                        sx={{ textTransform: "capitalize" }}
                        error={Boolean(
                          analyticsCostsForm.touched.dateto &&
                            analyticsCostsForm.errors.dateto
                        )}
                        /* helperText={touched.dob && errors.dob} */
                        helperText={
                          Boolean(
                            analyticsCostsForm.touched.dateto &&
                              analyticsCostsForm.errors.dateto
                          )
                            ? analyticsCostsForm.errors.dateto
                            : analyticsCostsForm.errors.dateto
                            ? "* " + "Data Obbligatoria"
                            : "* " + "Data Obbligatoria"
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <TextField
                    fullWidth
                    select
                    id="demo-simple-select"
                    value={analyticsCostsForm.values.idtype || " "}
                    label="Tipo di costo"
                    size="small"
                    onChange={(e) =>
                      analyticsCostsForm.changeHandler("idtype", e.target.value)
                    }
                  >
                    <MenuItem value={" "}>...Seleziona tipo di costo</MenuItem>
                    {factivities.map((data, index) => {
                      return (
                        <MenuItem key={index} value={data.id}>
                          {data.description}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                  <TextField
                    fullWidth
                    id="nome"
                    name="import"
                    sx={{ textTransform: "capitalize" }}
                    label={"Importo"}
                    error={Boolean(
                      analyticsCostsForm.touched.amount &&
                        analyticsCostsForm.errors.amount
                    )}
                    helperText={
                      Boolean(
                        analyticsCostsForm.touched.amount &&
                          analyticsCostsForm.errors.amount
                      )
                        ? analyticsCostsForm.errors.amount
                        : analyticsCostsForm.errors.amount
                        ? "* " + "Importo Obbligatorio"
                        : "* " + "Importo Obbligatorio"
                    }
                    type="number"
                    variant="outlined"
                    size="small"
                    value={analyticsCostsForm.values.amount || ""}
                    onChange={(e) =>
                      analyticsCostsForm.changeHandler(
                        "amount",
                        parseFloat(e.target.value)
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={2}>
                  <Button
                    startIcon={<Add />}
                    color="secondary"
                    variant="outlined"
                    disabled={!analyticsCostsForm.isValid}
                    onClick={() => {
                      addAnalyticsCosts(analyticsCostsForm, id, navigate);
                    }}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {"Aggiungi Costo"}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <DataGrid
                    autoHeight
                    disableColumnMenu
                    disableSelectionOnClick
                    rows={costCenterAnalyticsCosts === undefined ? []: costCenterAnalyticsCosts}
                    columns={columns}
                    pagination
                    onPageSizeChange={setPageSize}
                    pageSize={pageSize}
                    rowsPerPageOptions={ROWS_PER_PAGE}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
    </>
  );
};

export default CenterCostsAnalyticsCostTab;
