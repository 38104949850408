import { useEffect, useState } from 'react';

function generateUUID() {
    // Public Domain/MIT
    let d = new Date().getTime(); // Timestamp
    let d2 = (performance && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        let r = Math.random() * 16; // random number between 0 and 16
        if (d > 0) {
            // Use timestamp until depleted
            // eslint-disable-next-line no-bitwise
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            // Use microseconds since page-load if supported
            // eslint-disable-next-line no-bitwise
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        // eslint-disable-next-line no-bitwise
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
}

export const openModal = (title, message, type, actions, size = "xs", duration = null) => {
    dispatchEvent(new CustomEvent('modal', { detail: { title, message, size, type, actions, duration } }));
};

const useModals = () => {
    const [modals, setModals] = useState([]);

    // eslint-disable-next-line no-underscore-dangle
    const _closeModal = (_modal, id) => {
        const idx = _modal.findIndex((item) => item.id === id);
        if (idx >= 0) {
            _modal.splice(idx, 1);
        }
        return _modal;
    };

    const handleModal = (e) => {
        const { title, message, type, actions, duration, size } = e.detail;

        // eslint-disable-next-line no-underscore-dangle
        const _modals = [...modals];

        const id = generateUUID();
        setModals([
            ..._modals,
            {
                title,
                message,
                type,
                size,
                id,
                actions: actions(() => closeModal(id))
            }
        ]);
        if (duration) {
            setTimeout(
                () => setModals((_modals) => _closeModal([..._modals], id)),
                duration * 1000
            );
        }
    };

    const closeModal = (id) => {
        setModals((_modals) => _closeModal([..._modals], id));
    };

    useEffect(() => {
        window.addEventListener('modal', handleModal);
        return () => window.removeEventListener('modal', handleModal);
    }, [modals]);

    return [modals, closeModal];
};

export default useModals;
