import Main from "./Main";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";
import {
  AuthProvider,
  ClockingsProvider,
  ThemeProvider,
  ReportsProvider,
  AnomaliesProvider,
  CostCenterProvider,
  PermitsProvider,
  HolidaysProvider,
  UserProvider,
  RequestsProvider,
  StatisticsProvider,
  JustificationsProvider,
  CalendarProvider,
  ManageRequestsProvider,
  ManageJustificationsProvider,
  LanguageContext,
  BusinesstripsProvider,
  DailyActivitiesProvider,
} from "./contexts";
import { useContext } from "react";

function App() {
  const {
    state: { muiTranslation },
  } = useContext(LanguageContext);
  let theme = createTheme();
  theme = createTheme(
    {
      palette: {
        primary: {
          dark: "#49000b",
          main: "#690011",
          light: "#873340",
        },
        secondary: {
          dark: "#036e6b",
          main: "#3a8a99",
          light: "#37b1ae",
        },
        requests: {
          dark: "#00283c",
          main: "#185066",
          light: "#4a7c94",
          contrastText: "#fff",
        },
        reports: {
          dark: "#006f6c",
          main: "#059e9a",
          light: "#56d0cb",
          contrastText: "#fff",
        },
        anomalies: {
          dark: "#73001b",
          main: "#a90641",
          light: "#e04a6b",
          contrastText: "#fff",
        },
        collaborators: {
          dark: "#26044f",
          light: "#8359ac",
          main: "#542e7c",
        },
        success: {
          dark: "#2e8269",
          main: "#329970",
          light: "#67c7ab",
          contrastText: "#fff",
        },
        warning: {
          dark: "#b28704",
          main: "#E0B812",
          light: "#ffcd38",
        },
        error: {
          dark: "#b80000",
          main: "#f40400",
          light: "#ff5834",
          contrastText: "#fff",
        },
        // FOR CALENDAR
        clockings: {
          dark: "#923c09",
          main: "#D1570D",
          light: "#da783d",
          contrastText: "#fff",
        },
        events: {
          dark: "#315319",
          main: "#477725",
          light: "#6b9250",
          contrastText: "#fff",
        },
        businesstrips: {
          dark: "#D88AE6",
          main: "#B473BF",
          light: "#EF99FF",
          contrastText: "#fff",
        },
      },
      typography: {
        button: {
          textTransform: "none",
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              height: "40px",
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              "&.in-navbar": {
                "&.Mui-selected": {
                  borderRadius: 4,
                  backgroundColor: "#49000b",
                  "&:hover": {
                    backgroundColor: "#873340",
                  },
                },
              },
            },
          },
        },
        // FOR CALENDAR
        MuiCalendarOrClockPicker: {
          styleOverrides: {
            root: {
              "& > div": {
                margin: "0px !important",
                maxHeight: "unset",
                width: "100%!important",
              },
            },
          },
        },
        MuiPickerStaticWrapper: {
          styleOverrides: {
            root: {
              "& > div > div": {
                width: "100% !important",
                overflowX: "unset !important",
                margin: "0px !important",
                [theme.breakpoints.up("md")]: {
                  maxHeight: "1500px !important",
                },
              },
            },
          },
        },
        MuiCalendarPicker: {
          styleOverrides: {
            root: {
              "& > div > div > div > span": {
                width: "100% !important",
              },
              width: "100% !important",
              [theme.breakpoints.up("md")]: {
                maxHeight: "1500px !important",
              },
            },
            viewTransitionContainer: {
              "& div[role=row]": {
                [theme.breakpoints.up("md")]: {
                  "& div": {
                    width: "113px",
                  },
                },
                [theme.breakpoints.up("lg")]: {
                  "& div": {
                    width: "138px",
                  },
                },
                "& div[role=gridcell]": {
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                },
                width: "100%",
                justifyContent: "space-around !important",
              },
            },
          },
        },
        MuiDayPicker: {
          styleOverrides: {
            weekContainer: {
              [theme.breakpoints.up("md")]: {
                "& div": {
                  width: "113px"
                },
              },
              [theme.breakpoints.up("lg")]: {
                "& div": {
                  width: "138px"
                },
              },
            }
          }
        },
        // END FOR CALENDAR
        MuiDataGrid: {
          styleOverrides: {
            root: {
              border: "0px",
            },
          },
        },
        MuiCardHeader: {
          styleOverrides: {
            root: {
              color: "black",
            },
          },
        },
        MuiDrawer: {
          defaultProps: {
            PaperProps: {
              elevation: 4,
            },
          },
        },
        MuiBreadcrumbs: {
          styleOverrides: {
            root: {
              color: "black",
            },
          },
        },
      },
    },
    muiTranslation.grid,
    muiTranslation.base
  );
  return (
    <DailyActivitiesProvider>
      <BusinesstripsProvider>
        <ManageJustificationsProvider>
          <ManageRequestsProvider>
            <RequestsProvider>
              <StatisticsProvider>
                <HolidaysProvider>
                  <PermitsProvider>
                    <ClockingsProvider>
                      <CostCenterProvider>
                        <AnomaliesProvider>
                          <ReportsProvider>
                            <JustificationsProvider>
                              <CalendarProvider>
                                <UserProvider>
                                  <ThemeProvider>
                                    <MuiThemeProvider theme={theme}>
                                      <AuthProvider>
                                        <Main />
                                      </AuthProvider>
                                    </MuiThemeProvider>
                                  </ThemeProvider>
                                </UserProvider>
                              </CalendarProvider>
                            </JustificationsProvider>
                          </ReportsProvider>
                        </AnomaliesProvider>
                      </CostCenterProvider>
                    </ClockingsProvider>
                  </PermitsProvider>
                </HolidaysProvider>
              </StatisticsProvider>
            </RequestsProvider>
          </ManageRequestsProvider>
        </ManageJustificationsProvider>
      </BusinesstripsProvider>
    </DailyActivitiesProvider>
  );
}

export default App;
