import React, { useContext, useEffect } from "react";
import { UserContext } from "../contexts";
import { ApproversList } from "../components";

const ApproverCollaborators = () => {
  const {
    state: { collaborators },
    setCollaborators,
  } = useContext(UserContext);

  useEffect(() => {
    setCollaborators();
  }, []);

  return (
    <ApproversList approvers={collaborators} />
  )
}

export default ApproverCollaborators