import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Collapse,
  CardActionArea,
  CardActions,
  Card,
  CardContent,
  CardHeader
} from '@mui/material';
import React, { useState } from 'react'

const CollapsableWindow = ({ children, initialState = false, collapsedSize = 300  }) => {

  const [open, setOpen] = useState(initialState);

  return (
    <Card>
      <Collapse in={open} collapsedSize={collapsedSize}>
        <CardContent sx={{ p: 0, pb: theme => theme.spacing(1) }}>
          {children}
        </CardContent>
      </Collapse>
      <CardActionArea sx={{ py: theme => theme.spacing(1), justifyContent: "center", display: "flex" }} onClick={() => setOpen(_ => !_)}>
        { open
          ? <KeyboardArrowUp />
          : <KeyboardArrowDown />
        }
      </CardActionArea>
    </Card>
  )
}

export default CollapsableWindow;