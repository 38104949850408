import React, { useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CostCenterContext, LanguageContext } from "../contexts";
import {
  Box,
  Tab,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CenterCostsRevenueTab from "./CenterCostsRevenueTab";
import CenterCostsAnalyticsCostTab from "./CenterCostsAnalyticsCostTab";

const UserCostAnalytics = () => {
  const { id, rights } = useParams();

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();

  const {
    state: { dictionary },
  } = useContext(LanguageContext);
  
  const {
    state: {titleCenterCost},
  } = useContext(CostCenterContext);

  return (
    <>
      <TabContext value={value}>
        <Typography fontSize={20} fontWeight={"bold"}>{titleCenterCost}</Typography>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Costi" value="1" />

            {rights.split("-").includes("3") ? (
              <Tab label="Ricavi" value="2" />
            ) : (
              <></>
            )}
          </TabList>
        </Box>
        <TabPanel value="1">
          <CenterCostsAnalyticsCostTab id={id} />
        </TabPanel>
        <TabPanel value="2">
          <CenterCostsRevenueTab id={id} />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default UserCostAnalytics;
