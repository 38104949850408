import React, {useState, useRef, useEffect, useContext, useCallback} from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import {
  useCostCenters,
  useJustificationsTypes,
  useDictionary,
  useForm,
} from "../hooks";
import moment from "moment";
import { openModal } from "../hooks/useModals";
import { isRequired } from "../hooks/useForm";
import { AnomaliesContext } from "../contexts";
import {
  commissionsValidity,
  renderHeader,
  translateMinutesInTime,
  translateTimeInMinutes,
  uuidv4,
} from "../utilities";

const AnomaliesForm = ({ anomaly, formRef, view, totMin }) => {
  const getTotJustifiedMinutes = (newJustifications) => {
    let _totMinutes = 0;
    newJustifications.forEach((next) => {
      _totMinutes += next.minutescount;
    });
    return _totMinutes;
  };
  const [addedJustifications, setAddedJustifications] = useState([]);
  const [totMinutes, setTotMinutes] = useState(getTotJustifiedMinutes(anomaly.justifications));
  const dictionary = useDictionary();
  const {minutescount: maxMinutesToCompile, datetimeto} = anomaly;

  const costcenters = useCostCenters();
  const justificationsTypes = useJustificationsTypes();

  /* const filterCommissions = commissionsValidity(costcenters, datetimeto).filter(
    (comm) => !addedJustifications.find((_comm) => _comm.id === comm.id)
  ); */
  const getCommissionById = (id) => {
    let test = costcenters.find((costcenter) => costcenter.id === id)
    return  test
  };
  useEffect(() => {
    setAddedJustifications(
      anomaly.justifications.filter((x) => x.status !== "NOT_APPROVED").map(({costcenter, typeid, minutescount, notes, _uid}) => ({
        notes,
        minutescount,
        costcenter: costcenter,
        motivation: justificationsTypes.find((_) => _.id === typeid),
        _uid,
      }))
    );
  }, []);
  const validJustificationsTypes = justificationsTypes.filter((just) => just.validforeventidtype === anomaly.typeid);

  const INITIAL_STATE = {
    minutescount: maxMinutesToCompile,
    motivation: "",
    costcenter: "",
    notes: "",
  };
  const getMotivationById = (id) => {
    return validJustificationsTypes.find((motivation) => motivation.id === id) || {};
  };
  const validations = [
    ({minutescount}) =>
      minutescount + totMinutes <= maxMinutesToCompile || {
        minutescount: dictionary["anomalies"]["errors"]["timeExceeded"],
      },
    ({motivation}) =>
      isRequired(motivation) || {
        motivation: dictionary["anomalies"]["errors"]["motivationRequired"],
      },
    ({costcenter, motivation}) =>
      getMotivationById(motivation).needtimereport &&
      (isRequired(costcenter) || {
        costcenter: "Centro di Costo obbligatorio",
      }),
  ];
  const {
    values: anomalyForm,
    changeHandler,
    errors,
    touched,
    isValid,
    setValues,
    reset,
  } = useForm(INITIAL_STATE, validations);
  useEffect(() => {
    if (!getMotivationById(anomalyForm.motivation).needtimereport) {
      changeHandler("costcenter", "");
    }
  }, [anomalyForm.motivation]);

  const setJustifiedMinutes = (newJustifications) => {
    setTotMinutes(getTotJustifiedMinutes(newJustifications));
  };
  const setNewJustifications = (newJustifications) => {
    setJustifiedMinutes(newJustifications);
    setAddedJustifications(newJustifications);
    formRef.justifications = newJustifications;
  };
  const handleAddJustification = () => {
    const {
      minutescount,
      notes,
      motivation: motivationId,
      costcenter: commissionId,
    } = anomalyForm;
    const costcenter = getCommissionById(commissionId);
    const motivation = getMotivationById(motivationId);
    const newJustifications = [
      ...addedJustifications,
      {
        minutescount,
        notes,
        motivation,
        costcenter,
        _uid: uuidv4(),
      },
    ];
    reset();
    setNewJustifications(newJustifications);
  };
  const deleteJustification = (justification) => {
    setTimeout(() =>
      setNewJustifications(
        addedJustifications.filter((aj) => aj._uid !== justification._uid)
      )
    );
  };

  const columns = [
    {
      field: "minutescount",
      headerName: dictionary["hours"],
      renderHeader: renderHeader(dictionary["hours"]),
      renderCell: ({ value }) => translateMinutesInTime(value),
    },
    {
      field: "motivation",
      headerName: dictionary["anomalies"]["motivation"],
      renderHeader: renderHeader(dictionary["anomalies"]["motivation"]),
      renderCell: ({ value }) => value.description,
      flex: 1,
    },
    {
      field: "costcenter",
      headerName: "Centro di Costo",
      renderHeader: renderHeader("Centro di costo"),
      renderCell: ({ value }) => value?.description,
      flex: 1,
    },
    // {
    //   field: "notes",
    //   headerName: dictionary["notes"],
    //   renderHeader: renderHeader(dictionary["notes"]),
    // },
    {
      field: "notes",
      headerName: dictionary["notes"],
      flex: 1,
      cellClassName: 'super-app-theme--header',
      renderHeader: renderHeader(dictionary["notes"]),
    },
    {
      field: "",
      headerName: dictionary["actions"],
      renderHeader: renderHeader(dictionary["actions"]),
      renderCell: ({ row }) => {
        let isApprovedByAdmin =row.notes.includes("ADMIN APPROVED")
        if (!view && !isApprovedByAdmin && costcenters.length > 0) {
          return (
            <Stack direction="row" spacing={1}>
              <Tooltip title={dictionary["delete"]} placement="top-start">
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => deleteJustification(row)}
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        }
      },
    },
  ];
  const getDisabled = () => {
    if (anomaly.typeid !== 6) {
      return maxMinutesToCompile === totMinutes;
    }
    return addedJustifications.length >= 1;
  };
  const getRowId = useCallback((row) => row._uid, []);
  // needtimereport if true need costcenter (overtime only at the moment)
  let hasMatch =
    anomaly.justifications.length > 0
      ? anomaly.justifications.some(function (value) {
          return value.status == "JUSTIFIED"; //"APPROVED";
        })
      : false;
  totMin = totMinutes;
  // if (!hasMatch || totMinutes > 0) {
  return (
    <Grid container spacing={2} sx={{ mt: (theme) => theme.spacing(1) }}>
      <Grid item xs={12} md={12} lg={12}>
        <DataGrid
          getRowId={getRowId}
          rows={addedJustifications}
          autoHeight
          density="compact"
          disableSelectionOnClick
          columns={columns}
          disableColumnMenu
          hideFooter
          pageSize={100}
          rowHeight={100}
        />
      </Grid>
      {((maxMinutesToCompile > totMinutes) || (anomaly.typeid === 6)) && (
        <React.Fragment>
          {anomaly.typeid !== 6 && (
            <Grid item xs={12} md={12} lg={4}>
              <TextField
                fullWidth
                size="small"
                id="time"
                error={Boolean(touched.minutescount && errors.minutescount)}
                helperText={touched.minutescount && errors.minutescount}
                label={dictionary["hours"]}
                type="time"
                value={translateMinutesInTime(anomalyForm.minutescount)}
                onChange={(e) => {
                  changeHandler("minutescount", translateTimeInMinutes(e.target.value));
                }}
                step="00:15"
              />
            </Grid>
          )}
          <Grid item xs={12} md={12} lg={8}>
            <FormControl error={Boolean(touched.motivation && errors.motivation)} fullWidth size="small">
              <InputLabel id="eventType-select">{dictionary["anomalies"]["motivation"]}</InputLabel>
              <Select
                id="eventType-select"
                value={anomalyForm.motivation || ""}
                onChange={(e) => changeHandler("motivation", e.target.value)}
                label={dictionary["anomalies"]["motivation"]}>
                <MenuItem value="">{dictionary["anomalies"]["noMotivation"]}</MenuItem>
                {validJustificationsTypes.map((just, i) => (
                  <MenuItem key={i} value={just.id}>
                    {just.description}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(touched.motivation && errors.motivation) && (
                <FormHelperText>{touched.motivation && errors.motivation}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <FormControl
              error={Boolean(touched.costcenter && errors.costcenter)}
              fullWidth
              size="small"
            >
              <InputLabel id="eventType-select">
                {"Centro di costo"}
              </InputLabel>
              <Select
                id="eventType-select"
                value={anomalyForm.costcenter || ""}
                disabled={
                  !anomalyForm.motivation ||
                  !getMotivationById(anomalyForm.motivation).needtimereport
                }
                onChange={(e) => changeHandler("costcenter", e.target.value)}
                label={"Centro di Costo"}
              >
                <MenuItem value="">
                  {"Nessun Centro di costo"}
                </MenuItem>
                {costcenters.map((costcenter, i) => (
                  <MenuItem key={i} value={costcenter.id}>
                    {costcenter.description}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(touched.costcenter && errors.costcenter) && (
                <FormHelperText>
                  {touched.costcenter && errors.costcenter}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              size="small"
              fullWidth
              autoComplete="note"
              id="note"
              label={dictionary["notes"]}
              variant="outlined"
              value={anomalyForm.notes || ""}
              onChange={(e) => changeHandler("notes", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Stack direction="row" spacing={2}>
              <Button
                color="success"
                size="small"
                disabled={!isValid || getDisabled()}
                variant="contained"
                onClick={handleAddJustification}
              >
                {dictionary["add"]}
              </Button>
            </Stack>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
  // } else {
  //   return (
  //     <Grid container spacing={2} sx={{ mt: (theme) => theme.spacing(1) }}>
  //       <Grid item xs={12} md={12} lg={12}>
  //         <DataGrid
  //           getRowId={getRowId}
  //           rows={addedJustifications}
  //           autoHeight
  //           density="compact"
  //           disableSelectionOnClick
  //           columns={columns}
  //           disableColumnMenu
  //           hideFooter
  //           pageSize={100}
  //         />
  //       </Grid>
  //     </Grid>
  //   );
  // }
};

const AnomaliesModal = ({ anomaly, user, searchForm, justificationTypes }) => {
  const dictionary = useDictionary();
  const { justificationAnomalies } = useContext(AnomaliesContext);
  const handleAddClocking = () => {
    const form = {};
    //let isNotApproved = searchForm.status === "JUSTIFIED" ? true : false;
    let isNotApproved = false
    let totMinutes = 0
    openModal(
      dictionary["anomalies"]["compile"],
      <AnomaliesForm formRef={form} anomaly={anomaly} view={isNotApproved} totMin={totMinutes} />,
      undefined,
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            color="success"
            size="small"
            variant="contained"
            disabled={isNotApproved}
            onClick={() => {
              justificationAnomalies(
                anomaly,
                form,
                searchForm,
                justificationTypes
              );
              closeModal();
            }}
          >
            {dictionary["confirm"]}
          </Button>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={closeModal}
          >
            {dictionary["cancel"]}
          </Button>
        </Stack>
      ),
      "md"
    );
  };

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title={dictionary["anomalies"]["compile"]} placement="top-start">
        <IconButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => handleAddClocking()}
        >
          <Edit fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default AnomaliesModal;
