import React, { useContext, useEffect } from "react";
import { JustificationsContext } from "../contexts";

const useJustificationsTypes = (mini = false) => {
  const {
    state: { justificationsTypes },
    setJustificationsType,
  } = useContext(JustificationsContext);

  useEffect(() => {
    if (!mini) {
      setJustificationsType();
    }
  }, []);

  return justificationsTypes;
};

export default useJustificationsTypes;
