import { useState, useEffect } from 'react';
import { LoadingBackdrop } from '../components';

const useLoading = () => {
    const [isLoading, setLoading] = useState([]);

    useEffect(() => {
        const doLoading = (event) => {
            setLoading((state)=> event.detail ? [...state, event.detail] : state.slice(0,state.length-1))
        }
        window.addEventListener('change_loading', doLoading);

        return () => {
            window.removeEventListener('change_loading', doLoading);
        }
    }, []);

    return [isLoading, LoadingBackdrop];
};

export default useLoading;