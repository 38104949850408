import createDataContext from "./createContext";
import { BaseService, UsersService } from "../api";
import moment from "moment";
import { openModal } from "../hooks/useModals";
import { Button, Grid, Stack } from "@mui/material";
import { AddBusinesstrip } from "../components";
import { sendNotification } from "../hooks/useNotification";

const SET_BUSINESS_TRIP = "set_business_trip";

const INITIAL_STATE = {
    businessTrips: [],
};

const businesstripsReducer = (state, action) => {
    switch (action.type) {
        case SET_BUSINESS_TRIP: {
            const { businessTrips } = action.payload;
            return { ...state, businessTrips };
        }
        default: {
            return state;
        }
    }
};

const setBusinesstrips = dispatch => async (userid) => {
    const today = moment(new Date());
    const minDate = moment(today).subtract(15, 'days');
    const response = await UsersService.get(`${userid}/businesstrip/${minDate.format("YYYY-MM-DD")}/${today.format("YYYY-MM-DD")}`);
    dispatch({ type: SET_BUSINESS_TRIP, payload: { businessTrips: response.data.businesstrips } });
};

const addBusinesstrip = dispatch => async (userid, dictionary) => {
    const action = async (form, closeModal) => {
        if (form.isValid) {
            await UsersService.post(`/${userid}/businesstrip`, {
                datefrom: form.datefrom,
                dateto: form.dateto,
                userid,
                request_type: "BUSINESSTRIP",
                status: "NEW",
                days: form.days,
                notes: form.note
            })
            sendNotification(
                dictionary["businesstrips"]["addSuccess"]["title"],
                dictionary["businesstrips"]["addSuccess"]["message"],
                "success",
                5
            );
            setBusinesstrips(dispatch)(userid);
            closeModal();
        } else {
            sendNotification(
                dictionary["businesstrips"]["form"]["errors"]["invalid"]["title"],
                dictionary["businesstrips"]["form"]["errors"]["invalid"]["message"],
                "error",
                5
            );
        }

    };
    const form = { note: '', datefrom: '', dateto: '', days: 1 };
    openModal(
        dictionary["businesstrips"]["request"],
        <AddBusinesstrip formRef={form} />,
        "info",
        closeModal => (
            <Stack direction="row" spacing={2}>
                <Button
                    color="success"
                    variant="contained"
                    size="small"
                    onClick={async () => {
                        await action(form, closeModal);
                    }}>
                    {dictionary["confirm"]}
                </Button>
                <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={closeModal}
                >
                    {dictionary["cancel"]}
                </Button>
            </Stack>
        ));
};
const deleteBusinesstrip = dispatch => async (request, dictionary, setRequests, INITIAL_STATE) => {
    const action = async (closeModal) => {
        await BaseService.put(`/requests/${request.id}/NOT_APPROVED`)
        setRequests(INITIAL_STATE)
        closeModal()
    };
    openModal(
        dictionary["businesstrips"]["delete"],
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
                {dictionary["businesstrips"]["form"]["from"]}{": "}{moment(request.datefrom).format("DD/MM/YYYY")}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                {dictionary["businesstrips"]["form"]["to"]}{": "}{moment(request.dateto).format("DD/MM/YYYY")}
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                {dictionary["businesstrips"]["days"]}{": "}{request.days}
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                {dictionary["note"]}{": "}{request.notes}
            </Grid>
        </Grid>,
        "warning",
        closeModal => (
            <Stack direction="row" spacing={2}>
                <Button
                    color="success"
                    variant="contained"
                    size="small"
                    onClick={async () => {
                        await action(closeModal);
                    }}>
                    {dictionary["confirm"]}
                </Button>
                <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={closeModal}
                >
                    {dictionary["cancel"]}
                </Button>
            </Stack>
        )
    )
};
export const { Provider, Context } = createDataContext(
    businesstripsReducer,
    {
        setBusinesstrips,
        addBusinesstrip,
        deleteBusinesstrip
    }, // actions
    INITIAL_STATE // initial state
);
