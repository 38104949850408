import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react'
import { useDictionary } from '../hooks';

const RequestsCategory = ({ rows, category, columns }) => {

    const [open, setOpen] = useState(false);
    const dictionary = useDictionary();

    return (
        <Accordion 
            disableGutters
            expanded={open} 
            onChange={() => setOpen(_ => !_)}
        >
            <AccordionSummary
                sx={{ 
                    color: theme => theme.palette.common.white,
                    backgroundColor: theme => theme.palette.requests.light 
                }}
                expandIcon={<ExpandMore sx={{ color: theme => theme.palette.common.white }} />}
            >
                <Typography 
                    variant="h6" 
                    sx={{ m: 0 }} 
                    fontSize={14}
                >
                    {dictionary["requestStatus"][category]}{" "}({rows.length})
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{ p: 0 }}
            >
                <DataGrid
                    columns={columns}
                    rows={rows}
                    disableColumnSelector
                    density="compact"
                    disableSelectionOnClick
                    disableColumnMenu
                    hideFooter
                    pageSize={100}
                    autoHeight
                />
            </AccordionDetails>
        </Accordion>
    )
}

export default RequestsCategory;