import * as React from "react";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import { SmartButtonTwoTone, StopCircleRounded } from "@mui/icons-material";
import HeaderCollapse from "./HeaderCollapse";
import {
  Alert,
  Box,
  Button,
  CardHeader,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useContext } from "react";
import { DailyActivitiesContext } from "../contexts";
import { useEffect } from "react";
import { useDictionary, useUser } from "../hooks";
import { useRef } from "react";
import DailyActivitiesForm from "./DailyActivitiesForm";
import { openModal } from "../hooks/useModals";
import { red } from "@mui/material/colors";

export default function DailyActivitiesPanel() {
  const {
    state: { dailyActivitiesExecutions },
    startActivities,
    stopActivities,
    activitiesInExecutions,
  } = useContext(DailyActivitiesContext);

  const dictionary = useDictionary();
  const { user, geolocationEnabled } = useUser();

  useEffect(() => {
    activitiesInExecutions(user);
  }, []);

  const notesRef = useRef(null);
  const timeRef = useRef();
  const dateRef = useRef();
  const positionRef = useRef();
  


  const handleStartActClock = (direction, item) => {
    openModal(
      direction === "OUT"
        ? `Termina Intervento`
        : direction === "PAUSE"
        ? `Blocca Intervento`
        : `Inizia Intervento`,
      <DailyActivitiesForm
        title={item.title}
        costcenter={item.costcenter}
        direction={direction}
        geolocationEnabled={geolocationEnabled}
        dateRef={dateRef}
        timeRef={timeRef}
        notesRef={notesRef}
        positionRef={positionRef}
        // lastClocking={lastClocking}
        dictionary={dictionary}
      />,
      null,
      (closeModal) => (
        <Stack direction="row" spacing={2}>
          <Button
            color="success"
            variant="contained"
            size="small"
            onClick={() =>
              direction === "OUT"
                ? stopActivities(
                    dateRef.current,
                    timeRef.current,
                    closeModal,
                    positionRef,
                    user,
                    item,
                    false,
                    true,
                    notesRef.current
                  )
                : direction === "PAUSE"
                ? pauseActivities(
                    dateRef.current,
                    timeRef.current,
                    closeModal,
                    positionRef,
                    user,
                    item
                  )
                : startActivities(
                    dateRef.current,
                    timeRef.current,
                    closeModal,
                    positionRef,
                    user,
                    item
                  )
            }
          >
            {dictionary["confirm"]}
          </Button>
          <Button
            color="error"
            variant="contained"
            size="small"
            onClick={closeModal}
          >
            {dictionary["cancel"]}
          </Button>
        </Stack>
      )
    );
  };

  return (
    <Stack spacing={2} mt={2}>
      <Card>
        <HeaderCollapse
          notificationCount={dailyActivitiesExecutions.length}
          title={"Interventi in corso"}
          Icon={SmartButtonTwoTone}
        />
        {dailyActivitiesExecutions.length > 0 && dailyActivitiesExecutions.map((item, idx) => (
          <Box key={idx}>
            <CardHeader
              action={
                <Box
                  sx={{ position: "relative" }}
                  onClick={() => handleStartActClock("OUT", item)}
                >
                  <IconButton
                    aria-label="play/pause"
                  >
                    <StopCircleRounded
                      sx={{ height: 30, width: 30 }}
                      color="error"
                    />
                  </IconButton>

                  {/* {loading && ( */}
                  <CircularProgress
                    size={34}
                    sx={{
                      color: red[500],
                      position: "absolute",
                      top: 6,
                      left: 6,
                      zIndex: 1,
                    }}
                  />
                  {/*  )} */}
                </Box>
              }
              title={item.title}
              subheader={item.costcenter}
            />
          </Box>
        ))}
        {dailyActivitiesExecutions.length === 0 ? <Alert severity="error">Nessun Intervento in esecuzione</Alert> :<></>}
      </Card>
    </Stack>
  );
}
