import { useEffect, useState } from "react";
import BaseRequest from "../api/Base";

const useEventTypes = (mini = false, onlyView = false) => {
    const [type, setType] = useState([])
    
    useEffect(() => {
        if(!mini || !onlyView) {
            getTypes()
        }
    }, []);

    const getTypes = async ()=>{
        let typesResp = await BaseRequest.get(`entities/JustificationsBO.TEventType`)
        let data = typesResp.data["JustificationsBO.TEventType"]
        setType(data)
    }

  return type;
}

export default useEventTypes;