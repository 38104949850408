import {
  CalendarMonth,
  CalendarViewMonth,
  Refresh,
  Search,
  Today,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { DailyActivitiesTabels, IconBox } from "../components";
import { DailyActivitiesContext } from "../contexts";
import { useDictionary, useForm, useUser, useCostCenters } from "../hooks";
import { isDateGreater, isRequired } from "../hooks/useForm";

const today = new Date();
const todayDate = today.toISOString().split("T")[0];

const DailyActivities = () => {
  const INITIAL_STATE = {
    datetimefrom: todayDate,
    datetimeto: todayDate,
    costcenterid: null,
    terminated: true,
    tostart: true,
    started: true,
  };

  const { user, setUser } = useUser();
  const costcenters = useCostCenters();

  const [filerButton, setFilterButton] = useState({
    today: true,
    week: false,
    month: false,
  });

  const dictionary = useDictionary();
  const validations = [
    ({ datetimefrom, datetimeto }) =>
      isDateGreater(datetimefrom, datetimeto) || {
        datetimefrom: dictionary["reports"]["errors"]["startGreaterEnd"],
      },
    ({ datetimefrom, costcenterid }) =>
      isRequired(datetimefrom) ||
      isRequired(costcenterid !== "ALL" ? costcenterid : null) || {
        datetimefrom: "Data Richiesta",
        costcenterid: "Centro di Costo Richiesto",
      },
  ];

  const {
    state: { dailyActivities },
    getActivities,
  } = useContext(DailyActivitiesContext);

  const {
    values: searchDailyAct,
    changeHandler,
    setValues,
    errors,
    touched,
    isValid,
    reset,
  } = useForm(INITIAL_STATE, validations);

  useEffect(() => {
    getActivities(
      user,
      todayDate,
      todayDate,
      INITIAL_STATE.costcenterid,
      INITIAL_STATE.terminated,
      INITIAL_STATE.tostart,
      INITIAL_STATE.started
    );
  }, []);

  // Get the date seven days from now
  const nextWeek = new Date(today);
  nextWeek.setDate(today.getDate() + 7);
  const nextWeekDate = nextWeek.toISOString().split("T")[0];

  // Get the date one month from now
  const nextMonth = new Date(today);
  nextMonth.setMonth(today.getMonth() + 1);
  const nextMonthDate = nextMonth.toISOString().split("T")[0];

  const ButtonFastFilter = () => {
    return (
      <Grid item container spacing={3}>
        <Grid item xs={12} md={12} lg={4}>
          <Button
            id="today-button"
        fullWidth
            startIcon={<Today />}
            variant={filerButton.today ? "contained" : "outlined"}
            onClick={() => {
              setFilterButton({ today: true, week: false, month: false }),
                setValues({
                  ...searchDailyAct,
                  datetimefrom: todayDate,
                  datetimeto: todayDate,
                });
              getActivities(
                user,
                todayDate,
                todayDate,
                searchDailyAct.costcenterid,
                searchDailyAct.terminated,
                searchDailyAct.tostart,
                searchDailyAct.started
              );
            }}
          >
            {dictionary["dailyactivities"]["showToday"]}
          </Button>
        </Grid>

        <Grid item xs={12} md={12} lg={4}>
          <Button
            id="week-button"
        fullWidth
            startIcon={<CalendarViewMonth />}
            variant={filerButton.week ? "contained" : "outlined"}
            onClick={() => {
              setFilterButton({ today: false, week: true, month: false }),
                setValues({
                  ...searchDailyAct,
                  datetimefrom: todayDate,
                  datetimeto: nextWeekDate,
                });
              getActivities(
                user,
                todayDate,
                nextWeekDate,
                searchDailyAct.costcenterid,
                searchDailyAct.terminated,
                searchDailyAct.tostart,
                searchDailyAct.started
              );
            }}
          >
            {dictionary["dailyactivities"]["showWeek"]}
          </Button>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Button
            id="month-button"
        fullWidth
            startIcon={<CalendarMonth />}
            variant={filerButton.month ? "contained" : "outlined"}
            onClick={() => {
              setFilterButton({ today: false, week: false, month: true }),
                setValues({
                  ...searchDailyAct,
                  datetimefrom: todayDate,
                  datetimeto: nextMonthDate,
                });
              getActivities(
                user,
                todayDate,
                nextMonthDate,
                searchDailyAct.costcenterid,
                searchDailyAct.terminated,
                searchDailyAct.tostart,
                searchDailyAct.started
              );
            }}
          >
            {dictionary["dailyactivities"]["showMonth"]}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Stack spacing={2}>
      <Card>
        <IconBox Icon={Search} />
        <CardHeader
          title={dictionary["dailyactivities"]["searchTitle"]}
          sx={{ pl: (theme) => theme.spacing(12) }}
        />
        <CardContent component="form">
          <Grid container spacing={2} mb={1}>
            <Grid item xs={12} md={12} lg={12}>
              <ButtonFastFilter />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <DatePicker
                label={dictionary["reports"]["dateFrom"]}
                inputFormat="DD/MM/yyyy"
                value={searchDailyAct.datetimefrom || ""}
                onChange={(newValue) =>
                  changeHandler("datetimefrom", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                  id="field-datafrom"
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.datetimefrom && errors.datetimefrom)}
                    helperText={touched.datetimefrom && errors.datetimefrom}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <DatePicker
              id="field-datato"
                label={dictionary["reports"]["dateTo"]}
                inputFormat="DD/MM/yyyy"
                value={searchDailyAct.datetimeto || ""}
                onChange={(newValue) =>
                  changeHandler("datetimeto", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.datetimeto && errors.datetimeto)}
                    helperText={touched.datetimeto && errors.datetimeto}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <TextField
                fullWidth
                select
                id="filled-password-input"
                label={dictionary["dailyactivities"]["form"]["costcenter"]}
                type="text"
                autoComplete="current-password"
                value={searchDailyAct.costcenterid || "ALL"}
                error={Boolean(touched.costcenterid && errors.costcenterid)}
                helperText={touched.costcenterid && errors.costcenterid}
                size="small"
                onChange={(e) => changeHandler("costcenterid", e.target.value)}
              >
                <MenuItem value={"ALL"}>Tutti</MenuItem>
                {costcenters.map((res, idx) => (
                  <MenuItem key={idx} value={res.id}>
                    {res.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Stack direction={"row"} spacing={2}>
                <FormGroup sx={{ width: "45%" }}>
                  <FormLabel component="legend">Stato</FormLabel>
                  <Grid item container>
                    <Grid item xs={12} md={12} lg={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                        id="terminated-filter"
                            checked={searchDailyAct.terminated}
                            onChange={(e) =>
                              changeHandler("terminated", e.target.checked)
                            }
                          />
                        }
                        label={dictionary["dailyactivities"]["terminated"]}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                        id="tostart-filter"
                            checked={searchDailyAct.tostart}
                            onChange={(e) =>
                              changeHandler("tostart", e.target.checked)
                            }
                          />
                        }
                        label={dictionary["dailyactivities"]["notstarted"]}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                        id="started-filter"
                            checked={searchDailyAct.started}
                            onChange={(e) =>
                              changeHandler("started", e.target.checked)
                            }
                          />
                        }
                        label={dictionary["dailyactivities"]["start"]}
                      />
                    </Grid>
                  </Grid>
                </FormGroup>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Stack direction="row" justifyContent={"flex-end"} spacing={2}>
                <Button
                id="search-activity-btn"
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                  }}
                  disabled={
                    !isValid ||
                    (!searchDailyAct.terminated &&
                      !searchDailyAct.tostart &&
                      !searchDailyAct.started)
                  }
                  startIcon={<Search />}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    getActivities(
                      user,
                      searchDailyAct.datetimefrom,
                      searchDailyAct.datetimeto,
                      searchDailyAct.costcenterid,
                      searchDailyAct.terminated,
                      searchDailyAct.tostart,
                      searchDailyAct.started
                    );
                    setFilterButton({
                      today: false,
                      week: false,
                      month: false,
                    });
                  }}
                >
                  {dictionary["search"]}
                </Button>
                <Button
                id="reset-button"
                  variant="contained"
                  color="warning"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                  }}
                  startIcon={<Refresh />}
                  onClick={reset}
                >
                  {dictionary["reset"]}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DailyActivitiesTabels searchedDailyAct={dailyActivities} mini />
    </Stack>
  );
};

export default DailyActivities;
