import createDataContext from "./createContext";
import { RequestsService } from "../api";
import moment from "moment";
import { Button, Grid, Stack } from "@mui/material";
import { openModal } from "../hooks/useModals";

const SET_REQUESTS = "set_requests";

const INITIAL_STATE = {
    requests: [],
};

const requestsReducer = (state, action) => {
    switch (action.type) {
        case SET_REQUESTS: {
            const { requests } = action.payload;
            return { ...state, requests };
        }
        default: {
            return state;
        }
    }
};

const setRequests = dispatch => async ({ from, to, status, type }) => {
    from = from ? moment(from).format("YYYY-MM-DD") : "ALL";
    to = to ? moment(to).format("YYYY-MM-DD") : "ALL";
    const response = await RequestsService.get(`current/${status}/${from}/${to}/${type}`);
    dispatch({ type: SET_REQUESTS, payload: { requests: response.data.requests } });
};


export const { Provider, Context } = createDataContext(
    requestsReducer,
    {
        setRequests
    }, // actions
    INITIAL_STATE // initial state
);