import { Refresh, Search } from "@mui/icons-material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { IconBox, ReportsTables } from "../components";
import { ReportsContext } from "../contexts";
import { useDictionary, useForm, useJustificationsTypes, useEventTypes } from "../hooks";
import { isDateGreater } from "../hooks/useForm";
import { addMonthsToDate, removeMonthsToDate, transformDateToDatetime } from "../utilities";


const Reports = () => {
  const INITIAL_STATE = {
    status: "NOTJUSTIFIED",
    datetimefrom: removeMonthsToDate(new Date(), 1),
    datetimeto: addMonthsToDate(new Date(), 1),
  };
  const {
    state: { searchedReport },
    setReports,
  } = useContext(ReportsContext);
  const justificationsType = useJustificationsTypes();
  const types = useEventTypes();

  const dictionary = useDictionary();
  const validations = [
    ({ datetimefrom, datetimeto }) =>
      isDateGreater(datetimefrom, datetimeto) || {
        datetimefrom: dictionary["reports"]["errors"]["startGreaterEnd"],
      }
  ];

  const {
    values: searchReport,
    changeHandler,
    setValues,
    errors,
    touched,
    isValid,
    reset
  } = useForm(INITIAL_STATE, validations);

  useEffect(() => {
    setReports(INITIAL_STATE);
  }, []);

  return (
    <Stack spacing={2}>
      <Card>
        <IconBox Icon={Search} />
        <CardHeader
          title={dictionary["reports"]["title"]}
          sx={{ pl: (theme) => theme.spacing(12) }}
        />
        <CardContent component="form">
          <Grid container spacing={2} mb={1}>
            <Grid item xs={12} md={4} lg={4}>
              <DatePicker
                label={dictionary["reports"]["dateFrom"]}
                inputFormat="DD/MM/yyyy"
                value={searchReport.datetimefrom || ""}
                onChange={(newValue) =>
                  changeHandler("datetimefrom", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.datetimefrom && errors.datetimefrom)}
                    helperText={touched.datetimefrom && errors.datetimefrom}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <DatePicker
                label={dictionary["reports"]["dateTo"]}
                inputFormat="DD/MM/yyyy"
                value={searchReport.datetimeto || ""}
                onChange={(newValue) =>
                  changeHandler("datetimeto", newValue?.format("yyyy-MM-DD"))
                }
                renderInput={(params) => (
                  <TextField
                    size="small"
                    fullWidth
                    {...params}
                    sx={{ textTransform: "capitalize" }}
                    error={Boolean(touched.datetimeto && errors.datetimeto)}
                    helperText={touched.datetimeto && errors.datetimeto}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="status-select">{dictionary["reports"]["status"]}</InputLabel>
                <Select
                  id="status-select"
                  value={searchReport.status || ""}
                  label={dictionary["reports"]["status"]}
                  onChange={(e) => changeHandler("status", e.target.value)}
                >
                  <MenuItem value={"ALL"}>{dictionary["reports"]["states"]["ALL"]}</MenuItem>
                  <MenuItem value={"JUSTIFIED"}>{dictionary["reports"]["states"]["JUSTIFIED"]}</MenuItem>
                  <MenuItem value={"NOTJUSTIFIED"}>
                    {dictionary["reports"]["states"]["NOTJUSTIFIED"]}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Stack direction="row" justifyContent={"flex-end"} spacing={2}>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                  }}
                  disabled={!isValid}
                  startIcon={<Search />}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    setReports(searchReport);
                  }}
                >
                  {dictionary["search"]}
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  sx={{
                    textTransform: "capitalize",
                  }}
                  startIcon={<Refresh />}
                  onClick={reset}
                >
                  {dictionary["reset"]}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <ReportsTables
            mini={false}
            searchForm={searchReport}
            searchedReport={searchedReport}
            justificationsType={justificationsType}
            types={types} />
        </CardContent>
      </Card>
    </Stack>
  );
};

export default Reports;
