import {Badge, Card, CardContent, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import React from "react";
import {ROWS_PER_PAGE} from "../hooks/usePagination";
import {useDictionary, useEventTypes, useMediaQuery, usePagination} from "../hooks";
import moment from "moment";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import {Edit} from "@mui/icons-material";
import ReportsModal from "./ReportsModal";
import {renderHeader, translateMinutesInTime, getCompiledHours, deleteMillisFromTime} from "../utilities";

const ReportsTables = ({
  mini = false,
  onlyView = false,
  searchedReport,
  searchForm = {},
  justificationsType = [],
  types = [],
}) => {
  const [pageSize, setPageSize] = usePagination();
  const [isMobile] = useMediaQuery("down", "md");
  const dictionary = useDictionary();

  const columnsDash = [
    {
      field: "minutescount",
      headerName: dictionary["reports"]["hours"],
      renderHeader: renderHeader(dictionary["reports"]["hours"]),
      renderCell: ({value}) => (
        <Stack justifyItems="center" justifyContent={"center"} ml={4}>
          <Badge badgeContent={` ${value / 60} ${dictionary["hours"]}`} color="secondary" />
        </Stack>
      ),
    },
    {
      field: "datetimefrom",
      headerName: dictionary["reports"]["dateFrom"],
      renderHeader: renderHeader(dictionary["reports"]["dateFrom"]),
      flex: 2,
      renderCell: (params) => {
        return <Typography>{moment(params.value).format("DD/MM/YYYY")}</Typography>;
      },
    },
  ];

  let columns = [];

  const columnsDesk = [
    {
      field: "typeid",
      headerName: dictionary["reports"]["type"],
      renderHeader: renderHeader(dictionary["reports"]["type"]),
      flex: 1,
      renderCell: (params) => {
        let filter = types.find((res) => res.id === params.value);
        return <Typography>{filter && dictionary["types"][filter.description]}</Typography>;
      },
    },
    {
      field: "minutescount",
      headerName: dictionary["reports"]["hours"],
      renderHeader: renderHeader(dictionary["reports"]["hours"]),
      renderCell: ({value}) => (
        <Stack direction={"row"} justifyItems="center" justifyContent={"center"} ml={4}>
          <Badge badgeContent={` ${value / 60} ${dictionary["hours"]}`} color="secondary" />
        </Stack>
      ),
    },
    {
      field: "periods",
      headerName: dictionary["reports"]["clockings"],
      renderHeader: renderHeader(dictionary["reports"]["clockings"]),
      flex: 2,
      renderCell: (params) => {
        const [datefrom, timefrom] = params.row.datetimefrom.split("T");
        const [dateto, timeto] = params.row.datetimeto.split("T");
        return (
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={0.5} mt={1}>
              <Typography sx={{fontWeight: "bold"}} variant="body2">
                {dictionary["reports"]["dateFrom"]}:{" "}
              </Typography>
              <Typography variant="body2">
                {moment(datefrom).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(timefrom)}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Typography sx={{fontWeight: "bold"}} variant="body2">
                {dictionary["reports"]["dateTo"]}:{" "}
              </Typography>
              <Typography variant="body2">
                {moment(dateto).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(timeto)}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "minutescompiled",
      headerName: dictionary["reports"]["compiledHours"],
      renderHeader: renderHeader(dictionary["reports"]["compiledHours"]),
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack justifyItems="center" justifyContent={"center"} ml={4}>
            <Badge badgeContent={getCompiledHours(params.row.justifications)} color="secondary" />
          </Stack>
        );
      },
    },
    {
      field: "",
      headerName: dictionary["actions"],
      renderHeader: renderHeader(dictionary["actions"]),
      renderCell: (params) => (
        <ReportsModal justificationsType={justificationsType} report={params.row} searchForm={searchForm} />
      ),
    },
  ];

  const columnsMobile = [
    {
      field: "periods",
      headerName: dictionary["reports"]["clockings"],
      renderHeader: renderHeader(dictionary["reports"]["clockings"]),
      flex: 2,
      renderCell: (params) => {
        const [datefrom, timefrom] = params.row.datetimefrom.split("T");
        const [dateto, timeto] = params.row.datetimeto.split("T");
        return (
          <Stack spacing={0.5}>
            <Stack direction="row" mt={1}>
              <Typography variant="body">{moment(datefrom).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(timefrom)}</Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="body2">{moment(dateto).format("DD/MM/YYYY")} -&nbsp;{deleteMillisFromTime(timeto)}</Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "minutescompiled",
      headerName: dictionary["reports"]["hours"],
      renderHeader: renderHeader(dictionary["reports"]["hours"]),
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack justifyItems="center" justifyContent={"center"} ml={4}>
            <Badge badgeContent={getCompiledHours(params.row.justifications)} color="secondary" />
          </Stack>
        );
      },
    },
    {
      field: "",
      headerName: dictionary["actions"],
      renderHeader: renderHeader(dictionary["actions"]),
      renderCell: (params) => (
        <ReportsModal
          isMobile={isMobile}
          justificationsType={justificationsType}
          report={params.row}
          searchForm={searchForm}
        />
      ),
    },
  ];

  isMobile ? (columns = columnsMobile) : (columns = columnsDesk);

  const columnsOnlyView = [
    {
      field: "minutescount",
      headerName: dictionary["reports"]["hours"],
      flex: 1,
      renderHeader: renderHeader(dictionary["reports"]["hours"]),
      renderCell: ({value}) => (
        <Stack direction={"row"} justifyItems="center" justifyContent={"center"} ml={4}>
          <Badge badgeContent={` ${value / 60} ${dictionary["hours"]}`} color="secondary" />
        </Stack>
      ),
    },
    {
      field: "periods",
      headerName: dictionary["reports"]["clockings"],
      renderHeader: renderHeader(dictionary["reports"]["clockings"]),
      flex: 2,
      renderCell: (params) => {
        let period = params.row.periods.replaceAll("||", " - ").replaceAll("|", "");
        let periodSplit = period.split(" - ");
        const [datefrom, timefrom] = periodSplit[0].split(" ");
        const [dateto, timeto] = periodSplit[1].split(" ");
        return (
          <Stack spacing={0.5}>
            <Stack direction="row" spacing={0.5} mt={1}>
              <Typography sx={{fontWeight: "bold"}} variant="body2">
                {dictionary["reports"]["dateFrom"]}:{" "}
              </Typography>
              <Typography variant="body2">
                {moment(datefrom).format("DD/MM/YYYY")} {deleteMillisFromTime(timefrom)}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Typography sx={{fontWeight: "bold"}} variant="body2">
                {dictionary["reports"]["dateTo"]}:{" "}
              </Typography>
              <Typography variant="body2">
                {moment(dateto).format("DD/MM/YYYY")} {deleteMillisFromTime(timeto)}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
  ];

  return (
    <DataGrid
      disableColumnMenu
      disableSelectionOnClick
      autoHeight
      density={mini ? "compact" : "standard"}
      rows={searchedReport}
      columns={mini ? columnsDash : onlyView ? columnsOnlyView : columns}
      pagination
      hideFooter={mini || onlyView}
      onPageSizeChange={setPageSize}
      pageSize={mini || onlyView ? 100 : pageSize}
      rowsPerPageOptions={ROWS_PER_PAGE}
    />
  );
};

export default ReportsTables;
