import createDataContext from "./createContext";
import BaseRequest from "../api/Base";
import { openModal } from "../hooks/useModals";
import { TextField } from "@mui/material";

const SET_MANAGE_JUSTIFICATION= "set_manage_justification";
const SET_MANAGE_JUSTIFICATION_APPORVED= "set_manage_justification_approved";

const INITIAL_STATE = {
  justification: {},
  justifications: [],
  searchedJustifications: [],
  loading: true,
  searchJustifications: {
    firstname: "",
    lastname: "",
    status: "",
    statusGroups:"",
    datefrom: "",
    dateto: "",
  },
};

const searchExecution = (justificationsToFilter, searchForm) => {
  let justifications = [...justificationsToFilter];
  const { firstname, lastname, status, statusGroups, datefrom, dateto } = searchForm;
  if (firstname) {
    justifications = justifications.filter((justification) =>
    justification.firstname.toLowerCase().includes(firstname.toLowerCase())
    );
  }
  if (lastname) {
    justifications = justifications.filter((justification) =>
    justification.lastname.toLowerCase().includes(lastname.toLowerCase())
    );
  }
  if (status) {
    justifications = justifications.filter((justification) =>
    justification.status.toLowerCase().includes(status.toLowerCase())
    );
  }
  if (statusGroups) {
    justifications = justifications.filter((justification) =>
    justification.statusGroups.toLowerCase().includes(statusGroups.toLowerCase())
    );
  }
  if (datefrom) {
    justifications = justifications.filter((justification) =>
    justification.datetimefrom.toLowerCase().includes(datetimefrom.toLowerCase())
    );
  }
  if (dateto) {
    justifications = justifications.filter((justification) =>
    justification.datetimeto.toLowerCase().includes(datetimeto.toLowerCase())
    );
  }
  return justifications;
};

const ManageRequestReducer = (state, action) => {
  switch (action.type) {
    case SET_MANAGE_JUSTIFICATION: {
      let justifications = searchExecution(action.payload, state.searchJustifications);
      return {
        ...state,
        searchedJustifications: justifications,
        justifications: [...action.payload],
        justification: {},

      };
    }
    case SET_MANAGE_JUSTIFICATION_APPORVED: {
      let justIdx = state.searchedJustifications.findIndex((just) => just.id === action.payload.id)
      let justifications = [...state.searchedJustifications]
      justifications[justIdx] = action.payload
      return {
        ...state,
        searchedJustifications: justifications,
        justifications: justifications,
        justification: {},
      };
    }
    default: {
      return state;
    }
  }
};

const setManageJustifications = (dispatch) => async (data) => {;
  let fristname = data.firstname || "ALL";
  let lastname  = data.lastname  || "ALL";
  let status = data.status || "ALL";
  let statusGroups = data.statusGroups || "ALL";
  let datetimefrom = data.datefrom || "ALL";
  let datetimeto = data.dateto || "ALL";
  const response = await BaseRequest.get(`justifications/approver/${fristname}/${lastname}/${status}/${statusGroups}/${datetimefrom}/${datetimeto}`);
  dispatch({ type: SET_MANAGE_JUSTIFICATION, payload: response.data.justifications });
};

const approveManageJustification = (dispatch) => async (id, data, closeModal) => {
  const response = await BaseRequest.put(`justifications/outcomes/${id}`, data);
  dispatch({ type: SET_MANAGE_JUSTIFICATION_APPORVED, payload: response.data.justifications[0] });
  /* closeModal() */
};


export const { Provider, Context } = createDataContext(
 ManageRequestReducer,
  {
    setManageJustifications,
    approveManageJustification
  }, // actions
  INITIAL_STATE // initial state
);
